import { useParams } from 'react-router';
import { useAllPoolsQuery } from './query/useAllPoolsQuery';

export const usePoolFromPath = () => {
  const params = useParams();
  const { allPoolsQuery } = useAllPoolsQuery();

  const poolId = params?.poolId;
  const pool = poolId ? allPoolsQuery.data?.list.find(({ id }) => poolId === id) : null;
  const isNotFound = poolId && allPoolsQuery.data && !pool;

  return {
    pool,
    isNotFound,
    poolId,
  };
};
