import { MangataGenericEvent, toBN } from 'gasp-sdk';
import { Decimal } from 'decimal.js';
import {
  TransactionStore,
  TxAsset,
  TxType,
  ExtrinsicTx,
  TransactionErrorEvent,
} from '../../../transaction';
import { QueryOptional, WalletAccount } from '../../../../services';
import { Config } from 'wagmi';
import { ApiPromise } from '@polkadot/api';
import { some } from 'lodash-es';
import { AddLiquidityTxSucess } from '../../Pool';

export type AddLiqudityProps = {
  firstToken: TxAsset;
  secondToken: TxAsset;
  selectedAccount: WalletAccount;
  secondAssetBalance: string | null;
  firstAssetBalance: string | null;
  onDone?: () => void;
};

export const SLIPPAGE_KOEF = 0.01;

const getAmountWithSlippage = (amount: string) => new Decimal(amount).mul(1 + SLIPPAGE_KOEF);

const isAddLiquiditySuccessful = (txEvents: MangataGenericEvent[]) => {
  switch (true) {
    case some(txEvents, { method: AddLiquidityTxSucess.PoolCreated }):
    case some(txEvents, { method: AddLiquidityTxSucess.LiquidityMinted }):
      return true;
  }

  return false;
};

const getError = (txEvents: MangataGenericEvent[]) => {
  const failedExtEvent = txEvents.find(
    (event) => event.method === TransactionErrorEvent.ExtrinsicFailed,
  );

  return {
    name: failedExtEvent?.error?.documentation.join(' ') || 'Unknown error',
  };
};

export const submitAddLiqudity =
  (
    api: ApiPromise | null,
    transactionStore: TransactionStore,
    poolIds: string[] | undefined,
    wagmiConfig: QueryOptional<Config>,
  ) =>
  async ({
    selectedAccount,
    firstToken,
    secondToken,
    secondAssetBalance,
    firstAssetBalance,
    onDone,
  }: AddLiqudityProps) => {
    if (!selectedAccount || !api || !wagmiConfig) {
      return false;
    }

    const isPoolAlreadyCreated = poolIds?.includes(`${firstToken.id}-${secondToken.id}`);

    const extrinsicStatusCallback = (txEvents: MangataGenericEvent[]) => {
      if (isAddLiquiditySuccessful(txEvents)) {
        tx.done();
      } else {
        tx.doneWithError(getError(txEvents));
      }
    };

    const tx = new ExtrinsicTx(
      api,
      transactionStore,
      wagmiConfig,
      selectedAccount.address,
      undefined,
      extrinsicStatusCallback,
    )
      .setOptions({ doneOnTrigger: true, onDone })
      .create(isPoolAlreadyCreated ? TxType.AddLiquidity : TxType.CreatePool);

    if (isPoolAlreadyCreated && firstAssetBalance && secondAssetBalance) {
      const secondAssetBalanceDec = new Decimal(secondAssetBalance);
      const secondAmountWSlip = getAmountWithSlippage(secondToken.amount);
      const firstAmountWSlip = getAmountWithSlippage(firstToken.amount);
      const isSecondBalanceInsuff = secondAmountWSlip.gt(secondAssetBalance);
      const isFirstBalanceInsuff = firstAmountWSlip.gt(firstAssetBalance);

      if (isSecondBalanceInsuff) {
        tx.setTx(
          api.tx.xyk.mintLiquidity(
            secondToken.id,
            firstToken.id,
            toBN(
              (isFirstBalanceInsuff
                ? secondAssetBalanceDec.mul(1 - SLIPPAGE_KOEF)
                : secondAssetBalanceDec
              )
                .toDP(secondToken.decimals, 0)
                .toFixed(),
              secondToken.decimals,
            ),
            toBN(
              (isFirstBalanceInsuff ? new Decimal(firstAssetBalance) : firstAmountWSlip)
                .toDP(firstToken.decimals, 0)
                .toFixed(),
              firstToken.decimals,
            ),
          ),
        );
      } else {
        tx.setTx(
          api.tx.xyk.mintLiquidity(
            firstToken.id,
            secondToken.id,
            toBN(firstToken.amount, firstToken.decimals),
            toBN(secondAmountWSlip.toDP(secondToken.decimals, 0).toFixed(), secondToken.decimals),
          ),
        );
      }
    } else {
      tx.setTx(
        api.tx.xyk.createPool(
          firstToken.id,
          toBN(firstToken.amount, firstToken.decimals),
          secondToken.id,
          toBN(secondToken.amount, secondToken.decimals),
        ),
      );
    }

    await tx.build().send();
    return true;
  };
