import { Container, ShortAddress, Skeleton, Text } from 'ui';
import { ReactComponent as UserIcon } from 'icons/dotted-avatar.svg';
import { StakingCardProps } from './StakingCard';
import { useCollatorApy } from 'core';
import { CollatorAprChip } from '../../../Staking/components/CollatorAprChip/CollatorAprChip';

export function StakingCardHeader({ delegation }: StakingCardProps) {
  const {
    collatorApyQuery: { data: apy, isLoading },
  } = useCollatorApy(delegation.owner.toString());

  const renderAPY = () => {
    if (isLoading) {
      return (
        <Container>
          <Skeleton width="70px" height="15px" rounded className="mr-2" />
          <Skeleton width="50px" height="15px" rounded />
        </Container>
      );
    }

    if (apy) {
      return (
        <Container alignItems="center">
          <Text color="secondary" type="title-4" id="common.stakeApr" className="mr-2" />
          <CollatorAprChip value={apy.apy} isActive />
        </Container>
      );
    }

    return null;
  };

  return (
    <Container fullWidth justifyContent="space-between" className="p-4">
      <Container alignItems="center">
        <UserIcon className="stroke-neutral w-[32px] h-auto" />
        <Container column className="ml-3">
          <Text color="secondary" type="title-4" id="common.collator" uppercase />
          <Text type="title-4">
            <ShortAddress address={delegation.owner.toString()} />
          </Text>
        </Container>
      </Container>
      {renderAPY()}
    </Container>
  );
}
