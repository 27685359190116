import { ClassName, useAssets, useDelegators, useSession } from 'core';
import { Banner, Container } from 'ui';
import cls from 'classnames';
import { BN_ZERO } from 'gasp-sdk';

export function StakingListNotifications({ className }: ClassName) {
  const { assetsMap } = useAssets();
  const {
    delegatorStateQuery: { data: delegator },
    delegatorStateRequests: requests,
  } = useDelegators();

  const {
    currentSession: { data: session },
  } = useSession();

  const requestsForConfirmation = requests.filter(([, r]) =>
    r?.whenExecutable.lte(session ?? BN_ZERO),
  );

  const delegationsMap = new Map(delegator?.delegations?.map((d) => [d.owner.toString(), d]));

  const renderNotifications = () => {
    return Array.from(requestsForConfirmation ?? []).map(([, r]) => {
      const delegator = delegationsMap.get(r.collator.toString());
      const asset = assetsMap.get(delegator?.liquidityToken.toString() ?? '');

      return (
        <Banner
          variant="positive"
          key={r?.collator.toString()}
          message={{ id: 'stakingNotification.ready.desc' }}
          values={{ symbol: asset?.symbol }}
        />
      );
    });
  };

  return (
    <Container
      column
      fullWidth
      alignItems="start"
      justifyContent="center"
      className={cls('gap-y-2', className)}
    >
      {renderNotifications()}
    </Container>
  );
}
