import { useAccount, useSwitchChain } from 'wagmi';
import { useRollupChainsQuery } from '../rollup';

// TODO: rename to useChainProvider/useChainConnection
export const useMetamaskNetwork = () => {
  const { switchChain, isLoading, chains } = useSwitchChain();
  const { chainId } = useAccount();
  const { rollupChainsQuery } = useRollupChainsQuery();

  const defaultChain = rollupChainsQuery.data?.find((c) => Number(c.chainId) === chains[0].id);
  const isDefaultChain = chainId === Number(defaultChain?.chainId);
  const isChainSwitchRequired = !isDefaultChain;

  const requestChain = (newChainId?: string) => {
    if (!newChainId) {
      console.error(`Chain ${newChainId} is not available`);
      return;
    }

    switchChain({ chainId: Number(newChainId) });
  };
  const requestDefaultChain = () => requestChain(defaultChain?.chainId);
  const isChainConnected = (newChainId?: string) => Number(newChainId) === chainId;

  return {
    isLoading,
    defaultChain,
    isChainSwitchRequired,
    isDefaultChain,
    isChainConnected,
    requestDefaultChain,
    requestChain,
  };
};
