import {
  TransactionError,
  TxStatus,
  ExtrinsicError,
  SwapTransactionError,
  DelegateTransactionError,
  ScheduleBondMoreTransactionError,
  TxError,
  XcmError,
} from 'core';

export const getSpinnerStatus = (txStatus: TxStatus) => {
  switch (txStatus) {
    case TxStatus.Success:
      return 'success';
    case TxStatus.Error:
      return 'error';

    default:
      return 'loading';
  }
};

export const getErrorText = (error: TransactionError) => {
  switch (error.name) {
    case TxError.RequestRejected:
    case TxError.DeniedSignature:
    case ExtrinsicError.Cancelled:
      return {
        title: 'modal.transaction.error.cancelled.title',
        desc: 'modal.transaction.error.cancelled.desc',
      };
    case ExtrinsicError.Unknown:
      return {
        title: 'modal.transaction.error.title',
        desc: 'modal.transaction.error.unknown.desc',
      };
    case SwapTransactionError.FailedDueToSlippage:
      return {
        title: 'modal.transaction.error.slippage.title',
        desc: 'modal.transaction.error.slippage.desc',
      };
    case DelegateTransactionError.TooLowDelegationCountToDelegate:
      return {
        title: 'modal.transaction.error.title',
        desc: 'modal.transaction.error.tooLowDelegationCountToDelegate.desc',
      };
    case DelegateTransactionError.DelegationBelowMin:
      return {
        title: 'modal.transaction.error.title',
        desc: 'modal.transaction.error.delegationBelowMin.desc',
      };
    case ScheduleBondMoreTransactionError.NotExistingDelegation:
      return {
        title: 'modal.transaction.error.title',
        desc: 'modal.transaction.error.notExistingDelegation.desc',
      };
    case ScheduleBondMoreTransactionError.RequestAlreadyExists:
      return {
        title: 'modal.transaction.error.title',
        desc: 'modal.transaction.error.requestAlreadyExists.desc',
      };

    case DelegateTransactionError.ExceedMaxTotalDelegatorsPerCandidate:
      return {
        title: 'modal.transaction.error.title',
        desc: 'modal.transaction.error.exceedMaxTotalDelegatorsPerCandidate.desc',
      };
    case XcmError.ValidatorBlockLimitReached:
      return {
        title: 'modal.transaction.error.title',
        desc: 'modal.transaction.error.validatorBlockLimitReached.desc',
      };

    default:
      return {
        title: 'modal.transaction.error.title',
        desc: null,
      };
  }
};

export const deepCompareArrays = (arr1: [string, string][], arr2: [string, string][]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i][0] !== arr2[i][0] || arr1[i][1] !== arr2[i][1]) {
      return false;
    }
  }

  return true;
};
