import { Container } from 'ui';
import cls from 'classnames';
import { ClassName, TestId, useAssets, useSession } from 'core';
import { ParachainStakingBond } from '@polkadot/types/lookup';
import { StakingCardHeader } from './StakingCardHeader';
import { StakingCardRewards } from './StakingCardRewards';
import { StakingCardMyStake } from './StakingCardMyStake';
import { ParachainStakingDelegator } from '@polkadot/types/lookup';
import { StakingCardScheduledChange } from './StakingCardScheduledChange';

export interface StakingCardProps extends ClassName, TestId {
  delegation: ParachainStakingBond;
  requests?: ParachainStakingDelegator['requests']['requests'];
}

export function StakingCard({
  delegation,
  requests,
  className,
  'data-testid': testId,
}: StakingCardProps) {
  const { assetsMap } = useAssets();
  const {
    currentSession: { data: session },
  } = useSession();

  const request = Array.from(requests?.entries() ?? []).find(([, req]) => {
    return req.collator.eq(delegation.owner);
  })?.[1];

  const asset = assetsMap.get(delegation.liquidityToken.toString());

  return (
    <Container
      fullWidth
      column
      justifyContent="center"
      className={cls('bg-default rounded-xl border border-default', className)}
      data-testid={testId}
    >
      <StakingCardHeader delegation={delegation} />
      {asset && session && (
        <>
          <StakingCardMyStake
            delegation={delegation}
            asset={asset}
            request={request}
            className="pt-5 pb-2 border-t border-default"
          />
          {request && (
            <StakingCardScheduledChange
              asset={asset}
              delegation={delegation}
              request={request}
              session={session}
            />
          )}
        </>
      )}
      <Container className="p-4 w-full">
        <StakingCardRewards delegation={delegation} />
      </Container>
    </Container>
  );
}
