import { PoolWithShare } from '@mangata-finance/sdk';
import { Asset, useAll3rdPartyRewardsInfoQuery, useMangataAsset } from 'core';
import { Container, TokenIcon, Text, FormatAmount } from 'ui';
import { isUndefined } from '@polkadot/util';
import Decimal from 'decimal.js';
import { isNull } from 'lodash-es';

interface ThirdPartyRewardsInfoRowProps {
  pool: PoolWithShare;
  asset: Asset;
  amount?: string;
}

export const PoolRewardsInfoRow = ({ amount, asset, pool }: ThirdPartyRewardsInfoRowProps) => {
  const { allThirdPartyRewardsInfoQuery } = useAll3rdPartyRewardsInfoQuery();
  const { mangataAsset } = useMangataAsset();

  const thirdPartyRewardsInfo = allThirdPartyRewardsInfoQuery.data
    ?.get(pool.liquidityTokenId)
    ?.get(asset.id);
  const isMangataAsset = asset.id === mangataAsset?.id;
  const renderAmount = () => {
    const hasActivatedScheduledRewards = isMangataAsset
      ? pool.activatedLPTokens.gtn(0)
      : thirdPartyRewardsInfo?.activatedAmount.gtn(0);

    const hasRewardsToClaim = amount ? new Decimal(amount).gt(0) : null;

    if (isNull(hasRewardsToClaim) || isUndefined(hasActivatedScheduledRewards)) {
      return null;
    }

    return hasActivatedScheduledRewards || hasRewardsToClaim ? (
      <Text className="_accent-premium" color="accent">
        <FormatAmount
          value={amount}
          options={{ nonZeroPrecision: true, precision: 2, maxChars: 13 }}
        />{' '}
        {asset.symbol}
      </Text>
    ) : (
      <Container className="px-2 py-1 rounded-lg bg-default">
        <Text
          id="position.overview.rewards.notActive"
          color="secondary"
          type="title-4"
          weight="normal"
        />
      </Container>
    );
  };

  return (
    <Container
      alignItems="center"
      justifyContent="space-between"
      fullWidth
      className="mt-4"
      key={asset.id}
    >
      <Container alignItems="center">
        <TokenIcon token={asset} size="xs" className="mr-2" />
        <Text
          type="title-4"
          id={`position.overview.${isMangataAsset ? 'native' : '3rdParty'}.liqMining.title`}
          values={{
            symbol: asset.symbol,
          }}
          weight="normal"
        />
      </Container>

      {renderAmount()}
    </Container>
  );
};
