import { TestId } from 'core';
import { Modal, Button, Container, Text } from 'ui';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { useStartStakingStore } from '../../widgets/start/store/useStartStakingStore';
import { EnvConfig } from 'core';

type StakingLimitReachedModalProps = TestId;

export const StakingLimitReachedModal = ({
  'data-testid': testId,
}: StakingLimitReachedModalProps) => {
  const { isLimitReachedModalVisible, setLimitReachedModalVisibility } = useStartStakingStore();

  const onClose = () => {
    setLimitReachedModalVisibility(false);
  };

  return (
    <Modal
      isOpen={isLimitReachedModalVisible}
      onClose={onClose}
      className="w-[400px] px-8 !py-[76px] pt-8 flex-col whitespace-pre-line items-center"
      data-testid={testId}
      closeIconClassName="hidden"
    >
      <Container
        alignItems="center"
        justifyContent="center"
        className="mb-6 bg-accent-soft h-[80px] w-[80px] rounded-full"
      >
        <InfoIcon className="fill-highlight w-[40px] h-auto" />
      </Container>
      <Text id="staking.modal.limit.reached.title" type="title-1" />
      <Text
        id="staking.modal.limit.reached.desc"
        color="secondary"
        className="mb-14 mt-2 text-center"
        values={{ limitCount: EnvConfig.MAX_DELEGATION_COUNT }}
        type="body-l"
      />
      <Button
        variant="primary"
        onClick={onClose}
        data-testid={`${testId}-confirm`}
        message={{ id: 'staking.modal.limit.reached.ok' }}
      />
    </Modal>
  );
};
