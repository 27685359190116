import { ClassName, TestId } from 'core';
import cls from 'classnames';
import { Container } from '../Container/Container';

interface SkeletonProps extends ClassName, TestId {
  rounded?: boolean;
  height?: string;
  width?: string;
}

export const Skeleton = ({ rounded = true, height, width, className }: SkeletonProps) => {
  return (
    <Container
      style={{ width, height }}
      className={cls('animate-pulse bg-hover', rounded ? 'rounded-full' : '', className)}
    />
  );
};
