import { ClassName, TestId } from 'core';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { TokenInputProps } from 'ui';

export enum ProvideLiquidityWidgetType {
  Create,
  Add,
}

export interface CreatePoolLiquidityWidgetProps extends Pick<TokenInputProps, 'tokens'>, TestId {
  type: ProvideLiquidityWidgetType.Create;
}

export interface AddPoolLiquidityWidgetProps extends TestId {
  type: ProvideLiquidityWidgetType.Add;
  tokens?: never;
}

export type ProvideLiquidityWidgetProps = (
  | CreatePoolLiquidityWidgetProps
  | AddPoolLiquidityWidgetProps
) &
  ClassName & {
    onCancel?: () => void;
    onSuccess?: (pool: PoolWithRatio) => void;
    title?: string;
    onWalletConnect?: () => void;
  };

export type PendingNewPool = Pick<PoolWithRatio, 'firstAsset' | 'secondAsset'>;

export enum ProvideLiquidityFormError {
  InsufficientFirstAssetFunds = 'InsufficientFirstAssetFunds',
  InsufficientSecondAssetFunds = 'InsufficientSecondAssetFunds',
  InsufficientFee = 'InsufficientFee',
}

export interface EstimatedShare {
  next: string;
  current: string | null;
}
