import { BN, BN_ZERO } from '@polkadot/util';
import { ReserveSourceType, QueryOptional } from '../../../services';
import { useReserves } from './useReserves';
import { useInternalBalance } from '../internal/useInternalBalance';

export type ReserveSource = [ReserveSourceType, BN][];

export const useReservesAggregator = (amount: BN | null, assetId: QueryOptional<string> | null) => {
  const { unspentReserves, activatedUnstakedReserves } = useReserves(assetId);
  const { balances } = useInternalBalance();

  if (!amount || amount.isZero() || !assetId) {
    return { reserveSource: null };
  }

  const availableBalance = balances?.get(assetId)?.free || BN_ZERO;
  const balanceSources = [
    { type: ReserveSourceType.UnspentReserves, balance: unspentReserves },
    { type: ReserveSourceType.ActivatedUnstakedReserves, balance: activatedUnstakedReserves },
    { type: ReserveSourceType.AvailableBalance, balance: availableBalance },
  ];

  let remainingAmount = amount;

  const reserveSource = balanceSources.reduce((source: ReserveSource, { type, balance }) => {
    if (remainingAmount.gt(BN_ZERO) && balance?.gt(BN_ZERO)) {
      const balanceUsage = remainingAmount.gte(balance) ? balance : remainingAmount;
      remainingAmount = remainingAmount.sub(balanceUsage);

      source.push([type, balanceUsage]);
    }
    return source;
  }, []);

  return { reserveSource: remainingAmount.gt(BN_ZERO) ? null : reserveSource };
};
