import cls from 'classnames';

import { useToggle } from '../../hooks';
import { ClassName, TestId } from '../../types';
import { Text } from '../';
import { useEffect } from 'react';

export interface ToggleProps extends ClassName, TestId {
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
  defaultValue?: boolean;
  value?: boolean;
}

export function Toggle({
  isDisabled,
  onChange,
  className,
  'data-testid': testId,
  value,
  defaultValue = false,
}: ToggleProps) {
  const [isChecked, setChecked] = useToggle(defaultValue || value);

  useEffect(() => {
    if (value !== undefined) {
      setChecked(value);
    }
  }, [setChecked, value]);

  const handleChange = () => {
    if (!isDisabled) {
      if (onChange) {
        onChange(!isChecked);
      }
      setChecked(!isChecked);
    }
  };

  return (
    <label className={cls('relative inline-flex items-center bg-input rounded-full', className)}>
      <input
        type="checkbox"
        checked={isChecked}
        className="sr-only peer"
        onChange={handleChange}
        readOnly={isDisabled}
        disabled={isDisabled}
        data-testid={testId}
      />
      <div
        className={cls(
          'flex items-center w-[56px] h-[24px] border-solid border-1 border-default peer-focus:outline-none rounded-full',
          "peer peer-checked:after:translate-x-[31px] after:content-[''] after:absolute after:top-[5px] after:left-[6px]",
          'after:rounded-full after:h-[14px] after:w-[14px] after:transition-all',
          isChecked ? 'after:bg-accent' : 'after:bg-inverted',
          isDisabled ? 'opacity-40' : 'cursor-pointer',
        )}
      >
        <Text
          type="body-tiny"
          weight="bold"
          className={cls(
            'transition-all select-none',
            isChecked ? 'ml-[11px] text-accent' : 'ml-6',
          )}
          id={isChecked ? 'default.toggle.on' : 'default.toggle.off'}
        />
      </div>
    </label>
  );
}
