import cls from 'classnames';
import { SyntheticEvent, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { Children, ClassName, TestId } from '../../types';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { IconButton } from '../IconButton/IconButton';
import { Container } from '../Container/Container';

export const STOP_EVENT_BUBBLING = (e: SyntheticEvent) => e.stopPropagation();

export interface ModalProps extends Children, ClassName, TestId {
  isOpen?: boolean;
  onClose?(): void;
  isClosable?: boolean;
  isClosableOnOverlayClick?: boolean;
  closeIconClassName?: string;
  overlayClassName?: string;
  isCloseIconVisible?: boolean;
}

export const ModalContent = ({
  className,
  'data-testid': testId,
  children,
  onClose,
  isOpen,
  isClosableOnOverlayClick = true,
  isCloseIconVisible = true,
  closeIconClassName,
  isClosable = true,
  overlayClassName,
}: ModalProps) => {
  const handleClose = useCallback(() => {
    if (isClosable && isOpen) {
      onClose?.();
    }
  }, [onClose, isOpen, isClosable]);

  useEffect(() => {
    const handleEscPress = (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === 'escape') {
        handleClose();
      }
    };
    document.addEventListener('keyup', handleEscPress);

    return () => document.removeEventListener('keyup', handleEscPress);
  }, [handleClose]);

  return (
    <Container
      className={cls('prose absolute inset-0 bg-base-inverted/80 z-modal', overlayClassName)}
      column
      justifyContent="center"
      alignItems="center"
      onClick={isClosableOnOverlayClick ? handleClose : undefined}
      data-testid={`${testId}-bg`}
    >
      <Container
        className={cls(
          'shadow-primary bg-default border-default border-1 rounded-xl p-6 pointer-events-auto relative min-w-[400px]',
          className,
        )}
        column
        data-testid={`${testId}-content`}
        onClick={STOP_EVENT_BUBBLING}
      >
        {isCloseIconVisible && (
          <IconButton
            onClick={handleClose}
            className={cls('fill-secondary absolute right-6 top-6', closeIconClassName)}
            data-testid={`${testId}-close`}
            Icon={CloseIcon}
          />
        )}
        {children}
      </Container>
    </Container>
  );
};

export function Modal(props: ModalProps) {
  return props.isOpen ? createPortal(<ModalContent {...props} />, document.body) : null;
}
