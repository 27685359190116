import { Container, ShortAddress, Text, useHover } from 'ui';
import { ReactComponent as WalletPlusIcon } from 'icons/wallet-plus.svg';
import { ReactComponent as UserIcon } from 'icons/user-round.svg';
import { useAccounts } from 'core';
import { animated, useTransition } from '@react-spring/web';
import { useIntl } from 'react-intl';

interface WalletChipProps {
  onClick: () => void;
}

export function WalletChip({ onClick }: WalletChipProps) {
  const intl = useIntl();
  const { selectedAccount } = useAccounts();

  const [ref, isHovering] = useHover<HTMLDivElement>();

  const openButtonTransition = useTransition(isHovering, {
    from: { opacity: 0, maxWidth: 0 },
    enter: { opacity: 1, maxWidth: 80 },
    leave: { opacity: 0, maxWidth: 0 },
    config: {
      tension: 100,
      friction: 15,
      precision: 0.01,
    },
  });

  const renderDisconnectedState = () => {
    return (
      <>
        <Container className="rounded-full p-2 bg-accent mr-2">
          <WalletPlusIcon className="w-5 h-auto stroke-inverted" />
        </Container>
        <Text id="walletChip.connect" />
      </>
    );
  };

  const renderAccountInfo = () => {
    if (!selectedAccount) return null;

    return (
      <>
        <Container className="rounded-full p-2 bg-accent-soft mr-2">
          <UserIcon className="w-5 h-auto stroke-secondary" />
        </Container>
        <Container column className="whitespace-nowrap" data-testid="wallet-status-connected">
          <Text type="title-4">
            <ShortAddress withTooltip={false} address={selectedAccount?.address} />
          </Text>
          <Text
            type="body-tiny"
            color="secondary"
            id="walletChip.connectedWith"
            values={{ wallet: intl.formatMessage({ id: `wallet.extension.metamask` }) }}
          />
        </Container>
      </>
    );
  };

  return (
    <Container
      ref={ref}
      className="rounded-full p-2 pr-6 border border-default h-[56px] whitespace-nowrap cursor-pointer hover:bg-hover"
      alignItems="center"
      onClick={onClick}
      data-testid="wallet-status"
    >
      {selectedAccount ? renderAccountInfo() : renderDisconnectedState()}
      {openButtonTransition((style, item) => {
        return (
          item && (
            <animated.div style={style}>
              <Container
                className="bg-accent rounded-full py-1 px-2 cursor-pointer ml-4 overflow-hidden"
                onClick={onClick}
                data-testid="open-wallet"
              >
                <Text id="walletChip.open" color="inverted" uppercase />
              </Container>
            </animated.div>
          )
        );
      })}
    </Container>
  );
}
