import cls from 'classnames';
import Slider from 'rc-slider';
import { useState } from 'react';
import { ClassName, TestId } from 'core';
import { Container } from '../../Container/Container';
import { Button } from '../../Button/Button';
import { Text } from '../../Text/Text';

export interface SliderWidgetProps extends TestId, ClassName {
  titleId: string;
  value?: number;
  disabledPerc?: number;
  isNotAllowed?: boolean;
  maxAmount?: number;
  notAllowedNode?: React.ReactNode;
  onChange?: (value: number) => void;
}

export function SliderWidget({
  className,
  'data-testid': testId,
  titleId,
  value,
  onChange,
  disabledPerc,
  isNotAllowed,
  maxAmount = 100,
  notAllowedNode,
}: SliderWidgetProps) {
  const [inputValue, setInputValue] = useState(value ? value?.toString() : '0');
  const presetButtons: [number, string][] = [
    [25, '25%'],
    [50, '50%'],
    [75, '75%'],
    [maxAmount, '100%'],
  ];

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (newValue.length > 3) {
      return;
    }

    if (/^\d+$/.test(newValue)) {
      const newValueInt = parseInt(newValue);
      onChange?.(Math.min(newValueInt, 100));
      setInputValue(newValue);

      if (newValueInt > 100) {
        setInputValue('100');
        return;
      }
    } else if (newValue === '') {
      setInputValue('');
    }
  };

  const onInputBlur = () => {
    if (inputValue === '') {
      setInputValue(value?.toString() || '0');
    }
  };

  const onValueChange = (newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      onChange?.(newValue);
      setInputValue(newValue.toString());
    }
  };

  const onPresetValueClick = (newValue: number) => () => onValueChange(newValue);

  return (
    <Container
      inline
      fullWidth
      data-testid={testId}
      className={cls('rounded-xxl p-4 pb-2 border-1 border-default', className)}
      column
    >
      <Container className="w-full" justifyContent="space-between">
        <Text type="body-m" id={titleId} />
        <Container>
          {presetButtons.map(([presetValue, presetLabel]) => (
            <Button
              size="xs"
              className="ml-2 w-[45px] pt-[2px]"
              variant={presetValue === value ? 'primary' : 'secondary'}
              onClick={onPresetValueClick(presetValue)}
              key={presetLabel}
              data-testid={`${presetLabel}-button`}
            >
              {presetLabel}
            </Button>
          ))}
        </Container>
      </Container>
      <Container alignItems="center" justifyContent="center" className="mt-3 w-full">
        <Container alignItems="center" className="min-w-[76px]">
          <input
            type="text"
            value={inputValue}
            autoFocus
            onBlur={onInputBlur}
            max={100}
            maxLength={3}
            data-testid={`${testId}-input`}
            onChange={onInputChange}
            className={cls(
              'text-neutral py-0 font-display-2 px-0 bg-transparent w-full align-bottom',
              'border-none border-b-1 border-strong focus:ring-0 focus:outline-none',
              'peer placeholder:text-secondary relative top-[1px] min-w-[45px]',
              inputValue.length === 3
                ? 'w-[54px]'
                : (inputValue.length || 0) === 2
                ? 'w-[39px]'
                : 'w-[22px]',
            )}
          />
          <Text type="title-1" className="ml-[2px]">
            %
          </Text>
        </Container>
        <div className="relative w-full ml-4">
          {(disabledPerc || 0) > 0 && (
            <Container
              className={cls(
                'rc-slider-disabled-track',
                (disabledPerc || 0) > 60 ? 'justify-center' : 'justify-end',
              )}
              style={{ width: `${disabledPerc}%`, minWidth: '8px' }}
            >
              {notAllowedNode}
            </Container>
          )}
          <Slider
            className={cls('w-full rc-slider-accent', isNotAllowed && 'rc-slider-not-allowed')}
            onChange={onValueChange}
            value={value}
          />
        </div>
      </Container>
    </Container>
  );
}
