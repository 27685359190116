import { useUserStore } from '../../../user/useUserStore';
import { useTokenMetadata } from '../../metadata/useTokenMetadata';
import { useStashTokens } from '../../stash/useStashTokens';
import { useInternalBalance } from '../../../balance';
import { useAllPoolsQuery } from '../../../pool/all/query/useAllPoolsQuery';
import { tokenFilterSelector } from './TokenFilterSelectors';
import { useRollupTokensQuery } from '../../../rollup';

export const useFilteredAssets = () => {
  const {
    allPoolsQuery: { data: pools },
  } = useAllPoolsQuery();
  const { liquidityAssetsQuery, nativeAssetsQuery } = useTokenMetadata({
    pools: pools?.baseList,
  });
  const { stashTokensQuery } = useStashTokens();
  const { tokenFilters, setTokensFilter } = useUserStore();
  const { balances } = useInternalBalance();
  const {
    rollupTokensQuery: { data: rollupTokens },
  } = useRollupTokensQuery();

  const { filteredAssets, tokensByFilter } = tokenFilterSelector({
    native: nativeAssetsQuery.data,
    liquidity: liquidityAssetsQuery.data,
    stash: stashTokensQuery.data,
    rollupTokens,
    tokenFilters,
    balances,
  });

  return {
    filteredAssets,
    tokensByFilter,
    setTokensFilter,
    tokenFilters,
  };
};
