import { Asset } from 'core';
import { Container, TokenIcon, Text, FormatAmount, Button } from 'ui';
import { ReactComponent as ArrowRight } from 'icons/arrow-right.svg';

interface MyStakeProps {
  asset: Asset;
  amount: string;
}

export const MyStake = ({ asset, amount }: MyStakeProps) => {
  return (
    <Container
      fullWidth
      className={'rounded-xl p-5 bg-accent-soft px-6 py-8'}
      alignItems="center"
      justifyContent="space-between"
    >
      <Container alignItems="center">
        <TokenIcon token={asset} type="diagonal" size="l" />
        <Container className="ml-3" column>
          <Text id="myStake.title" color="secondary" uppercase type="body-s" />
          <Text type="title-2">
            <FormatAmount value={amount} options={{ precision: 3, maxChars: 12 }} /> {asset.symbol}
          </Text>
        </Container>
      </Container>
      <Button
        variant="base"
        element="link"
        to="/staking/own"
        className="p-2 text-accent"
        TrailIcon={ArrowRight}
        message={{ id: 'myStake.cta' }}
        iconClassName="stroke-accent"
      />
    </Container>
  );
};
