import { ClassName, TestId } from '../../types';
import { Container } from '../Container/Container';
import { CountBadge } from '../CountBadge/CountBadge';
import { Text } from '../Text/Text';
import React from 'react';
import cls from 'classnames';
import { useHover } from '../../hooks/useHover';
import { Link } from 'react-router-dom';
import { EnvConfig } from '../../environment/EnvConfig';

export interface NavItemProps extends ClassName, TestId {
  title: string;
  count?: number;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  isActive?: boolean;
  hasNotification?: boolean;
  path: string;
}

export const NavItem = ({
  isActive = false,
  hasNotification = false,
  title,
  count,
  Icon,
  className,
  path,
  'data-testid': testId,
}: NavItemProps) => {
  const [hoverRef, isHovering] = useHover<HTMLDivElement>();

  const ICON_CLASS = cls(
    'w-6 h-6',
    isActive && 'fill-accent stroke-accent',
    !isActive && 'stroke-secondary',
  );

  return (
    <Link to={path} data-testid={testId}>
      <Container
        inline
        ref={hoverRef}
        alignItems="center"
        className={cls(
          'group cursor-pointer rounded-full py-3 px-4 hover:bg-hover',
          isActive && 'bg-accent-soft',
          className,
        )}
      >
        {React.cloneElement(<Icon />, {
          className: ICON_CLASS,
        })}
        <Container alignItems="center" className={cls('transition-transform')}>
          <Text
            type="title-3"
            className={cls('px-2', isActive ? 'text-neutral' : 'text-secondary')}
          >
            {title}
          </Text>
          {count !== undefined && !hasNotification && (
            <CountBadge
              count={count}
              variant={
                isActive || isHovering
                  ? EnvConfig.isRollupEnv
                    ? 'highlight'
                    : 'accent'
                  : 'secondary'
              }
            />
          )}
          {hasNotification && !isActive && (
            <Container className="rounded-full w-1.5 h-1.5 bg-accent relative">
              <div className="w-1.5 h-1.5 rounded-full bg-accent blur-sm animate-shadowPulse" />
            </Container>
          )}
        </Container>
      </Container>
    </Link>
  );
};
