import { useLocation, useNavigate } from 'react-router';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { TokenIcon, Container, Text, Button } from 'ui';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left.svg';
import { RewardsAprBadge } from 'modules';

export interface PoolDetailHeaderProps {
  pool: PoolWithRatio;
  poolApr?: string | null;
}

export const PoolDetailHeader = ({ pool, poolApr }: PoolDetailHeaderProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBack = () => {
    if (location.state?.isFromPoolItem) {
      navigate(-1);
    } else {
      navigate('/pools');
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        LeadIcon={BackIcon}
        onClick={handleBack}
        className="py-0"
        data-testid="back-button"
        message={{ id: 'pool.detail.back.cta' }}
      />
      <Container
        className="mt-10 whitespace-nowrap"
        alignItems="center"
        justifyContent="space-between"
        fullWidth
      >
        <Container alignItems="center" data-testid="header">
          <TokenIcon token={[pool.firstAsset, pool.secondAsset]} size="l" />
          <Text type="display-2" className="text-center ml-4">
            {pool.firstAsset.symbol} / {pool.secondAsset.symbol}
          </Text>
        </Container>
        {poolApr && <RewardsAprBadge value={poolApr} data-testid="rewards-apr" />}
      </Container>
    </>
  );
};
