import { useCallback } from 'react';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, toBN } from '@mangata-finance/sdk';
import Decimal from 'decimal.js';
import { Container, Text, Button, SliderWidget } from 'ui';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { useRemoveStakeInputs } from './useRemoveStakeInputs';
import { useRemoveStakeStore } from './store/useRemoveStakeStore';
import { RemoveStakeWidgetDetails } from './details/RemoveStakeWidgetDetails';
import { getSubmitMessage, useRemoveStakeValidation } from './useRemoveStakeValidation';
import {
  useScheduleDelegatorBondLess,
  Asset,
  isAmountGtZero,
  TestId,
  useScheduleRevokeDelegationMutation,
  TxType,
  useMetamaskNetwork,
} from 'core';
import { useTx } from '../../../Tx';

export interface RemoveStakeWidgetProps extends TestId {
  collatorAddress: string;
  currentStakeAmount: string | null;
  onClose?: () => void;
  stakedAsset: Asset | null;
}

const FULL_AMOUNT_PERCENTAGE = 100;

export const RemoveStakeWidget = ({
  collatorAddress,
  currentStakeAmount,
  stakedAsset,
  onClose,
  'data-testid': testId = 'remove-stake-widget',
}: RemoveStakeWidgetProps) => {
  const store = useRemoveStakeStore();
  const { amount, dispose } = store;
  const { handleAmountChange } = useRemoveStakeInputs(stakedAsset);
  const { isAllowed } = useTx();
  const { isChainSwitchRequired, requestDefaultChain } = useMetamaskNetwork();

  const stakeRemoveAmount = new Decimal(currentStakeAmount || '0')
    .mul(new Decimal(amount).div(100))
    .toFixed(BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);

  const { scheduleBondLessFeeQuery, scheduleBondLessMutation } = useScheduleDelegatorBondLess(
    collatorAddress,
    toBN(stakeRemoveAmount, stakedAsset?.decimals),
  );
  const { scheduleRevokeDelegationMutation } = useScheduleRevokeDelegationMutation();
  const { isInvalid } = useRemoveStakeValidation(scheduleBondLessFeeQuery.data?.current);

  const handleClose = useCallback(() => {
    dispose();
    onClose?.();
  }, [dispose, onClose]);

  const handleSubmit = () => {
    if (!stakedAsset) return;

    const scheduleMutation =
      amount === FULL_AMOUNT_PERCENTAGE
        ? scheduleRevokeDelegationMutation
        : scheduleBondLessMutation;

    scheduleMutation.mutate({
      collatorAddress,
      txAsset: {
        ...stakedAsset,
        amount: stakeRemoveAmount,
      },
      onDone: handleClose,
    });
  };

  return (
    <Container
      fullWidth
      className="transition-all bg-default p-6 rounded-lg"
      alignItems="start"
      justifyContent="start"
      data-testid={`${testId}-container`}
      column
    >
      <Container alignItems="center" fullWidth justifyContent="space-between" className="mb-4">
        <Text type="title-1" id="staking.remove.title" />
        <Button
          variant="base"
          TrailIcon={CloseIcon}
          onClick={handleClose}
          iconClassName="fill-secondary w-[24px]"
          className="py-0"
          data-testid="cancel-button"
        />
      </Container>
      <SliderWidget
        value={new Decimal(amount).toNumber()}
        titleId="position.remove.widget.input.title"
        data-testid={`${testId}-slider`}
        onChange={handleAmountChange}
      />
      <RemoveStakeWidgetDetails
        feeData={scheduleBondLessFeeQuery.data?.current}
        isFeeLoading={scheduleBondLessFeeQuery.isLoading}
        currentStakeAmount={currentStakeAmount}
        stakeRemoveAmount={stakeRemoveAmount}
        stakedAsset={stakedAsset}
        data-testid={`${testId}-details`}
      />
      <Container fullWidth className="mt-6">
        <Button
          variant="primary"
          size="huge"
          fullWidth
          isDisabled={
            isInvalid ||
            !isAmountGtZero(stakeRemoveAmount) ||
            !isAllowed(TxType.StakeChange) ||
            scheduleBondLessFeeQuery.isLoading
          }
          data-testid={`${testId}-submit`}
          onClick={isChainSwitchRequired ? requestDefaultChain : handleSubmit}
          message={{ id: getSubmitMessage(store, isChainSwitchRequired) }}
        />
      </Container>
    </Container>
  );
};
