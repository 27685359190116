import { Text, Tooltip } from 'ui';
import { ReactComponent as LockPeriodIcon } from 'assets/icons/lock_period.svg';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';
import { useIntl } from 'react-intl';
import { useFeeMetadataQuery } from 'core';

interface FeeDetailsProps {
  isInsufficient: boolean;
  isGasless: boolean;
}

export const FeeDetails = ({ isInsufficient, isGasless }: FeeDetailsProps) => {
  const intl = useIntl();
  const { mgxLockAmount } = useFeeMetadataQuery();

  const TooltipContent = (
    <Text
      color="none"
      className="whitespace-pre-line"
      id={`swap.fee.${!isGasless && isInsufficient ? 'insufficient' : 'lock'}`}
      values={{ lockAmount: mgxLockAmount }}
    />
  );

  return (
    <Tooltip
      tooltipContent={(!isGasless || isInsufficient) && TooltipContent}
      tooltipClassName="!w-[336px] !h-[auto]"
      id="swapFee"
      inPortal
      className="flex items-center"
    >
      <Text color={isInsufficient ? 'alert' : 'accent'} className="align-middle">
        {isGasless ? '0' : mgxLockAmount} {intl.formatMessage({ id: 'common.mainToken' })}
      </Text>
      {!isGasless && !isInsufficient && (
        <LockPeriodIcon className="w-[14px] h-[14px] fill-accent ml-1" />
      )}
      {isInsufficient && <WarningIcon className="w-[18px] h-[auto] fill-alert ml-1 " />}
    </Tooltip>
  );
};
