import cls from 'classnames';
import { Container } from '../Container/Container';
import { ReactComponent as StarIcon } from '../../../assets/icons/star.svg';
import { ClassName } from 'core';

type IconSize = 'xs' | 's' | 'm' | 'l';
interface ActiveMiningIconProps extends ClassName {
  size?: IconSize;
}

const CLASS_MAP: Record<IconSize, Record<'icon' | 'blur', string>> = {
  xs: {
    icon: 'w-[10px] h-[10px]',
    blur: 'blur-[2px]',
  },
  s: {
    icon: 'w-[20px] h-[20px]',
    blur: 'blur-[3px]',
  },
  m: {
    icon: 'w-[26px] h-[26px]',
    blur: 'blur-[4px]',
  },
  l: {
    icon: 'w-[30px] h-[30px]',
    blur: 'blur-[5px]',
  },
};

export function ActiveMiningIcon({ className, size = 'xs' }: ActiveMiningIconProps) {
  const renderShadow = () => {
    return (
      <StarIcon
        className={cls(
          '_accent-premium absolute animate-shine flex justify-center items-center fill-highlight',
          CLASS_MAP[size].icon,
          CLASS_MAP[size].blur,
        )}
      />
    );
  };

  return (
    <Container
      justifyContent="center"
      alignItems="center"
      className={cls('relative', CLASS_MAP[size].icon, className)}
    >
      {renderShadow()}
      <StarIcon
        className={cls('fill-highlight', 'absolute opacity-80 animate-scale', CLASS_MAP[size].icon)}
      />
    </Container>
  );
}
