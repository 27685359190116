import { QueryOptional, useDelegatorRewards } from 'core';
import { Container, Skeleton, Text } from 'ui';
import { StakingRewardsBadge } from '../StakingRewardsBadge/StakingRewardsBadge';
import { StakingCardProps } from './StakingCard';

export function StakingCardRewards({ delegation }: StakingCardProps) {
  const { dailyRewards, isLoading } = useDelegatorRewards([delegation.owner.toString()]);

  const rewards = new Map(dailyRewards?.map((r) => [...r]));

  const renderRewards = (amount: QueryOptional<string>) => {
    if (isLoading) {
      return <Skeleton width="100px" height="16px" rounded className="my-2" />;
    }

    return amount ? <StakingRewardsBadge value={amount} /> : '-';
  };

  return (
    <Container column fullWidth className="p-[14px] bg-accent-soft rounded-lg">
      <Container fullWidth alignItems="start" justifyContent="space-between">
        <Container column className="w-1/2">
          <Text color="secondary" id="stakingCard.rewards.day" uppercase type="body-tiny" />
          {renderRewards(rewards.get(delegation.owner.toString()))}
        </Container>
        <Container column className="w-1/2">
          <Text color="secondary" id="stakingCard.rewards.month" uppercase type="body-tiny" />
          <Text>-</Text>
        </Container>
      </Container>
    </Container>
  );
}
