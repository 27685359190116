import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface PoolsUIStore {
  isSearchOpen: boolean;
  isCreatePoolOpen: boolean;
  setIsCreatePoolOpen: (isOpen: boolean) => void;
  setIsSearchOpen: (isOpen: boolean) => void;
}

export const usePoolsUIStore = create(
  devtools<PoolsUIStore>((set) => ({
    isSearchOpen: false,
    isCreatePoolOpen: false,
    setIsCreatePoolOpen: (isCreatePoolOpen) => set({ isCreatePoolOpen }),
    setIsSearchOpen: (isSearchOpen) => set({ isSearchOpen }),
  })),
);
