import cls from 'classnames';
import * as React from 'react';

import { Children, ClassName, TestId } from '../../../types';

type TabSize = 'l' | 's';

export interface TabProps
  extends Children,
    ClassName,
    TestId,
    Pick<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onClick'> {
  size?: TabSize;
  isActive?: boolean;
}

const SIZES = {
  l: 'font-body-l',
  s: 'font-body-m',
};

export function Tab({
  className,
  onClick,
  'data-testid': testId,
  children,
  size = 'l',
  isActive,
}: TabProps) {
  return (
    <div
      onClick={onClick}
      data-testid={testId}
      className={cls(
        'py-3 cursor-pointer',
        isActive && 'border-b-2 border-accent',
        !isActive && 'hover:border-transparent hover:text-neutral',
        SIZES[size],
        className,
      )}
    >
      {children}
    </div>
  );
}
