import { PoolWithShare, TestId, useInvestedPools } from 'core';
import { Button, Container, PositionRow, Skeleton, Text } from 'ui';
import { ReactComponent as PoolIcon } from 'icons/pool.svg';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export function MyPositions({ 'data-testid': testId = 'my-positions' }: TestId) {
  const { data, isLoading } = useInvestedPools();

  const positions = useMemo(() => data?.baseList?.sort(sortPositionsBySize), [data]);

  const renderEmptyPositions = () => (
    <Container
      fullWidth
      column
      justifyContent="center"
      alignItems="center"
      className="h-[480px] text-center px-4"
      data-testid={`${testId}-empty`}
    >
      <PoolIcon className="fill-secondary w-[40px] h-[40px] mb-2" />
      <Text type="body-l" color="secondary" id="wallet.positions.empty.title" className="mb-2" />
      <Text color="secondary" id="wallet.positions.empty.desc" />
      <Link to="/pools">
        <Button variant="primary" className="mt-8" message={{ id: 'wallet.positions.empty.cta' }} />
      </Link>
    </Container>
  );

  const renderLoading = () => (
    <Container column fullWidth justifyContent="space-between" className="px-1">
      {new Array(8).fill(true).map((_, i) => (
        <Container
          key={i}
          fullWidth
          justifyContent="space-between"
          alignItems="center"
          className="mb-6 px-2"
        >
          <Container alignItems="center">
            <Skeleton width="27px" height="24px" rounded />
            <Skeleton className="ml-4" width="50px" height="10px" />
            <Skeleton className="ml-2" width="10px" height="10px" rounded />
          </Container>
          <Container alignItems="center">
            <Skeleton width="10px" height="10px" rounded />
            <Skeleton className="ml-2" width="30px" height="10px" />
          </Container>
        </Container>
      ))}
    </Container>
  );

  return (
    <Container fullWidth column data-testid={testId} className="mt-4">
      {isLoading && renderLoading()}
      {!isLoading && positions?.length === 0 && renderEmptyPositions()}
      {!isLoading && positions && (
        <Container fullWidth column data-testid={`${testId}-data`}>
          {positions.map((pool) => (
            <PositionRow key={pool.id} pool={pool} />
          ))}
        </Container>
      )}
    </Container>
  );
}

function sortPositionsBySize(a: PoolWithShare, b: PoolWithShare) {
  return b.share.cmp(a.share);
}
