import { Banner, Button, Container, Input, Skeleton, Text, TokenIcon } from 'ui';
import { ReactComponent as BackIcon } from 'icons/arrow-left.svg';
import { ReactComponent as ChevronRightIcon } from 'icons/chevron-right.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import {
  AssetType,
  EnvConfig,
  transformToAsset,
  useRollupChainsQuery,
  useTokenContract,
} from 'core';
import { useCustomTokenDepositState } from './useCustomTokenDepositState';
import { isNil } from 'lodash-es';
import { DepositUIState, useDepositStore } from '../../store/useDepositStore';

interface CustomTokenDepositProps {
  onBack?(): void;
  onClose?(): void;
  titleId?: string;
}

export const CustomTokenDeposit = ({ onClose, onBack, titleId }: CustomTokenDepositProps) => {
  const {
    rollupChainsQuery: { data: _chains },
  } = useRollupChainsQuery();

  const { setUIState, setAsset, setChain, chain: depositChain, originAccount } = useDepositStore();

  const {
    selectedTokenContract,
    setSelectedTokenContract,
    selectedChain,
    setSelectedChain,
    isTokenContractValid,
  } = useCustomTokenDepositState();

  const {
    tokenContractMetadataQuery: { data: customTokenMetadata, isLoading, isError },
  } = useTokenContract(selectedChain, selectedTokenContract, originAccount?.address);

  const tokenMetadata = customTokenMetadata?.[0];

  const chains = _chains?.map((chain) => ({
    key: chain.chainId,
    value: (
      <Container
        fullWidth
        alignItems="center"
        className="p-3 hover:bg-hover transition-all cursor-pointer"
      >
        <img
          src={`${EnvConfig.ASSETS_URL}/channels/${chain.chainId}.png`}
          alt=""
          className="w-6 h-6 mr-2 border-none"
        />
        <Text>{chain.name}</Text>
      </Container>
    ),
  }));

  const handleTokenContractChange = (value: string) => {
    if (selectedTokenContract === value) {
      return;
    }
    setSelectedTokenContract(value);
  };

  const handleChainSelect = (value: string) => {
    if (selectedChain === value) {
      return;
    }

    setSelectedChain(value);
  };

  const renderChainSelectorContent = (chainId: string) => {
    const chain = _chains?.find(($) => $.chainId === chainId);

    if (!chain) {
      return <Text color="secondary" id="bridge.customToken.chain.placeholder" />;
    }

    return (
      <Container alignItems="center">
        {chain?.chainId && (
          <img
            src={`${EnvConfig.ASSETS_URL}/channels/${chain.chainId.toLowerCase()}.png`}
            alt=""
            className="w-6 h-6 mr-2 rounded-full"
          />
        )}
        <Text>{chain?.name}</Text>
      </Container>
    );
  };

  const handleAddCustomToken = () => {
    if (!tokenMetadata?.name) {
      return;
    }
    if (selectedChain !== depositChain?.id) {
      setChain(
        {
          id: selectedChain,
          icon: `${EnvConfig.ASSETS_URL}/channels/${selectedChain.toLowerCase()}.png`,
          title: _chains?.find((chain) => chain.chainId === selectedChain)?.name ?? '',
        },
        false,
      );
    }
    setUIState(DepositUIState.Default);

    setAsset({
      name: tokenMetadata.name,
      symbol: tokenMetadata.symbol,
      decimals: parseInt(tokenMetadata.decimals, 10),
      type: AssetType.Native,
      origin: tokenMetadata.origin,
      id: selectedTokenContract,
      source: {
        chainId: selectedChain,
        address: selectedTokenContract,
        iconUrl: tokenMetadata.source.iconUrl,
      },
    });
  };

  return (
    <Container fullWidth className={'min-h-[300px]'} column>
      <Container fullWidth justifyContent="space-between" className="px-2 mb-5">
        <Container alignItems="center">
          <Container onClick={onBack} className="cursor-pointer">
            <BackIcon className="fill-secondary w-5 h-auto" />
            <Text id={titleId} type="title-3" color="secondary" className="ml-5" />
          </Container>
          <ChevronRightIcon className="stroke-secondary w-4 h-auto mx-2" />
          <Text id="bridge.tokenSelect.custom.title" type="title-3" />
        </Container>
        <CloseIcon onClick={onClose} className="fill-secondary w-6 h-auto cursor-pointer" />
      </Container>

      <Container fullWidth column>
        <Input
          value={selectedChain}
          renderValue={renderChainSelectorContent}
          onChange={handleChainSelect}
          options={chains}
          placeholder="bridge.customToken.chain.placeholder"
          label="bridge.customToken.chain.label"
          isValid={!!selectedChain}
        />
        <Input
          isValid={selectedTokenContract === '' || isTokenContractValid}
          onChange={handleTokenContractChange}
          value={selectedTokenContract}
          placeholder="bridge.customToken.token.placeholder"
          className="mt-6"
          label="bridge.customToken.token.label"
        />
      </Container>

      {isLoading && isTokenContractValid && (
        <Container fullWidth className="mt-6" alignItems="center">
          <Skeleton rounded width="40px" height="40px" />
          <Container column className="ml-2">
            <Skeleton rounded width="43px" height="8px" />
            <Skeleton rounded width="82px" height="8px" className="mt-[6px]" />
          </Container>
        </Container>
      )}

      {!isLoading && tokenMetadata && isTokenContractValid && (
        <Container fullWidth className="mt-6" alignItems="center">
          <TokenIcon token={transformToAsset(tokenMetadata)} size="l" />
          <Container fullWidth justifyContent="space-between" alignItems="center" className="ml-3">
            <Container column>
              <Text type="title-3">{tokenMetadata.symbol ?? 'Unknown'}</Text>
              <Text color="secondary" type="body-s">
                {tokenMetadata.name ?? 'Unknown'}
              </Text>
            </Container>
            {!isNil(tokenMetadata.decimals) && (
              <Container>
                <Text type="body-s" color="secondary" className="mr-[2px]">
                  {tokenMetadata.decimals?.toString()}
                </Text>
                <Text type="body-s" color="secondary" id="common.decimals" />
              </Container>
            )}
          </Container>
        </Container>
      )}

      <Container fullWidth className="mt-6">
        {isError && (
          <Banner
            variant="alert"
            message={{ id: 'bridge.customToken.token.error.desc' }}
            values={{
              title: (
                <Text color="alert" weight="semibold" id="bridge.customToken.token.error.title" />
              ),
            }}
          />
        )}

        {selectedTokenContract !== '' && !isTokenContractValid && (
          <Banner
            variant="alert"
            message={{ id: 'bridge.customToken.token.error.desc' }}
            values={{
              title: (
                <Text weight="semibold" color="alert" id="bridge.customToken.token.error.title" />
              ),
            }}
          />
        )}

        {selectedTokenContract !== '' && isTokenContractValid && !isError && (
          <Banner variant="warning" message={{ id: 'bridge.customToken.token.warning.desc' }} />
        )}
      </Container>

      {isTokenContractValid && tokenMetadata && (
        <Button
          isDisabled={!isTokenContractValid || selectedTokenContract === '' || !tokenMetadata}
          variant="primary"
          size="huge"
          fullWidth
          onClick={handleAddCustomToken}
          data-testid="submit-custom-token"
          className="mt-6"
          message={{ id: 'bridge.customToken.add' }}
          values={{ symbol: tokenMetadata?.symbol ?? 'Unknown' }}
        />
      )}
    </Container>
  );
};
