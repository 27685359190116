import { useState } from 'react';
import { TestId, usePoolLiquidityHistory } from 'core';
import {
  Container,
  Text,
  LineChart,
  Timeframe,
  TimeframeSelector,
  timeframes,
  formatCurrency,
} from 'ui';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';

interface PoolLiquidityHistoryProps extends TestId {
  pool: PoolWithRatio;
}

export const PoolLiquidityHistory = ({ pool }: PoolLiquidityHistoryProps) => {
  const [currentTimeframe, setTimeframe] = useState<Timeframe>(timeframes[1]);

  const { poolVolumeHistoryQuery } = usePoolLiquidityHistory(
    `${pool.firstAsset.id}-${pool.secondAsset.id}`,
    currentTimeframe.days,
    currentTimeframe.interval,
  );

  const data = poolVolumeHistoryQuery.data?.volumes.map(([timestamp, volume]) => ({
    x: new Date(timestamp),
    y: volume,
  }));

  const formatUsd =
    (withoutSymbol = false) =>
    (value: string) =>
      formatCurrency(value, { precise: false, withoutSymbol }) || '0';

  return (
    <Container
      column
      className="w-full h-[270px] mt-10 rounded-lg pl-2"
      data-testid="pool-liq-history"
    >
      <Container alignItems="center" justifyContent="space-between" fullWidth className="mb-6">
        <Text type="body-l" className="text-center mb-1" id="pool.detail.liquidity.chart.title" />
        <TimeframeSelector onSelect={setTimeframe} currentTimeframe={currentTimeframe} />
      </Container>
      <LineChart
        withYAxis
        data={data}
        timeframe={currentTimeframe}
        id={`liquidity-${currentTimeframe.key}`}
        yAxisTitleId="lineChart.yAxis.usd"
        formatYAxis={formatUsd(true)}
        formatTooltipYValue={formatUsd()}
        isEmpty={!poolVolumeHistoryQuery.isLoading || poolVolumeHistoryQuery.isError}
      />
    </Container>
  );
};
