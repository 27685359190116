import cls from 'classnames';

import { ClassName, TestId } from '../../types';
import { Container } from '../Container/Container';
import { Text } from '../Text/Text';

type CountBadgeVariant = 'accent' | 'highlight' | 'secondary';

export interface CountBadgeProps extends ClassName, TestId {
  count: number | string;
  variant?: CountBadgeVariant;
}

const STYLES = {
  accent: 'text-accent border-highlight bg-accent-soft',
  highlight: 'text-highlight border-highlight bg-highlight-soft',
  secondary: 'text-secondary border-default',
};

export function CountBadge({
  className,
  'data-testid': testId,
  count,
  variant = 'secondary',
}: CountBadgeProps) {
  return (
    <Container
      inline
      alignItems="center"
      justifyContent="center"
      data-testid={testId}
      className={cls(
        'p-[4px] bg-default border-1 rounded-full h-[20px] min-w-[20px]',
        STYLES[variant],
        className,
      )}
    >
      <Text className="text-[12px]">{count}</Text>
    </Container>
  );
}
