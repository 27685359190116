import { SubmittableExtrinsic } from '@polkadot/api/types';
import { TransactionError, TransactionStore } from './private/useTransactionStore';
import { TxStatus } from './Transaction';
import { ApiPromise } from '@polkadot/api';
import { ExtrinsicSubscriptionData, MangataGenericEvent, signTx as signGaspTx } from 'gasp-sdk';
import { EnvConfig } from '../../envConfig';
import { ExtrinsicStatusCallback, HandleStatusChangeFn, TxBase } from './TxBase';
import type { Config } from 'wagmi';

export class ExtrinsicTx extends TxBase {
  private extrinsic?: SubmittableExtrinsic<'promise'>;

  constructor(
    private api: ApiPromise,
    store: TransactionStore,
    private wagmiConfig: Config,
    private address: string,
    private _handleStatusChange?: HandleStatusChangeFn,
    private _handleExtrinsicStatusChange?: ExtrinsicStatusCallback,
  ) {
    super(store);
  }

  setTx(call: SubmittableExtrinsic<'promise'>) {
    this.extrinsic = call;
    return this;
  }

  async send() {
    try {
      const result = await this.execute();

      if (!this.options.doneOnTrigger) {
        this.done();
      }

      return result;
    } catch (e) {
      this.handleError(e, {
        address: this.address,
        extrinsic: {
          data: this.extrinsic?.method.toHuman(),
          hash: this.extrinsic?.hash?.toString(),
        },
        wagmiState: this.wagmiConfig.state,
      });

      return null;
    }
  }

  doneWithError(error: TransactionError) {
    super.doneWithError(error, {
      address: this.address,
      extrinsic: {
        data: this.extrinsic?.method.toHuman(),
        hash: this.extrinsic?.hash?.toString(),
      },
      wagmiState: this.wagmiConfig.state,
    });
  }

  private async execute() {
    if (!this.extrinsic || !this.address) {
      return null;
    }

    return signGaspTx(this.api, this.extrinsic, this.address, {
      wagmiConfig: this.wagmiConfig,
      statusCallback: this.handleStatusChange,
      extrinsicStatus: this.handleExtrinsicStatusChange,
    });
  }

  private handleExtrinsicStatusChange = (events: MangataGenericEvent[]) => {
    if (this._handleExtrinsicStatusChange) {
      this._handleExtrinsicStatusChange(events);
      return;
    }
  };

  private handleStatusChange = (result: ExtrinsicSubscriptionData) => {
    if (this._handleStatusChange) {
      this._handleStatusChange(result);
      return;
    }

    const { status, isError } = result;
    if (status.isInvalid || isError) {
      this.store.set(this.id, { status: TxStatus.Error });
    }

    if (status.isReady) {
      this.store.set(this.id, {
        status: TxStatus.Pending,
        explorerUrl:
          this.options.showExplorerLink && result.txHash && EnvConfig.EXPLORER_URL
            ? `${EnvConfig.EXPLORER_URL}/extrinsic/${result.txHash.toHex()}`
            : undefined,
      });
    }
  };
}
