import cls from 'classnames';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as MetamaskIcon } from '../../../assets/icons/metamask.svg';
import { Button } from '../Button/Button';
import { Text } from '../Text/Text';
import { Container } from '../Container/Container';
import { shortenAddress } from '../../utils';
import { useRef, MouseEventHandler, useEffect, useState } from 'react';
import { TestId, WalletAccount } from 'core';

interface AccountSelectProps extends TestId {
  selectedAccount: WalletAccount | null;
  accounts: Array<WalletAccount>;
  onSelect?: (account: WalletAccount) => void;
  disabled?: boolean;
}

export function AccountSelect({
  selectedAccount,
  accounts,
  onSelect,
  disabled = false,
  'data-testid': testId,
}: AccountSelectProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    function handleMouseDown(e: MouseEvent) {
      if (!ref.current?.contains(e.target as Node) && isOpen) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [ref, isOpen]);

  const handleSelect = (account: WalletAccount) => () => {
    setIsOpen(!isOpen);
    onSelect?.(account);
  };

  return (
    <div className="relative w-full" ref={ref}>
      <Button
        variant="base"
        data-testid={testId}
        className={cls(
          'transition-all border border-default w-full px-3 py-2 bg-soft rounded-full',
          isOpen && 'bg-item-hover',
          disabled && 'border-none cursor-default',
          !selectedAccount && 'cursor-default',
        )}
        iconClassName="stroke-secondary"
        TrailIcon={selectedAccount && !disabled ? ChevronDown : undefined}
        onClick={selectedAccount && !disabled ? handleButtonClick : undefined}
      >
        {!selectedAccount && (
          <Container column fullWidth>
            <Text type="body-s" color="secondary" id="bridge.accountSelect.noAccount" />
          </Container>
        )}
        {selectedAccount && (
          <Container fullWidth alignItems="center">
            <MetamaskIcon className="fill-secondary w-[24px] h-auto" />
            <Text
              type="body-s"
              color="secondary"
              className="mx-2 max-w-full text-ellipsis overflow-hidden"
            >
              {selectedAccount.name || shortenAddress(selectedAccount.address)}
            </Text>
          </Container>
        )}
      </Button>
      <Container
        column
        fullWidth
        className={cls(
          'mt-1 p-2 absolute left-0 bg-soft border border-default shadow-primary',
          'rounded-lg z-dropdown max-h-[250px] overflow-y-auto',
          !isOpen && 'hidden',
        )}
        data-testid={`${testId}-list`}
      >
        <ul className="w-full">
          {accounts.map((account) => (
            <li
              key={account.address}
              className="p-2 hover:bg-default rounded w-full cursor-pointer"
              onClick={handleSelect(account)}
            >
              <Container fullWidth alignItems="center" data-testid={`${testId}-list-item`}>
                <Text
                  type="title-3"
                  className="mr-2 max-w-[60%] overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {account.name}
                </Text>
                <Text type="body-s" color="secondary">
                  {shortenAddress(account.address)}
                </Text>
              </Container>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  );
}
