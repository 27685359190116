import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN, toBN } from 'gasp-sdk';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ApiPromise } from '@polkadot/api';
import {
  ReserveSourceType,
  QueryOptional,
  StakeReserveSource,
  ActivateLiquidityReserveSource,
} from '../../../../../services';
import { BN_ZERO } from '@polkadot/util';
import { createLiqActivationTxsForStaking } from '../../../../pool/activateLiquidity/services/ActivateLiquidityMutationService';
import { ReserveSourceForTx } from '../../../../balance';
import { TxAsset } from '../../../../transaction';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { AssetType } from '../../../../token';

type ScheduleBondMoreFeeParams = Readonly<
  [
    queryKey: string,
    address: QueryOptional<string>,
    collatorAddress: QueryOptional<string>,
    stakedAsset: QueryOptional<TxAsset>,
  ]
>;

export const getScheduleBondMoreFee =
  (
    api: ApiPromise | null,
    sources: ReserveSourceForTx<StakeReserveSource>,
    activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  ) =>
  async ({
    queryKey: [, address, collatorAddress, stakedAsset],
  }: QueryFunctionContext<ScheduleBondMoreFeeParams>) => {
    if (!api || !address || !collatorAddress || !stakedAsset || !sources) {
      return null;
    }

    const txs = createBondMoreTx(api, collatorAddress, sources, activateLiqSources, stakedAsset);

    if (txs.length === 1) {
      const feeInfo = await txs[0].paymentInfo(address);

      return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    }

    const txFees = await Promise.all(txs.map((tx) => tx.paymentInfo(address)));

    const batchingFee = await api.tx.utility.batchAll([]).paymentInfo(address);

    const totalFee = txFees
      .reduce((acc, curr) => acc.add(curr.partialFee), BN_ZERO)
      .add(batchingFee.partialFee);

    return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };

const createBondMoreTx = (
  api: ApiPromise,
  collatorAddress: string,
  sources: ReserveSourceForTx<StakeReserveSource>,
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  txAsset: TxAsset,
) => {
  const sufficientAmountSingleSource = sources.available.find(([, amount]) => {
    return toBN(txAsset.amount).sub(amount).lte(BN_ZERO);
  });

  const balanceSource = (() => {
    if (sufficientAmountSingleSource) {
      return sufficientAmountSingleSource[0];
    }
    if (activateLiqSources.hasAvailable && txAsset.type === AssetType.LP) {
      return ReserveSourceType.ActivatedUnstakedReserves;
    }
    return ReserveSourceType.AvailableBalance;
  })();

  const txs: SubmittableExtrinsic<'promise'>[] = [];

  if (
    !sufficientAmountSingleSource &&
    activateLiqSources.hasAvailable &&
    txAsset.type === AssetType.LP
  ) {
    txs.push(...createLiqActivationTxsForStaking(api, activateLiqSources, txAsset));
  }

  txs.push(
    api.tx.parachainStaking.scheduleDelegatorBondMore(
      collatorAddress,
      toBN(txAsset.amount),
      balanceSource,
    ),
  );

  return txs;
};
