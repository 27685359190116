import { uniqBy } from 'lodash-es';
import { TokenFilter } from '../../../user/useUserStore';
import { AssetRegistryAssetMetadata } from '../../../../services/sdk/mangataQueryApi';
import { Asset } from '../../Token';
import { transformToAsset } from '../../shared';
import { StashTokensData } from '../../stash/StashToken';
import { QueryOptional } from '../../../../services';
import { InternalBalance } from '../../../balance/internal/services/InternalBalancesService';
import { RollupToken } from '../../../rollup/stash/RollupStash';

interface TokenFilterSelectorProps {
  stash: StashTokensData | undefined;
  native: QueryOptional<AssetRegistryAssetMetadata[]>;
  liquidity: QueryOptional<AssetRegistryAssetMetadata[]>;
  tokenFilters: TokenFilter[];
  balances: QueryOptional<Map<string, InternalBalance>>;
  rollupTokens: QueryOptional<RollupToken[]>;
}

interface NativeAssets {
  verified: Asset[];
  other: Asset[];
  all: Asset[];
  liquidity: Asset[];
}

export const tokenFilterSelector = ({
  balances,
  native,
  liquidity,
  stash,
  tokenFilters,
  rollupTokens,
}: TokenFilterSelectorProps) => {
  const rollupTokenIds = rollupTokens?.map((token) => token.l2Id);
  const rollupAssets =
    native?.filter((asset) => rollupTokenIds?.includes(asset.id)).map(transformToAsset) ?? [];
  const verifiedIds = new Set(rollupAssets.map((token) => token.id));
  const liquidityIds = new Set(liquidity?.map((token) => token.id) ?? []);

  const all = [...(native ?? []), ...(liquidity ?? [])];

  const byType = all.reduce<NativeAssets>(
    (acc, a) => {
      const asset = transformToAsset(a);
      acc.all.push(asset);

      if (liquidityIds.has(asset.id) || verifiedIds.has(asset.id)) {
        if (liquidityIds.has(asset.id)) {
          acc.liquidity.push(asset);
        }

        if (verifiedIds.has(asset.id)) {
          acc.verified.push(asset);
        }
      } else {
        acc.other.push(asset);
      }

      return acc;
    },
    { all: [], verified: [], other: [], liquidity: [] },
  );

  const tokensByFilter = {
    [TokenFilter.UserOwned]:
      byType?.all.filter((asset) => balances?.get(asset.id)?.free.gtn(0)) ?? [],
    [TokenFilter.LiquidityPool]: byType?.liquidity ?? [],
    [TokenFilter.MangataDefault]: rollupAssets,
    [TokenFilter.Other]: byType?.other ?? [],
  };

  if (!liquidity || !native || !rollupTokenIds) {
    return {
      tokenFilters,
      tokensByFilter,
      filteredAssets: [],
    };
  }

  const filteredAssets = uniqBy(tokenFilters.map((filter) => tokensByFilter[filter]).flat(), 'id');

  return {
    tokenFilters,
    tokensByFilter,
    filteredAssets,
  };
};
