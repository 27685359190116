import { Container, IconButton, Text, shortenAddress, WalletLogo } from 'ui';
import { ReactComponent as WalletInfiniteIcon } from 'icons/wallet-infinite.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import cls from 'classnames';
import { TestId, WalletAccount, useModalStore } from 'core';
import { useIntl } from 'react-intl';

interface AccountInfoProps extends TestId {
  wallet: string;
  account: WalletAccount;
  onToggle: () => void;
}

export function AccountInfo({
  wallet,
  account,
  onToggle,
  'data-testid': testId,
}: AccountInfoProps) {
  const intl = useIntl();
  const { openAccountSettings } = useModalStore();

  const ACCOUNT_INFO_CLASS = cls(
    'transition-all group',
    account && 'visible',
    !account && 'invisible hidden',
    'h-[56px]',
  );

  const renderAccountInfo = () => {
    return (
      <Container
        data-testid={`${testId}-account`}
        alignItems="center"
        justifyContent="space-between"
      >
        <WalletLogo className="rounded-full mr-4 w-8 h-8" wallet={wallet} />
        <Container column>
          <Text
            type="title-4"
            className={cls('overflow-hidden text-ellipsis whitespace-nowrap', 'w-[170px]')}
          >
            {account.name || shortenAddress(account.address)}
          </Text>
          <Text
            color="secondary"
            type="body-tiny"
            id="walletChip.connectedWith"
            values={{ wallet: intl.formatMessage({ id: `wallet.extension.metamask` }) }}
          />
        </Container>
      </Container>
    );
  };

  const renderActions = () => {
    return (
      <Container row alignItems="center">
        <IconButton
          data-testid={`${testId}-settings`}
          Icon={WalletInfiniteIcon}
          iconClassName="stroke-secondary fill-none"
          onClick={openAccountSettings}
        />
        <IconButton Icon={CloseIcon} iconFill iconStroke={false} onClick={onToggle} />
      </Container>
    );
  };

  return (
    <Container
      justifyContent="space-between"
      alignItems="stretch"
      row
      fullWidth
      className={cls(ACCOUNT_INFO_CLASS)}
    >
      {renderAccountInfo()}
      {renderActions()}
    </Container>
  );
}
