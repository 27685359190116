import { useMutation } from '@tanstack/react-query';
import { useTransaction } from '../../../../transaction';
import { useApi } from '../../../../../services';
import { submitDelegate } from '../services/DelegateMutationService';
import { useAccounts } from '../../../../account';
import { useConfig } from 'wagmi';

export const useDelegateMutation = () => {
  const { selectedAccount } = useAccounts();
  const config = useConfig();
  const api = useApi();
  const { transactionStore } = useTransaction();

  const delegateMutation = useMutation(
    ['delegate'],
    submitDelegate(api, selectedAccount?.address, config, transactionStore),
  );

  return { delegateMutation };
};
