import { useAccounts, useDelegators, useSearch } from 'core';
import {
  StakingCard,
  StakingCardSkeleton,
  useStakingUIStore,
  useWalletUIStore,
  WalletStatus,
} from 'modules';
import { Button, Container, Grid, Text } from 'ui';
import { ParachainStakingBond } from '@polkadot/types/lookup';
import { ReactComponent as StakingIcon } from 'icons/staking.svg';
import { ReactComponent as DisconnectedWallet } from 'icons/wallet-no.svg';
import { useUIStore } from '../../../store/useUIStore';
import RockQuestion from '../../../assets/images/rock-question.png';
import { isNil } from 'lodash-es';
import { StakingListNotifications } from '../list/notifications/StakingListNotifications';

export function UserStakePage() {
  const {
    delegatorStateQuery: { data: delegator, isLoading },
  } = useDelegators();

  const { selectedAccount } = useAccounts();
  const { setStatus } = useWalletUIStore();
  const { setIsSidebarOpen } = useUIStore();
  const { isSearchOpen } = useStakingUIStore();

  const delegations = delegator?.delegations;

  const { entries, isActive } = useSearch<ParachainStakingBond>({
    list: delegations,
    toSearch: ['owner'],
  });

  const connectWallet = () => {
    setIsSidebarOpen(true);
    setStatus(WalletStatus.SelectWallet);
  };

  const hasDelegations = !isNil(entries) && entries.length > 0;

  const oddEntries = entries?.filter((_, i) => i % 2 === 0);
  const evenEntries = entries?.filter((_, i) => i % 2 !== 0);

  const renderDelegations = (delegation: ParachainStakingBond) => {
    return (
      <StakingCard
        key={delegation.owner.toString()}
        delegation={delegation}
        requests={delegator?.requests.requests}
        className="mb-6"
      />
    );
  };

  const renderLoading = () => {
    return (
      <Grid cols={2} fullWidth className="mt-6 gap-4">
        <StakingCardSkeleton />
        <StakingCardSkeleton />
        <StakingCardSkeleton />
      </Grid>
    );
  };

  const renderEmptyDelegations = () => {
    return (
      <Container
        column
        fullWidth
        className="bg-input rounded-xl py-[120px] mt-6"
        alignItems="center"
      >
        <StakingIcon className="fill-accent stroke-accent w-[44px] h-auto" />
        <Text type="title-2" id="staking.collators.delegations.empty.title" className="mt-4" />
        <Text
          color="secondary"
          id="staking.collators.delegations.empty.desc"
          className="mt-2 whitespace-pre-line text-center"
        />
        <Button
          element="link"
          to="/staking"
          className="mt-4"
          message={{ id: 'staking.collators.delegations.empty.cta' }}
        />
      </Container>
    );
  };

  const renderConnectWallet = () => {
    return (
      <Container
        column
        fullWidth
        className="bg-input rounded-xl py-[120px] mt-6"
        alignItems="center"
      >
        <DisconnectedWallet className="stroke-secondary w-[44px] h-auto" />
        <Text
          type="title-2"
          id="staking.collators.delegations.disconnected.title"
          className="mt-4"
        />
        <Text
          color="secondary"
          id="staking.collators.delegations.disconnected.desc"
          className="mt-2 whitespace-pre-line text-center"
        />
        <Button
          onClick={connectWallet}
          className="mt-4"
          message={{ id: 'staking.collators.delegations.disconnected.cta' }}
        />
      </Container>
    );
  };

  const renderEmptySearchResults = () => {
    return (
      <Container
        column
        fullWidth
        className="bg-input rounded-xl py-[120px] mt-6"
        alignItems="center"
      >
        <img src={RockQuestion} alt="rock-question" className="w-20 h-auto mx-auto" />
        <Text
          type="title-2"
          id="staking.collators.delegations.emptySearch.title"
          className="mt-4"
        />
        <Text
          color="secondary"
          id="staking.collators.delegations.emptySearch.desc"
          className="mt-2 whitespace-pre-line text-center"
        />
      </Container>
    );
  };

  return (
    <Container column fullWidth>
      <Container column fullWidth>
        <Text type="title-3" id="staking.page.own.title" />
        <Text color="secondary" id="staking.page.own.desc" className="mt-1 whitespace-pre-line" />
      </Container>

      {!isActive && selectedAccount && <StakingListNotifications className="mt-6" />}

      {!selectedAccount ? (
        renderConnectWallet()
      ) : isLoading ? (
        renderLoading()
      ) : hasDelegations ? (
        <Container fullWidth className="mt-6">
          <Container column fullWidth className="w-1/2 pr-2">
            {oddEntries?.map(renderDelegations)}
          </Container>
          <Container column fullWidth className="w-1/2 pl-2">
            {evenEntries?.map(renderDelegations)}
          </Container>
        </Container>
      ) : isSearchOpen && isActive ? (
        renderEmptySearchResults()
      ) : (
        renderEmptyDelegations()
      )}
    </Container>
  );
}
