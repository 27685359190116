import { Container, Text } from 'ui';
import avatarImg from 'images/avatar_hand.png';
import { ReactComponent as InfoIcon } from 'icons/info-filled.svg';

export const StakingFullBanner = () => {
  return (
    <Container fullWidth>
      <Container
        justifyContent="space-between"
        fullWidth
        className="bg-highlight-soft rounded-lg p-4 px-6 relative mb-5"
      >
        <Container column>
          <Container alignItems="center">
            <InfoIcon className="fill-accent w-6 h-auto mr-2" />
            <Text type="title-3" id="staking.list.full.title" />
          </Container>
          <Text
            type="body-m"
            color="secondary"
            id="staking.list.full.desc"
            className="mt-2 max-w-[580px]"
          />
        </Container>
        <img src={avatarImg} className="absolute right-4 bottom-0" alt="avatar" />
      </Container>
    </Container>
  );
};
