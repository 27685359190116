import cls from 'classnames';
import { Container, Grid, Skeleton, Text } from 'ui';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';
import { CollatorRowItem, useStakingUIStore } from 'modules';
import { Asset, ClassName, TestId } from 'core';
import { fromBN, toBN } from 'gasp-sdk';
import { isNil, last } from 'lodash-es';
import RockQuestion from '../../../../assets/images/rock-question.png';

interface CollatorListSectionProps extends ClassName, TestId {
  list: ParachainStakingCollatorCandidate[] | null | undefined;
  assets: Map<string, Asset>;
  titleId: string;
  descId: string;
  collatorApy?: Map<string, string> | null;
  isLoading: boolean;
}

const renderHeader = () => (
  <Grid cols={7} gap="l" className="w-full px-6 py-4 border-b border-default">
    <Text className="col-span-2" id="staking.list.header.collator" color="secondary" />
    <Text id="staking.list.header.totalStake" color="secondary" />
    <Text id="staking.list.header.token" color="secondary" />
    <Text id="staking.list.header.apr" color="secondary" />
    <Text id="staking.list.header.minStake" color="secondary" />
    <Text id="staking.list.header.delegatorsCount" color="secondary" />
  </Grid>
);

export const calculateMinBond = (collator: ParachainStakingCollatorCandidate, asset: Asset) => {
  if (collator.bottomDelegations.length === 0 && collator.topDelegations.length < 13) {
    return '1';
  }

  const min = last(collator.topDelegations)?.amount.add(toBN('1', asset.decimals)) ?? null;

  return min ? fromBN(min, asset.decimals) : null;
};

const renderList = (
  list: Array<ParachainStakingCollatorCandidate> | null | undefined,
  assets: Map<string, Asset>,
  collatorApy?: Map<string, string> | null,
) => {
  return list?.map((candidate) => {
    const asset = assets.get(candidate.liquidityToken.toString());
    const minBond = asset ? calculateMinBond(candidate, asset) : null;
    const bondedAmount = asset !== undefined ? fromBN(candidate.bond.toBn(), asset.decimals) : null;
    const apy = collatorApy?.get(candidate.id.toString());

    return (
      <CollatorRowItem
        data={candidate}
        apy={apy}
        key={candidate.id.toString()}
        bond={bondedAmount}
        minBond={minBond ?? undefined}
        lpAsset={assets.get(candidate.liquidityToken.toString()) ?? null}
      />
    );
  });
};

const renderLoading = () => {
  return Array.from({ length: 3 }).map((_, i) => (
    <Grid cols={7} gap="l" className="w-full px-3 py-6 border-b border-default" key={i}>
      <Skeleton width="100px" height="12px" className="col-span-2" />
      <Skeleton width="50px" height="12px" />
      <Skeleton width="50px" height="12px" />
      <Skeleton width="30px" height="12px" />
      <Skeleton width="50px" height="12px" />
      <Skeleton width="50px" height="12px" />
    </Grid>
  ));
};

export function CollatorListSection({
  list,
  collatorApy,
  assets,
  titleId,
  descId,
  className,
  isLoading,
  'data-testid': testId,
}: CollatorListSectionProps) {
  const { isSearchOpen } = useStakingUIStore();

  const renderEmptySearchResult = () => (
    <Container column fullWidth className="bg-input rounded-xl py-[120px]" alignItems="center">
      <img src={RockQuestion} alt="rock-question" className="w-20 h-auto mx-auto" />
      <Text type="title-2" id="staking.collators.searchResult.empty.title" className="mt-4" />
      <Text
        color="secondary"
        id="staking.collators.searchResult.empty.desc"
        className="mt-2 whitespace-pre-line text-center"
      />
    </Container>
  );

  return (
    <Container
      column
      fullWidth
      className={cls('bg-default rounded-lg pb-6', className)}
      data-testid={testId}
    >
      {isSearchOpen && !isLoading && (isNil(list) || list.length === 0) ? (
        renderEmptySearchResult()
      ) : (
        <>
          <Container column fullWidth>
            <Text type="title-3" id={titleId} />
            <Text color="secondary" id={descId} className="mt-1 whitespace-pre-line" />
          </Container>
          <Container column fullWidth className="mt-6 border border-default rounded-xl">
            {renderHeader()}
            <Container column fullWidth className="px-2">
              {isLoading ? renderLoading() : renderList(list, assets, collatorApy)}
            </Container>
          </Container>
        </>
      )}
    </Container>
  );
}
