import { FormEvent, SyntheticEvent, useState } from 'react';
import cls from 'classnames';

import { ClassName, TestId } from '../../types';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as ClearIcon } from '../../../assets/icons/close.svg';
import { Button } from '../Button/Button';

const PREVENT_FOCUS_ONCLICK = (e: SyntheticEvent) => e.preventDefault();

export interface SearchBarProps
  extends ClassName,
    TestId,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, 'autoFocus'> {
  onChange?: (value: string) => void;
  placeholder?: string;
  defaultValue?: string;
  inputClassName?: string;
}

export function SearchBar({
  onChange,
  className,
  inputClassName,
  placeholder,
  defaultValue = '',
  'data-testid': testId,
  autoFocus,
}: SearchBarProps) {
  const [value, setValue] = useState(defaultValue);

  const handleChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    setValue(currentTarget.value);

    if (onChange) {
      onChange(currentTarget.value);
    }
  };

  const handleClear = () => {
    setValue('');

    if (onChange) {
      onChange('');
    }
  };

  return (
    <label className={cls('relative block h-[48px]', className)}>
      <input
        type="search"
        data-testid={testId}
        autoFocus={autoFocus}
        onChange={handleChange}
        value={value}
        className={cls(
          'text-neutral py-[14px] px-12 font-body-m border',
          'rounded-md align-bottom border-strong bg-default',
          'hover:bg-hover focus:bg-base hover:border-strong/[0.45]',
          'focus:ring-0 focus:outline-none focus:border-highlight w-full',
          'peer placeholder:text-secondary relative top-[1px]',
          inputClassName,
        )}
        placeholder={placeholder}
      />
      <div
        className={cls(
          'peer-focus:stroke-accent absolute inset-y-0 left-4',
          'flex items-center pointer-events-none',
        )}
      >
        <SearchIcon className="h-auto w-[20px] top-[1px] relative stroke-secondary" />
      </div>
      <Button
        className={cls(
          'p-1 absolute inset-y-0 right-3 flex items-center',
          'hidden peer-focus:flex focus:flex',
        )}
        data-testid={`${testId}-close`}
        onClick={handleClear}
        onMouseDown={PREVENT_FOCUS_ONCLICK}
        variant="base"
      >
        <ClearIcon className="h-auto w-[16px] top-[1px] relative stroke-secondary" />
      </Button>
    </label>
  );
}
