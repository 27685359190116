import { FormattedMessage } from 'react-intl';
import { AdaptiveTxFee, Asset, TestId, isAmountGtZero } from 'core';
import { StartStakingUIStoreFormError, useStartStakingStore } from '../store/useStartStakingStore';
import { Text, Tooltip, FormatAmount, Skeleton, WidgetDetailRow, Container, Banner } from 'ui';
import Decimal from 'decimal.js';

import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { animated, useTransition } from '@react-spring/web';
import { isNull } from 'lodash-es';

interface StartStakingWidgetDetailsProps extends TestId {
  feeData: AdaptiveTxFee['current'] | null | undefined;
  isFeeLoading: boolean;
  minStakeAmount: string | null;
  stakedAsset: Asset | null;
}

export const StartStakingWidgetDetails = ({
  feeData,
  isFeeLoading,
  minStakeAmount,
  stakedAsset,
  'data-testid': testId = 'stake-details',
}: StartStakingWidgetDetailsProps) => {
  const { formError, amount } = useStartStakingStore();

  const isFeeInsufficient = !!formError[StartStakingUIStoreFormError.InsufficientFee];
  const testIdValue = testId + '-fee-value';

  const Fee = feeData && (
    <Text type="label" color={isFeeInsufficient ? 'alert' : 'neutral'}>
      ≈
      <FormatAmount
        data-testid={testIdValue}
        value={feeData?.amount ?? ''}
        options={{ precision: 3, maxChars: 20 }}
        className="mx-1"
      />
      {feeData?.symbol || ''}
    </Text>
  );

  const detailsTransition = useTransition(isAmountGtZero(amount), {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 32 },
    leave: { opacity: 0, height: 0 },
  });

  const renderFee = () => {
    switch (true) {
      case feeData && isFeeInsufficient:
        return (
          <Tooltip
            tooltipContent={<FormattedMessage id="pool.provide.fee.insufficient" />}
            className="flex items-center"
            inPortal
            leftOffset={false}
          >
            {Fee}
            <InfoIcon className={'w-[24px] h-[24px] ml-1 fill-alert'} />
          </Tooltip>
        );
      case !feeData && isFeeLoading:
        return (
          <Skeleton height="14px" width="70px" className={isFeeInsufficient ? 'my-[5px]' : ''} />
        );

      case !!feeData:
        return Fee;

      default:
        return (
          <Text type="label" color="secondary">
            -
          </Text>
        );
    }
  };

  const renderMinStakeBanner = () => {
    if (
      !isAmountGtZero(amount) ||
      isNull(minStakeAmount) ||
      new Decimal(amount).gte(minStakeAmount)
    ) {
      return null;
    }

    return (
      <Banner
        variant="warning"
        className="mt-5"
        data-testid={`${testId}-minAmount-banner`}
        message={{ id: 'staking.new.minAmount.info' }}
        values={{ minStakeAmount, symbol: stakedAsset?.symbol }}
      />
    );
  };

  return (
    <Container column fullWidth data-testid={testId}>
      {renderMinStakeBanner()}
      {detailsTransition((style, visible) =>
        visible ? (
          <animated.div style={style} className="w-full overflow-hidden mt-4">
            <WidgetDetailRow
              label={
                <Text id="staking.new.fee" type="label" color={'secondary'} className="mr-2" />
              }
              value={renderFee()}
              data-testid={`${testId}-fee`}
            />
          </animated.div>
        ) : null,
      )}
    </Container>
  );
};
