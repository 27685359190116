import { Button, Container, Spinner, Text, TokenIcon } from 'ui';
import { Transaction, TxStatus, useTransactionStore } from 'core';
import { DepositUIState, useDepositStore } from '../../store/useDepositStore';

interface DepositTxInfoProps {
  approveTx: Transaction | null;
  depositTx: Transaction | null;
}

export const DepositTxInfo = ({ approveTx, depositTx }: DepositTxInfoProps) => {
  const { setUIState } = useDepositStore();
  const { remove } = useTransactionStore();

  const anyTx = approveTx ?? depositTx;
  const token = anyTx?.metadata.tokens?.[0];
  const amount = anyTx?.metadata.tokens?.[0].amount;

  const titleId = (() => {
    if (depositTx) {
      switch (depositTx.status) {
        case TxStatus.Pending:
          return 'bridge.deposit.pending.title';
        case TxStatus.Confirming:
          return 'bridge.deposit.confirm.title';
        case TxStatus.Error:
          return 'bridge.deposit.error.title';
      }
    }

    if (approveTx) {
      switch (approveTx.status) {
        case TxStatus.Pending:
          return 'bridge.allowance.pending.title';
        case TxStatus.Confirming:
        case TxStatus.Success:
          return 'bridge.allowance.confirm.title';
        case TxStatus.Error:
          return 'bridge.allowance.error.title';
      }
    }

    return '';
  })();

  const descId = (() => {
    if (depositTx) {
      switch (depositTx.status) {
        case TxStatus.Pending:
          return 'bridge.deposit.pending.desc';
        case TxStatus.Confirming:
          return 'bridge.deposit.confirm.desc';
        case TxStatus.Error:
          return 'bridge.deposit.error.desc';
      }
    }

    if (approveTx) {
      switch (approveTx.status) {
        case TxStatus.Pending:
          return 'bridge.allowance.pending.desc';
        case TxStatus.Confirming:
        case TxStatus.Success:
          return 'bridge.allowance.confirm.desc';
        case TxStatus.Error:
          return 'bridge.allowance.error.desc';
      }
    }
    return '';
  })();

  const handleClose = () => {
    setUIState(DepositUIState.Default);
    remove(approveTx?.id);
    remove(depositTx?.id);
  };

  const renderFooter = () => {
    if (anyTx?.status === TxStatus.Error) {
      return (
        <Button
          data-testid="deposit-status-back"
          onClick={handleClose}
          message={{ id: 'bridge.button.back' }}
        />
      );
    }

    return token && amount ? (
      <Container
        fullWidth
        justifyContent="center"
        className="py-4 px-2 bg-soft rounded-full ml-auto"
      >
        <Text id={depositTx ? 'bridge.deposit.confirm.info' : 'bridge.allowance.confirm.info'} />
        <TokenIcon token={token} size="s" className="mx-2" />
        <Text data-testid="deposit-status-amount">
          {amount} {token.symbol}
        </Text>
      </Container>
    ) : null;
  };

  return (
    <Container
      fullWidth
      alignItems="center"
      column
      justifyContent="end"
      className="h-full min-h-[400px]"
    >
      <Container column alignItems="center" grow justifyContent="center">
        <Spinner
          data-testid="deposit-status"
          state={anyTx?.status === TxStatus.Error ? 'error' : 'loading'}
          className="!w-[60px]"
        />

        <Text type="title-1" id={titleId} className="mt-6 mb-2" />
        <Text
          color="secondary"
          id={descId}
          values={{ error: anyTx?.error?.name ?? anyTx?.error?.message ?? '' }}
        />
      </Container>

      {renderFooter()}
    </Container>
  );
};
