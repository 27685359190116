import cls from 'classnames';

import { ClassName, TestId } from '../../types';
import { Container } from '../Container/Container';
import { Text, TextColor } from '../Text/Text';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert.svg';
import { ReactComponent as PositiveIcon } from '../../../assets/icons/check-fill.svg';
import { ReactComponent as TipIcon } from '../../../assets/icons/bulb.svg';
import { Children } from 'core';
import { createElement } from 'react';
import { MessageDescriptor, PrimitiveType } from 'react-intl';
import { isNil } from 'lodash-es';

type BannerVariant = 'alert' | 'warning' | 'positive' | 'tip';
type BannerWithMessage = BaseBannerProps & {
  message: MessageDescriptor;
  values?: Record<string, PrimitiveType | JSX.Element>;
  children?: never;
};
type BannerWithChildren = BaseBannerProps & Children & { message?: never; values?: never };

interface BaseBannerProps extends ClassName, TestId {
  variant: BannerVariant;
  showIcon?: boolean;
}

export type BannerProps = BaseBannerProps & (BannerWithMessage | BannerWithChildren);

const VARIANT_STYLES: Record<
  BannerVariant,
  {
    icon: string;
    container: string;
    text: TextColor;
  }
> = {
  alert: {
    icon: 'fill-alert',
    container: 'bg-alert-soft',
    text: 'alert',
  },
  warning: {
    icon: 'fill-attention',
    container: 'bg-attention-soft',
    text: 'attention',
  },
  positive: {
    icon: 'fill-positive',
    container: 'bg-positive-soft',
    text: 'positive',
  },
  tip: {
    icon: 'fill-accent ',
    container: 'bg-highlight-soft',
    text: 'highlight',
  },
};

export function Banner({
  variant = 'alert',
  children,
  message,
  values,
  showIcon = true,
  className,
  'data-testid': testId,
}: BannerProps) {
  const ICONS = {
    alert: AlertIcon,
    warning: WarningIcon,
    positive: PositiveIcon,
    tip: TipIcon,
  };

  return (
    <Container
      alignItems="center"
      data-testid={testId}
      className={cls('rounded-xl w-full p-4', VARIANT_STYLES[variant].container, className)}
    >
      {showIcon && (
        <div>
          {createElement(ICONS[variant], {
            className: cls('w-5 h-auto', VARIANT_STYLES[variant].icon),
          })}
        </div>
      )}
      <Container className="ml-3">
        {message?.id
          ? createElement(Text, {
              id: message.id,
              values: !isNil(values) ? values : undefined,
              color: VARIANT_STYLES[variant].text,
            })
          : children}
      </Container>
    </Container>
  );
}
