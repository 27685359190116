import cls from 'classnames';
import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { ReactComponent as Lightbulb } from '../../../assets/icons/bulb.svg';
import { ClassName } from 'core';

export function ETHFeeInfo({ className }: ClassName) {
  return (
    <Container
      fullWidth
      alignItems="center"
      justifyContent="center"
      className={cls('bg-accent-soft rounded-xl px-4 py-3', className)}
    >
      <Container>
        <Lightbulb className="w-[24px] h-auto fill-accent" />
      </Container>

      <Container>
        <Text
          color="highlight"
          id="ethFeeInfo.desc"
          className="inline ml-3"
          values={{
            prefix: (
              <Text weight="semibold" color="highlight" id="common.tip" className="mr-1 inline" />
            ),
          }}
        />
      </Container>
    </Container>
  );
}
