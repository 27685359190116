import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { LayoutSize } from '../layouts';

export function useLayoutSize() {
  const { pathname } = useLocation();

  const layoutSize = useMemo(() => {
    switch (pathname) {
      case '/staking':
      case '/staking/own':
      case '/tokens':
      case '/positions':
      case '/pools':
      case '/pools/promoted':
        return LayoutSize.Wide;
      default:
        return LayoutSize.Narrow;
    }
  }, [pathname]);

  return { layoutSize };
}
