import { Container, Text, FormatAmount, Button, Tooltip } from 'ui';

import {
  Asset,
  TxType,
  useExecuteDelegationRequestFeeQuery,
  useExecuteDelegationRequestMutation,
  useReservesForTx,
  useSafeTransaction,
} from 'core';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { StakingCardProps } from './StakingCard';
import { ParachainStakingDelegationRequest } from '@polkadot/types/lookup';
import { StakingCardStakeChangeChip } from './StakingCardStakeChangeChip';
import { u32 } from '@polkadot/types';
import { ReactComponent as CheckIcon } from 'icons/check.svg';

interface StakingCardScheduledChangeProps extends Pick<StakingCardProps, 'delegation'> {
  asset: Asset;
  request: ParachainStakingDelegationRequest;
  session: u32;
}

export function StakingCardScheduledChange({
  asset,
  delegation,
  request,
  session,
}: StakingCardScheduledChangeProps) {
  const collatorAddress = delegation.owner.toString();
  const stakedTxAsset = {
    ...asset,
    amount: fromBN(request.amount.toBn(), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS),
  };

  const {
    executeDelegationRequestMutation: {
      mutate: executeDelegationRequestMutation,
      isLoading: isExecuteDelegationRequestLoading,
    },
  } = useExecuteDelegationRequestMutation(collatorAddress);
  const { executeDelegationRequestFeeQuery } = useExecuteDelegationRequestFeeQuery(
    collatorAddress,
    stakedTxAsset,
    request,
  );

  const requestType = request?.action.isIncrease
    ? TxType.ConfirmStakeIncrease
    : TxType.ConfirmStakeDecrease;
  const { sources } = useReservesForTx(requestType, asset?.id);
  const { sources: activateLiqSources } = useReservesForTx(TxType.ActivateLP, asset?.id);

  const isStakeChangeReady = session && request?.whenExecutable.lte(session);
  const isStakeChangeActive = false;

  const confirmStakeChange = () => {
    if (!request || !asset) {
      return null;
    }

    executeDelegationRequestMutation({
      asset: {
        ...asset,
        amount: fromBN(request.amount.toBn(), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS),
      },
      request,
      sources,
      activateLiqSources,
    });
  };

  const { submitTxWithChecks } = useSafeTransaction(
    {
      spendingAssets: [stakedTxAsset],
      adaptiveFee: executeDelegationRequestFeeQuery.data,
      type: requestType,
    },
    confirmStakeChange,
  );

  return (
    !isStakeChangeActive &&
    request && (
      <Container fullWidth className="px-4 py-2">
        <Container
          fullWidth
          alignItems="center"
          justifyContent="space-between"
          className="bg-default rounded-lg"
        >
          <Container column>
            <Text color="secondary" type="body-s" id="stakingCard.changeRequest" uppercase />
            <Text
              id={`stakingCard.changeRequestValue.${request.action.isIncrease ? 'add' : 'remove'}`}
              values={{
                amount: (
                  <Text type="body-m">
                    <FormatAmount
                      value={fromBN(request.amount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
                      options={{ precision: 3, nonZeroPrecision: false, maxChars: 15 }}
                    />
                  </Text>
                ),
                symbol: <Text type="body-m">{asset?.symbol ?? ''}</Text>,
              }}
            />
          </Container>
          {isStakeChangeReady ? (
            <Button
              variant="primary"
              size="s"
              onClick={submitTxWithChecks}
              className="h-[28px] text-[12px] px-2"
              isDisabled={isExecuteDelegationRequestLoading}
              TrailIcon={CheckIcon}
              message={{ id: 'stakingCard.confirmChange.cta' }}
            />
          ) : (
            <Tooltip
              tooltipContent={
                <Container className="max-w-[200px] p-2">
                  <Text id="stakingCard.changeRequestInfo" />
                </Container>
              }
              className="flex items-center"
              inPortal
              leftOffset={false}
            >
              <StakingCardStakeChangeChip isReady={!!isStakeChangeReady} />
            </Tooltip>
          )}
        </Container>
      </Container>
    )
  );
}
