import cls from 'classnames';
import { ClassName, TestId, useAccounts } from 'core';
import { Container } from 'ui';

import { WalletHeader } from '../Header/Header';
import { twMerge } from 'tailwind-merge';

export interface WalletWrapperProps extends ClassName, TestId {
  children(onClose: () => void): React.ReactNode;
  onClose: () => void;
}

export function WalletWrapper({
  children,
  className,
  onClose,
  'data-testid': testId = 'walletWrapper',
}: WalletWrapperProps) {
  const { selectedAccount, selectedWallet } = useAccounts();

  const CONTAINER_CLASS = twMerge(
    cls('group transition-all duration-300 [&>*]:duration-300 pointer-events-auto', className),
  );

  const CONTENT_CLASS = cls(
    'transition-all w-full relative z-20 overflow-hidden',
    'opacity-100 visible',
  );

  return (
    <Container
      justifyContent="center"
      fullWidth
      className={cls('transition-all duration-300 pointer-events-none h-full')}
    >
      <Container
        fullWidth
        alignItems="center"
        column
        className={CONTAINER_CLASS}
        data-testid={testId}
      >
        <WalletHeader
          onClose={onClose}
          selectedWallet={selectedWallet}
          selectedAccount={selectedAccount}
          data-testid={`${testId}-header`}
        />
        <Container column className={CONTENT_CLASS} data-testid={`${testId}-content`}>
          <>{children(onClose)}</>
        </Container>
      </Container>
    </Container>
  );
}
