import {
  BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
  BN_ZERO,
  fromBN,
  MangataGenericEvent,
  toBN,
} from 'gasp-sdk';
import {
  ExtrinsicError,
  TransactionErrorEvent,
  TransactionStore,
  ExtrinsicTx,
  TxAsset,
  TxType,
} from '../../../../transaction';
import {
  ActivateLiquidityReserveSource,
  QueryOptional,
  ReserveSourceType,
  StakeReserveSource,
} from '../../../../../services';
import { ApiPromise } from '@polkadot/api';
import { ParachainStakingDelegationRequest } from '@polkadot/types/lookup';
import { ReserveSourceForTx } from '../../../../balance';
import { SubmittableExtrinsic } from '@polkadot/api/types';
import { createLiqActivationTxsForStaking } from '../../../../pool/activateLiquidity/services/ActivateLiquidityMutationService';
import { Config } from 'wagmi';
import { AssetType } from '../../../../token';
import { QueryFunctionContext } from '@tanstack/react-query';

type ExecuteDelegationRequestFeeParams = Readonly<
  [
    queryKey: string,
    delegatorAddress: QueryOptional<string>,
    collatorAddresss: QueryOptional<string>,
    stakedAsset: QueryOptional<TxAsset>,
    request: ParachainStakingDelegationRequest,
  ]
>;

const getError = (txEvents: MangataGenericEvent[] | null | undefined) => {
  const failedEvent = txEvents?.find((txEvent) => txEvent.error);

  switch (true) {
    case !!failedEvent:
      return {
        name: failedEvent?.error?.name || ExtrinsicError.Unknown,
        msg: failedEvent?.error?.documentation.join(' '),
      };
  }

  return { name: ExtrinsicError.Unknown };
};

const createExecuteDelegationRequestTx = (
  api: ApiPromise,
  delegatorAddress: string,
  collatorAddress: string,
  sources: ReserveSourceForTx<StakeReserveSource>,
  activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  asset: TxAsset,
  request: ParachainStakingDelegationRequest,
) => {
  const sufficientAmountSingleSource = sources.available.find(([, amount]) => {
    return toBN(asset.amount).sub(amount).lte(BN_ZERO);
  });

  const balanceSource = (() => {
    if (request.action.isDecrease) return null;
    if (sufficientAmountSingleSource) {
      return sufficientAmountSingleSource[0];
    }
    if (activateLiqSources.hasAvailable && asset.type === AssetType.LP) {
      return ReserveSourceType.ActivatedUnstakedReserves;
    }

    return ReserveSourceType.AvailableBalance;
  })();

  const txs: SubmittableExtrinsic<'promise'>[] = [];

  if (activateLiqSources.hasAvailable && asset.type === AssetType.LP) {
    txs.push(...createLiqActivationTxsForStaking(api, activateLiqSources, asset));
  }

  txs.push(
    api.tx.parachainStaking.executeDelegationRequest(
      delegatorAddress,
      collatorAddress,
      balanceSource,
    ),
  );

  return txs;
};

export const executeDelegationRequest =
  (
    api: ApiPromise | null,
    delegatorAddress: string | undefined,
    collatorAddress: QueryOptional<string>,
    config: QueryOptional<Config>,
    transactionStore: TransactionStore,
  ) =>
  async ({
    asset,
    request,
    sources,
    activateLiqSources,
  }: {
    asset: TxAsset;
    request: ParachainStakingDelegationRequest;
    sources: ReserveSourceForTx<StakeReserveSource>;
    activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>;
  }) => {
    if (!api || !config || !collatorAddress || !delegatorAddress) {
      return null;
    }

    const txs = createExecuteDelegationRequestTx(
      api,
      delegatorAddress,
      collatorAddress,
      sources,
      activateLiqSources,
      asset,
      request,
    );

    const extrinsic = txs.length === 1 ? txs[0] : api.tx.utility.batchAll(txs);

    if (!extrinsic) {
      return null;
    }

    const tx = new ExtrinsicTx(api, transactionStore, config, delegatorAddress)
      .create(request.action.isIncrease ? TxType.ConfirmStakeIncrease : TxType.ConfirmStakeDecrease)
      .setMetadata({ tokens: [asset], amountPrefix: request.action.isIncrease ? '+' : '-' })
      .setTx(extrinsic)
      .build();

    const res = await tx.send();

    if (res?.some((event) => event.method === TransactionErrorEvent.ExtrinsicFailed)) {
      tx.doneWithError(getError(res));
      return false;
    }
  };

export const getExecuteDelegationRequestFee =
  (
    api: ApiPromise | null,
    stakingSources: ReserveSourceForTx<StakeReserveSource>,
    activateLiqSources: ReserveSourceForTx<ActivateLiquidityReserveSource>,
  ) =>
  async ({
    queryKey: [, delegatorAddress, collatorAddress, asset, request],
  }: QueryFunctionContext<ExecuteDelegationRequestFeeParams>) => {
    if (
      !api ||
      !stakingSources ||
      !activateLiqSources ||
      !delegatorAddress ||
      !collatorAddress ||
      !request ||
      !asset
    ) {
      return null;
    }

    const txs = createExecuteDelegationRequestTx(
      api,
      delegatorAddress,
      collatorAddress,
      stakingSources,
      activateLiqSources,
      asset,
      request,
    );

    if (!txs) {
      return null;
    }

    if (txs.length === 1) {
      const feeInfo = await txs[0].paymentInfo(delegatorAddress);

      return fromBN(feeInfo.partialFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
    }

    const txFees = await Promise.all(txs.map((tx) => tx.paymentInfo(delegatorAddress)));
    const batchingFee = await api.tx.utility.batchAll([]).paymentInfo(delegatorAddress);

    const totalFee = txFees
      .reduce((acc, curr) => acc.add(curr.partialFee), BN_ZERO)
      .add(batchingFee.partialFee);

    return fromBN(totalFee, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  };
