import { useMutation } from '@tanstack/react-query';
import { useTransactionStore } from '../../../transaction';
import { submitAddLiqudity } from '../services/AddLiquidityMutationService';
import { QueryOptional, useApi } from '../../../../services';
import { PoolWithRatio } from '../../Pool';
import { useConfig } from 'wagmi';

interface UseAddLiquidityMutationParams {
  pools: QueryOptional<PoolWithRatio[]>;
}

export const useAddLiqudityMutation = ({ pools }: UseAddLiquidityMutationParams) => {
  const transactionStore = useTransactionStore();
  const api = useApi();
  const config = useConfig();

  const poolIds = pools?.map((pool) => pool.id);
  const addLiquidityMutation = useMutation(
    submitAddLiqudity(api, transactionStore, poolIds, config),
  );

  return { addLiquidityMutation };
};
