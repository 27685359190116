import { Modal } from 'ui';
import { AddStakeWidget, RemoveStakeWidget, useStakingUIStore } from 'modules';
import { fromBN, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS } from 'gasp-sdk';
import { useAssets, useDelegators } from 'core';
import cls from 'classnames';

export function StakeChangeModal() {
  const {
    isAddModalOpen,
    isRemoveModalOpen,
    collatorAddress,
    setAddModalOpen,
    setRemoveModalOpen,
  } = useStakingUIStore();
  const {
    delegatorStateQuery: { data: delegator },
  } = useDelegators();
  const { assetsMap } = useAssets();

  const delegation = delegator?.delegations.find((d) => d.owner.toString() === collatorAddress);
  const delegationAsset =
    (delegation && assetsMap.get(delegation.liquidityToken.toString())) || null;
  const delegationAmount = delegation
    ? fromBN(delegation.amount.toBn(), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)
    : null;
  const isLoaded = !!delegation && !!collatorAddress;

  return (
    <>
      <Modal
        isCloseIconVisible={false}
        isClosableOnOverlayClick={false}
        isOpen={isLoaded && isAddModalOpen}
        onClose={() => setAddModalOpen(false)}
        className={cls('w-[480px] overflow-hidden py-0 px-2')}
        data-testid="add-stake-modal"
      >
        {isLoaded && (
          <AddStakeWidget
            collatorAddress={collatorAddress}
            currentStakeAmount={delegationAmount}
            stakedAsset={delegationAsset}
            onClose={() => setAddModalOpen(false)}
          />
        )}
      </Modal>
      <Modal
        isCloseIconVisible={false}
        isClosableOnOverlayClick={false}
        isOpen={isLoaded && isRemoveModalOpen}
        onClose={() => setRemoveModalOpen(false)}
        className={cls('w-[480px] overflow-hidden py-0 px-2')}
        data-testid="remove-stake-modal"
      >
        {isLoaded && (
          <RemoveStakeWidget
            collatorAddress={collatorAddress}
            currentStakeAmount={delegationAmount}
            stakedAsset={delegationAsset}
            onClose={() => setAddModalOpen(false)}
          />
        )}
      </Modal>
    </>
  );
}
