import cls from 'classnames';
import { useRef, useState } from 'react';

import { ClassName, TestId } from '../../types';
import { Container } from '../Container/Container';
import { Text, TextElementType } from '../Text/Text';
import { ReactComponent as SecurityIcon } from '../../../assets/icons/alert.svg';
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../assets/icons/chevron-up.svg';
import { IconButton } from '../IconButton/IconButton';

type AlertMessageVariant = 'warning';

export interface AlertMessageProps extends ClassName, TestId {
  variant?: AlertMessageVariant;
  titleId: string;
  showIcon?: boolean;
  descriptionId?: string;
}

const VARIANT_STYLES = {
  warning: {
    container: 'bg-soft px-5 py-3',
    text: 'text-neutral',
    Icon: <SecurityIcon className="w-[19px] h-[100%] fill-alert mr-3" />,
  },
};

export function AlertMessage({
  variant = 'warning',
  titleId,
  showIcon = true,
  className,
  'data-testid': testId = 'alertMessage',
  descriptionId,
}: AlertMessageProps) {
  const [isExpanded, setExpanded] = useState(false);
  const textRef = useRef<TextElementType>(null);

  const textHeight = textRef.current?.offsetHeight || 0;

  const handleToggleExpand = () => setExpanded(!isExpanded);

  const CONTAINER_STYLES = cls(
    'rounded p-3 min-w-[400px] ease-in-out',
    'transition-all duration-300 overflow-hidden cursor-pointer',
    isExpanded ? 'is-expanded' : 'is-collapsed',
    VARIANT_STYLES[variant].container,
    className,
  );

  return (
    <Container
      style={{ height: isExpanded ? `${textHeight + 52}px` : '52px' }}
      column
      data-testid={testId}
      className={CONTAINER_STYLES}
      onClick={handleToggleExpand}
    >
      <Container alignItems="center" justifyContent="space-between" className="w-full">
        <Container>
          <Container>{showIcon && VARIANT_STYLES[variant].Icon}</Container>
          <Text
            type="title-3"
            data-testid={`${testId}-title`}
            className={VARIANT_STYLES[variant].text}
            id={titleId}
          />
        </Container>
        <IconButton
          Icon={isExpanded ? ChevronUp : ChevronDown}
          data-testid={`${testId}-open`}
          size="s"
        />
      </Container>
      <Text
        ref={textRef}
        className={cls(
          'transition-opacity duration-100 ml-[31px]',
          isExpanded ? 'opacity-100' : 'opacity-0',
        )}
        id={descriptionId}
        data-testid={`${testId}-desc`}
      />
    </Container>
  );
}
