import { FormattedMessage } from 'react-intl';
import { AdaptiveTxFee, Asset, TestId, isAmountGtZero } from 'core';
import { AddStakeUIStoreFormError, useAddStakeStore } from '../store/useAddStakeStore';
import {
  Text,
  Tooltip,
  FormatAmount,
  Skeleton,
  WidgetDetailRow,
  Container,
  formatAmount,
} from 'ui';

import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { animated, useTransition } from '@react-spring/web';
import Decimal from 'decimal.js';

interface AddStakeWidgetDetailsProps extends TestId {
  feeData: AdaptiveTxFee['current'] | null | undefined;
  isFeeLoading: boolean;
  stakedAsset: Asset | null;
  currentStakeAmount: string | null;
}

export const AddStakeWidgetDetails = ({
  feeData,
  isFeeLoading,
  currentStakeAmount,
  stakedAsset,
  'data-testid': testId = 'stake-details',
}: AddStakeWidgetDetailsProps) => {
  const { formError, amount } = useAddStakeStore();

  const isFeeInsufficient = !!formError[AddStakeUIStoreFormError.InsufficientFee];
  const newStakeAmount = isAmountGtZero(amount)
    ? new Decimal(amount).add(currentStakeAmount ?? '0').toFixed()
    : null;

  const Fee = feeData && (
    <Text type="label" color={isFeeInsufficient ? 'alert' : 'neutral'}>
      ≈
      <FormatAmount
        value={feeData?.amount ?? ''}
        options={{ precision: 3, maxChars: 15 }}
        className="mx-1"
      />
      {feeData?.symbol || ''}
    </Text>
  );

  const detailsTransition = useTransition(isAmountGtZero(amount), {
    from: { opacity: 0, height: 0 },
    enter: { opacity: 1, height: 80 },
    leave: { opacity: 0, height: 0 },
  });

  const renderFee = () => {
    switch (true) {
      case feeData && isFeeInsufficient:
        return (
          <Tooltip
            tooltipContent={<FormattedMessage id="pool.provide.fee.insufficient" />}
            className="flex items-center"
            inPortal
            leftOffset={false}
          >
            {Fee}
            <InfoIcon className={'w-[24px] h-[24px] ml-1 fill-alert'} />
          </Tooltip>
        );
      case !feeData && isFeeLoading:
        return (
          <Skeleton height="14px" width="70px" className={isFeeInsufficient ? 'my-[5px]' : ''} />
        );

      case !!feeData:
        return Fee;

      default:
        return (
          <Text type="label" color="secondary">
            -
          </Text>
        );
    }
  };

  return (
    <Container column fullWidth data-testid={testId} className="mb-2 px-2">
      <WidgetDetailRow
        className="mt-6"
        label={
          <Text
            id="staking.add.currentAmount.info"
            type="title-4"
            color={'secondary'}
            className="mr-2"
          />
        }
        value={`
                  ${formatAmount(currentStakeAmount, {
                    precision: 3,
                    maxChars: 15,
                    nonZeroPrecision: true,
                  })} ${stakedAsset?.symbol ?? ''}
                `}
        data-testid={`${testId}-fee`}
      />
      {detailsTransition((style, visible) =>
        visible ? (
          <animated.div style={style} className="w-full overflow-hidden">
            <>
              <WidgetDetailRow
                label={
                  <Text
                    id="staking.add.newAmount.info"
                    type="title-4"
                    color={'secondary'}
                    className="mr-2"
                  />
                }
                value={`
                  ${formatAmount(newStakeAmount ?? '0', {
                    precision: 3,
                    maxChars: 15,
                    nonZeroPrecision: true,
                  })} ${stakedAsset?.symbol ?? ''}
                `}
                data-testid={`${testId}-fee`}
              />
              <WidgetDetailRow
                label={
                  <Text id="staking.add.fee" type="title-4" color={'secondary'} className="mr-2" />
                }
                value={renderFee()}
                data-testid={`${testId}-fee`}
              />
            </>
          </animated.div>
        ) : null,
      )}
    </Container>
  );
};
