import { Button, Container, ShortAddress, Spinner, Text, TokenIcon } from 'ui';
import { Transaction, TxStatus, useTransactionStore } from 'core';
import { WithdrawalUIState, useWithdrawalStore } from '../../store/useWithdrawalStore';

interface WithdrawalTxInfoProps {
  tx: Transaction;
  to: string;
}

export const WithdrawalTxInfo = ({ tx, to }: WithdrawalTxInfoProps) => {
  const { setUIState } = useWithdrawalStore();
  const { remove } = useTransactionStore();

  const token = tx?.metadata.tokens?.[0];
  const amount = tx?.metadata.tokens?.[0].amount;

  const titleId = (() => {
    if (tx) {
      switch (tx.status) {
        case TxStatus.Pending:
          return 'bridge.withdrawal.pending.title';
        case TxStatus.Confirming:
          return 'bridge.withdrawal.confirm.title';
        case TxStatus.Error:
          return 'bridge.withdrawal.error.title';
      }
    }

    return '';
  })();

  const descId = (() => {
    if (tx) {
      switch (tx.status) {
        case TxStatus.Pending:
          return 'bridge.withdrawal.pending.desc';
        case TxStatus.Confirming:
          return 'bridge.withdrawal.confirm.desc';
        case TxStatus.Error:
          return 'bridge.withdrawal.error.desc';
      }
    }

    return '';
  })();

  const handleClose = () => {
    setUIState(WithdrawalUIState.Default);
    remove(tx?.id);
  };

  const renderFooter = () => {
    if (tx?.status === TxStatus.Error) {
      return (
        <Button
          data-testid="withdraw-status-back"
          onClick={handleClose}
          message={{ id: 'bridge.button.back' }}
        />
      );
    }

    return token && amount ? (
      <Container
        fullWidth
        justifyContent="center"
        alignItems="center"
        className="py-4 px-2 bg-soft rounded-full ml-auto"
      >
        <Text id="bridge.withdrawal.confirm.info" />
        <TokenIcon token={token} size="s" className="mr-1 ml-2" />
        <Text data-testid="withdraw-status-amount">
          {amount} {token.symbol}
        </Text>
        <Text id="common.to" className="mx-1" />
        <Text data-testid="withdraw-status-recipient">
          <ShortAddress withTooltip={false} address={to} />
        </Text>
      </Container>
    ) : null;
  };

  return (
    <Container
      fullWidth
      alignItems="center"
      column
      justifyContent="end"
      className="h-full min-h-[400px]"
    >
      <Container column alignItems="center" grow justifyContent="center">
        <Spinner
          data-testid="withdraw-status"
          state={tx?.status === TxStatus.Error ? 'error' : 'loading'}
          className="!w-[60px]"
        />

        <Text type="title-1" id={titleId} className="mt-6 mb-2" />
        <Text
          color="secondary"
          id={descId}
          values={{ error: tx?.error?.name ?? tx?.error?.message ?? '' }}
        />
      </Container>

      {renderFooter()}
    </Container>
  );
};
