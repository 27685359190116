import { Asset, InternalBalance, TestId, useAssets, useInternalBalance, useModalStore } from 'core';
import { Button, Container, Skeleton, Text } from 'ui';
import { ReactComponent as WalletIcon } from 'icons/wallet.svg';
import { useMemo } from 'react';
import { BN } from '@polkadot/util';
import { fromBN } from '@mangata-finance/sdk';
import { Decimal } from 'decimal.js';
import { TokenRow } from '../../components/TokenRow/TokenRow';

export function MyTokens({ 'data-testid': testId = 'my-tokens' }: TestId) {
  const { assetsMap } = useAssets();
  const { balances, isLoading } = useInternalBalance();

  const sortedBalances = useMemo(
    () =>
      Array.from(balances || []).sort(([idA, balanceA], [idB, balanceB]) =>
        sortTokensByBalance(assetsMap.get(idA), assetsMap.get(idB), balanceA.free, balanceB.free),
      ),
    [balances, assetsMap],
  );

  const { openDeposit } = useModalStore();

  const renderEmptyTokens = () => (
    <Container
      fullWidth
      column
      justifyContent="center"
      alignItems="center"
      className="h-[480px] text-center px-4"
      data-testid={`${testId}-empty`}
    >
      <WalletIcon className="fill-secondary w-[40px] h-[40px] mb-2" />
      <Text type="body-l" color="secondary" id="wallet.tokens.empty.title" className="mb-2" />
      <Text color="secondary" id="wallet.tokens.empty.desc" />
      <Button
        onClick={openDeposit}
        variant="primary"
        className="mt-8"
        message={{ id: 'wallet.tokens.empty.cta' }}
      />
    </Container>
  );

  const renderLoading = () => (
    <Container column fullWidth justifyContent="space-between" className="px-2">
      {new Array(8).fill(true).map((_, i) => (
        <Container
          key={i}
          fullWidth
          justifyContent="space-between"
          alignItems="center"
          className="mb-4"
        >
          <Container alignItems="center">
            <Skeleton width="28px" height="28px" rounded />
            <Container column className="ml-3">
              <Skeleton width="80px" height="8px" />
              <Skeleton width="50px" height="8px" className="mt-2" />
            </Container>
          </Container>
          <Skeleton width="60px" height="10px" />
        </Container>
      ))}
    </Container>
  );

  const renderToken = ([id, balance]: [string, InternalBalance]) => {
    const asset = assetsMap.get(id);

    if (!asset || balance.free.eqn(0)) {
      return null;
    }

    return (
      <TokenRow
        showDescription={false}
        key={id}
        token={asset}
        iconType="diagonal"
        freeBalance={fromBN(balance.free, asset.decimals)}
        className="cursor-default"
        data-testid={`${asset.symbol}-token-row`}
        isCompact
      />
    );
  };

  return (
    <Container fullWidth column data-testid={testId} className="overflow-y-auto no-scrollbar mt-6">
      {isLoading && renderLoading()}
      {!isLoading && (
        <>
          {!sortedBalances?.length && !isLoading && renderEmptyTokens()}
          {sortedBalances?.map(renderToken)}
        </>
      )}
    </Container>
  );
}

const sortTokensByBalance = (
  assetA: Asset | undefined,
  assetB: Asset | undefined,
  balanceA: BN | null,
  balanceB: BN | null,
) => {
  const freeBalanceA = balanceA && assetA ? fromBN(balanceA, assetA.decimals) : null;
  const freeBalanceB = balanceB && assetB ? fromBN(balanceB, assetB.decimals) : null;

  if (freeBalanceA === null && freeBalanceB === null) {
    return 0;
  }

  if (freeBalanceA === null) {
    return 1;
  }

  if (freeBalanceB === null) {
    return -1;
  }

  const aBalance = new Decimal(freeBalanceA);
  const bBalance = new Decimal(freeBalanceB);

  return bBalance.cmp(aBalance);
};
