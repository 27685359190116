import { Container, Skeleton } from '../../../';

export const TokenRowSkeleton = () => (
  <Container className="w-full mb-2 px-2 justify-between items-center py-2">
    <Container alignItems="center">
      <Skeleton height="30px" width="30px" className="mr-3" />
      <Container column>
        <Skeleton height="14px" width="80px" className="" />
        <Skeleton height="10px" width="60px" className="mt-1" />
      </Container>
    </Container>
    <Container column alignItems="end">
      <Skeleton height="14px" width="100px" className="" />
      <Skeleton height="10px" width="50px" className="mt-1" />
    </Container>
  </Container>
);
