import cls from 'classnames';
import { Asset, AssetOrigin, AssetType } from 'core';
import { ReactNode, useMemo } from 'react';

import { ClassName, TestId } from '../../../types';
import { Container } from '../../Container/Container';
import { Text } from '../../Text/Text';
import { ItemRow } from '../../ItemRow/ItemRow';
import { TokenIcon, TokenIconSize, TokenIconType } from '../../TokenIcon/TokenIcon';
import { AmountTooltip } from '../../AmountTooltip/AmountTooltip';
import { useIntl } from 'react-intl';

export const getTokenI18nDesc = (type: AssetType, origin?: AssetOrigin) =>
  type === AssetType.LP ? 'common.lp.desc' : `common.assetOrigin.${origin}`;

export interface TokenRowProps
  extends ClassName,
    TestId,
    Pick<React.DetailsHTMLAttributes<HTMLDivElement>, 'onClick'> {
  token: Asset;
  isCompact?: boolean;
  rightElement?: ReactNode;
  showDescription?: boolean;
  iconSize?: TokenIconSize;
  iconType?: TokenIconType;
  freeBalance?: string;
}

export function TokenRow({
  className,
  'data-testid': testId,
  token,
  showDescription = true,
  rightElement,
  onClick,
  isCompact = true,
  iconSize = 's',
  iconType = 'row',
  freeBalance,
}: TokenRowProps) {
  const intl = useIntl();

  const renderRightElement = () => {
    if (rightElement) {
      return rightElement;
    }

    return (
      <Container row justifyContent="center">
        <Text type="label">
          <AmountTooltip
            id={token.id}
            value={freeBalance ?? null}
            token={token}
            options={{ maxChars: 23, precision: 3 }}
          />
        </Text>
      </Container>
    );
  };

  const rowDescription = useMemo(() => {
    if (!showDescription) return undefined;
    if (token.origin) {
      return intl.formatMessage({ id: getTokenI18nDesc(token.type, token.origin) });
    }
    return token.name;
  }, [showDescription, token.origin, token.name, token.type, intl]);

  return (
    <Container
      fullWidth
      alignItems="center"
      justifyContent="center"
      row
      className={cls(
        'rounded-lg px-2 py-2 last-of-type:mb-0 transition-all duration-100',
        'hover:bg-hover cursor-pointer mb-1',
        className,
      )}
      data-testid={testId}
      onClick={onClick}
    >
      <TokenIcon
        type={iconType}
        token={token}
        size={iconSize}
        isCompact={isCompact}
        className="mr-3"
      />
      <ItemRow
        title={token.symbol}
        className="w-full"
        description={rowDescription}
        rightElement={renderRightElement()}
      />
    </Container>
  );
}
