import { useLocation } from 'react-router';
import { Container, LinkButton, NavItem, ThemeSwitch } from 'ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDelegators, useSession, useUserStore } from 'core';
import { BN_ZERO } from 'gasp-sdk';

import { ReactComponent as ExchangeIcon } from 'icons/exchange.svg';
import { ReactComponent as DropletIcon } from 'icons/droplet.svg';
import { ReactComponent as VolumesIcon } from 'icons/volumes.svg';
import { ReactComponent as GaspLogo } from 'icons/gasp-logo.svg';
import { ReactComponent as StakingIcon } from 'icons/staking.svg';
// import { ReactComponent as FileIcon } from 'icons/file.svg';

export const Navbar = () => {
  const { pathname } = useLocation();
  const intl = useIntl();
  const { delegatorStateRequests: requests } = useDelegators();
  const { theme, setTheme } = useUserStore();

  const {
    currentSession: { data: session },
  } = useSession();

  const hasReadyStakeChange = Array.from(requests ?? []).some(([, r]) =>
    r?.whenExecutable.lte(session ?? BN_ZERO),
  );

  const firstRoute = pathname.split('/')[1];

  return (
    <Container
      column
      justifyContent="space-between"
      alignItems="center"
      className="h-full w-[264px] min-w-[264px] z-widget"
    >
      <Container column alignItems="start" fullWidth className="px-6 h-full">
        <Container alignItems="center" className="mt-6">
          <GaspLogo className="h-auto w-[70px]" />
        </Container>
        <Container className="h-full" alignItems="center">
          <Container column fullWidth>
            <NavItem
              isActive={pathname === '/'}
              path="/"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.swap' })}
              Icon={ExchangeIcon}
              data-testid="nav-swap"
            />
            <NavItem
              isActive={firstRoute === 'pools'}
              path="/pools/promoted"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.pools' })}
              Icon={DropletIcon}
              data-testid="nav-pools"
            />
            {/*<NavItem
              isActive={firstRoute === 'tokens'}
              path="/tokens"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.tokens' })}
              Icon={tokensIcon}
              data-testid="nav-tokens"
            />*/}
            <NavItem
              isActive={firstRoute === 'staking'}
              path="/staking"
              className="my-2"
              hasNotification={hasReadyStakeChange}
              title={intl.formatMessage({ id: 'navigation.staking' })}
              Icon={StakingIcon}
              data-testid="nav-staking"
            />
            <NavItem
              isActive={firstRoute === 'positions'}
              path="/positions"
              className="my-2"
              title={intl.formatMessage({ id: 'navigation.positions' })}
              Icon={VolumesIcon}
              data-testid="nav-positions"
            />
          </Container>
        </Container>
        <Container>
          <LinkButton
            href="https://docs.gasp.xyz"
            target="_blank"
            className="mt-3 ml-4"
            variant="secondary"
          >
            <FormattedMessage id="navigation.docs" />
          </LinkButton>
        </Container>
        <ThemeSwitch onChange={setTheme} theme={theme} className="ml-2" />
      </Container>
    </Container>
  );
};
