import cls from 'classnames';
import { Container, Text, WalletLogo } from 'ui';
import { TestId } from 'core';

interface WalletItemProps extends TestId {
  id: string;
  url: string;
  isMobile?: boolean;
}

export const WalletItem = ({ url, id, isMobile, 'data-testid': testId }: WalletItemProps) => {
  return (
    <a href={url} target="_blank" rel="noopener noreferrer" className="w-full">
      <Container
        justifyContent="space-between"
        alignItems="center"
        data-testid={testId}
        className="p-3 w-full hover:bg-hover rounded cursor-pointer transition-all"
      >
        <Container alignItems="center" className="h-[32px]">
          <WalletLogo className={cls('w-7', 'transition-all duration-400')} wallet={id} />
          <Text type="title-3" className="ml-3" id={`wallet.extension.${id}`} />
          {isMobile && (
            <Text
              id="wallet.isMobile"
              color="secondary"
              uppercase
              className="ml-1.5 px-1 rounded bg-default"
            />
          )}
        </Container>
      </Container>
    </a>
  );
};
