import cls from 'classnames';

import { CountBadge } from '../CountBadge/CountBadge';
import { ClassName, TestId } from '../../types';
import { Text } from '../Text/Text';
import { Tab } from './tab/Tab';

type TabSize = 'l' | 's';

interface TabGroupItem {
  label: string;
  count?: number;
}

export interface TabGroupProps extends ClassName, TestId {
  size?: TabSize;
  data: TabGroupItem[];
  activeIndex?: number;
  onSelect(i: number): void;
}

export function TabGroup({
  onSelect,
  className,
  'data-testid': testId,
  activeIndex,
  size = 'l',
  data,
}: TabGroupProps) {
  const handleSelect = (index: number) => () => {
    onSelect(index);
  };

  return (
    <div data-testid={testId} className={cls('flex border-b-1 border-default w-full', className)}>
      {data.map(({ label, count }, i) => {
        const isActive = activeIndex === i;
        const dataTestId: string = testId ?? label.replace(/\s+/g, '-') ?? 'defaultTestId';
        return (
          <Tab
            key={i}
            size={size}
            isActive={isActive}
            data-testid={`${dataTestId}-item`}
            className={cls('mr-6 relative top-[1px] group/tab', isActive && 'active')}
            onClick={handleSelect(i)}
          >
            <Text
              color={isActive ? 'accent' : 'secondary'}
              type={size === 'l' ? 'body-l' : 'body-m'}
            >
              {label}
            </Text>
            {count !== undefined && count > 0 && !isActive && (
              <CountBadge
                variant={isActive ? 'accent' : 'secondary'}
                count={count}
                className="ml-2 group-hover/tab:text-neutral group-hover/tab:border-default"
              />
            )}
          </Tab>
        );
      })}
    </div>
  );
}
