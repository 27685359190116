import { Container, IconButton, Text } from 'ui';
import { TestId, useModalStore, useWallet } from 'core';

import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as BackIcon } from 'icons/arrow-left.svg';
import { InstalledWallets } from './components/InstalledWallets/InstalledWallets';
import { PopularWallets } from './components/PopularWallets/PopularWallets';

interface ConnectWalletLayoutProps extends TestId {
  onClose(): void;
  onBack(): void;
}

export const SelectWalletLayout = ({
  onClose,
  onBack,
  'data-testid': testId,
}: ConnectWalletLayoutProps) => {
  const { setSelectedWallet } = useWallet();
  const { openAccountSettings } = useModalStore();

  const handleWalletSelect = (wallet: string) => {
    setSelectedWallet(wallet);
    openAccountSettings();
  };

  const renderContent = () => {
    return (
      <Container column className="w-full">
        <InstalledWallets onSelect={handleWalletSelect} />
        <PopularWallets />
      </Container>
    );
  };

  return (
    <Container column className="w-full h-full" alignItems="stretch" data-testid={testId}>
      <Container className="mb-4" justifyContent="space-between" alignItems="center">
        <Container alignItems="center">
          <IconButton Icon={BackIcon} onClick={onBack} size="s" data-testid={`${testId}-back`} />
          <Text
            type="title-4"
            color="secondary"
            id="wallet.connectWallet.header"
            className="ml-2"
          />
        </Container>
        <IconButton Icon={CloseIcon} onClick={onClose} size="s" data-testid={`${testId}-close`} />
      </Container>
      <Container column justifyContent="space-between" className="h-full">
        {renderContent()}
      </Container>
    </Container>
  );
};
