import { Button, Container, Text } from 'ui';
import { ReactComponent as DisconnectedWalletIcon } from 'icons/empty-wallet.svg';
import { TestId } from 'core';

interface DisconnectedPositionsProps extends TestId {
  onCtaClick: () => void;
}

export function DisconnectedPositions({
  onCtaClick,
  'data-testid': testId,
}: DisconnectedPositionsProps) {
  return (
    <Container
      data-testid={testId}
      column
      fullWidth
      alignItems="center"
      justifyContent="center"
      className="p-14 text-center"
    >
      <DisconnectedWalletIcon className="w-[64px] h-auto stroke-neutral" />
      <Text type="title-2" id="position.disconnected.title" className="mt-8" />
      <Text color="secondary" id="position.disconnected.desc" className="mt-2" />
      <Button
        variant="primary"
        size="m"
        onClick={onCtaClick}
        className="mt-12"
        message={{ id: 'position.disconnected.cta' }}
      />
    </Container>
  );
}
