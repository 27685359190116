import { Button, Container } from 'ui';
import { SwapWidget } from './widget/SwapWidget';
import { useSwapStore } from './store/useSwapStore';
import { animated, useTransition } from '@react-spring/web';
import { ReactComponent as ChartIcon } from 'icons/analytics.svg';
import { ReactComponent as EyeIcon } from 'icons/eye.svg';
import { SwapChart } from './chart/SwapChart';
import cls from 'classnames';
import { createRef, useEffect } from 'react';
import { TokenLaunchBanner } from './components/TokenLaunchBanner/TokenLaunchBanner';

export enum SwapInputType {
  FirstToken = 'firstToken',
  SecondToken = 'secondToken',
}

interface SwapPageProps {
  onWalletConnect?: () => void;
}

export function SwapPage({ onWalletConnect }: SwapPageProps) {
  const { firstToken, secondToken, isChartVisible, setChartVisible, areSettingsOpen } =
    useSwapStore();
  const chartRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (areSettingsOpen && isChartVisible) {
      setChartVisible(false);
    }
  }, [areSettingsOpen, isChartVisible, setChartVisible]);

  const onChartTransitionEnd = () => {
    if (!isChartVisible) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const onChartTransitionStart = () => {
    if (isChartVisible) {
      chartRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const chartTransition = useTransition(isChartVisible, {
    from: { maxHeight: 0, opacity: 0, filter: 'blur(20px)' },
    enter: { maxHeight: 500, opacity: 1, filter: 'blur(00px)' },
    leave: { maxHeight: 0, opacity: 0, filter: 'blur(20px)' },
    onRest: onChartTransitionEnd,
    onChange: onChartTransitionStart,
    onStart: onChartTransitionStart,
  });

  const toggleContent = () => {
    setChartVisible(!isChartVisible);
  };

  const ToggleChartIcon = isChartVisible ? EyeIcon : ChartIcon;

  return (
    <>
      <Container alignItems="center" className="h-full">
        <Container
          alignItems="center"
          justifyContent="center"
          className="pt-[80px] my-auto w-full "
          column
        >
          <SwapWidget onWalletConnect={onWalletConnect} />
          <Button
            variant="secondary"
            size="s"
            className={cls(
              'mt-10 text-secondary normal-case h-10',
              (!(firstToken && secondToken) || areSettingsOpen) && 'invisible',
            )}
            LeadIcon={ToggleChartIcon}
            iconClassName="stroke-secondary"
            isDisabled={!firstToken || !secondToken}
            onClick={toggleContent}
            message={{ id: isChartVisible ? 'swap.hide.chart.cta' : 'swap.show.chart.cta' }}
          />

          {chartTransition((style, item) =>
            item ? (
              <animated.div style={style} className="w-full overflow-hidden">
                <SwapChart className="pb-[130px] h-[530px]" ref={chartRef} />
              </animated.div>
            ) : null,
          )}
        </Container>
      </Container>
      <TokenLaunchBanner />
    </>
  );
}
