import cls from 'classnames';
import { ClassName, TestId } from 'core';
import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { useIntl } from 'react-intl';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import { useRef, useState } from 'react';
import { useClickOutside } from '../../hooks';

interface InputProps
  extends Pick<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'placeholder'>,
    ClassName,
    TestId {
  options?: Array<{ key: string; value: string | React.ReactNode }>;
  onChange?(value: string): void;
  value: string;
  renderValue?: (key: string) => React.ReactNode;
  label?: string;
  isValid?: boolean;
  suffix?: React.ReactNode;
}

export const Input = ({
  'data-testid': testId,
  className,
  type = 'text',
  onChange,
  value,
  placeholder,
  options,
  label,
  isValid = true,
  suffix,
  renderValue,
}: InputProps) => {
  const intl = useIntl();

  const ref = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useClickOutside([ref.current], (isOut) => isOut && isOpen && setIsOpen(false));

  const handleOptionSelect = (key: string) => {
    setIsOpen(false);
    onChange?.(key);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  const renderOptions = () => {
    return options?.map(({ key, value }) => (
      <Container
        key={key}
        fullWidth
        onClick={() => handleOptionSelect(key)}
        className="cursor-pointer"
      >
        {value}
      </Container>
    ));
  };

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const INPUT_CLASS = cls(
    'bg-transparent p-0 border-0 w-full placeholder:font-body-m font-body-m placeholder:text-secondary outline-none focus:ring-0',
  );

  const INPUT_WRAPPER_CLASS = cls(
    'rounded-xl border px-4 py-3',
    isValid ? 'border-default' : 'border-alert text-alert',
    options && 'p-4 pr-8 bg-gradient-to-r from-base/[.6] from-0% to-transparent to-50%',
    !isValid && 'hover:!border-alert focus-within:!border-alert',
  );

  return (
    <Container
      ref={ref}
      fullWidth
      column
      className={cls('relative', className)}
      data-testid={testId}
    >
      <Text color="secondary" type="body-s" uppercase id={label} className="text-[11px] mb-2" />
      <div className={cls('relative w-full', INPUT_WRAPPER_CLASS)}>
        {options && (
          <Container
            fullWidth
            onClick={toggleIsOpen}
            className={cls(INPUT_CLASS, 'cursor-default', !isValid && '[&>*]:text-alert')}
          >
            {renderValue ? renderValue(value) : value}
          </Container>
        )}
        {!options && (
          <Container fullWidth>
            <input
              data-testid={`${testId}-input`}
              type={type}
              onChange={handleChange}
              value={value}
              className={cls(INPUT_CLASS)}
              onClick={options && toggleIsOpen}
              placeholder={placeholder && intl.formatMessage({ id: placeholder })}
            />
            {suffix && suffix}
          </Container>
        )}

        {options && (
          <Container
            alignItems="center"
            className="pl-2 bg-default absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
          >
            <ChevronDownIcon onClick={toggleIsOpen} className="stroke-secondary w-4 h-auto" />
            {suffix && (
              <Container alignItems="center">
                <div className="h-5 border border-default mx-4" />
                {suffix}
              </Container>
            )}
          </Container>
        )}
      </div>
      {options && isOpen && (
        <Container
          fullWidth
          column
          className="max-h-[200px] absolute top-full mt-2 z-dropdown bg-default rounded-lg border-default border shadow-lg overflow-y-auto"
        >
          {renderOptions()}
        </Container>
      )}
    </Container>
  );
};
