import { Button, Container } from 'ui';
import { PoolWithShare, TxType, useMetamaskNetwork } from 'core';
import { BN_ZERO } from '@mangata-finance/sdk';
import { EligibleLpTokensCard } from '../EligibleLpTokensCard/EligibleLpTokensCard';
import { BN } from '@polkadot/util';
import { useTx } from '../../../Tx';

interface LiquidityActivationCardProps {
  pool: PoolWithShare;
  activeLPAmount: BN;
  availableLPAmount: BN;
  onActivate: () => void;
  onDeactivate: () => void;
}

export function LiquidityActivationCard({
  pool,
  onActivate,
  onDeactivate,
  activeLPAmount,
  availableLPAmount,
}: LiquidityActivationCardProps) {
  const { isChainSwitchRequired, requestDefaultChain } = useMetamaskNetwork();
  const { isAllowed } = useTx();
  const canActivate =
    availableLPAmount.sub(activeLPAmount).gt(BN_ZERO) &&
    isAllowed(TxType.ActivateLP) &&
    isAllowed(TxType.RemoveLiquidity) &&
    isAllowed(TxType.AddLiquidity);
  const canDeactivate =
    activeLPAmount.gt(BN_ZERO) &&
    isAllowed(TxType.DeactivateLP) &&
    isAllowed(TxType.RemoveLiquidity) &&
    isAllowed(TxType.AddLiquidity);

  const renderActionButtons = () => {
    if (isChainSwitchRequired) {
      return (
        <Button
          fullWidth
          className="w-full mr-4"
          variant="secondary"
          size="s"
          onClick={requestDefaultChain}
          message={{ id: 'common.switch.defaultNetwork' }}
        />
      );
    }

    return (
      <>
        <Button
          variant="secondary"
          className="w-1/3 mr-4"
          size="s"
          isDisabled={!canDeactivate}
          onClick={onDeactivate}
          data-testid="deactivate"
          message={{ id: 'liq.mining.detail.widget.lp.deactivate' }}
        />
        <Button
          variant="secondary"
          className="w-full"
          size="s"
          isDisabled={!canActivate}
          onClick={onActivate}
          data-testid="activate"
          message={{ id: 'liq.mining.detail.widget.lp.activate' }}
        />
      </>
    );
  };

  return (
    <Container fullWidth column className="p-6">
      <EligibleLpTokensCard
        pool={pool}
        activeLPAmount={activeLPAmount}
        availableLPAmount={availableLPAmount}
      />
      <Container fullWidth className="mt-4">
        {renderActionButtons()}
      </Container>
    </Container>
  );
}
