import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN, MangataGenericEvent } from 'gasp-sdk';
import { some } from 'lodash-es';
import {
  TransactionErrorEvent,
  TransactionStore,
  TxAsset,
  TxType,
  ExtrinsicTx,
} from '../../../transaction';
import { RemoveLiquidityTxSuccess } from '../../Pool';
import { QueryOptional } from '../../../../services';
import { BN } from '@polkadot/util';
import { ApiPromise } from '@polkadot/api';
import { get3rdPartyLiquidityDeactivationTxs } from '../../deactivateLiqFor3rdPartyRewards/services/deactivateLiqFor3rdPartyRewardsService';
import { Config } from 'wagmi';

export type RemoveLiquidityProps = {
  firstTxAsset: TxAsset;
  secondTxAsset: TxAsset;
  liquidityTokenId: string;
  lpAmount: string;
  amountPerc: string;
  active3rdPartyLiquidities: [string, BN][];
  onDone?: () => void;
};

const getError = (txEvents: MangataGenericEvent[]) => {
  const failedExtEvent = txEvents.find(
    (event) => event.method === TransactionErrorEvent.ExtrinsicFailed,
  );

  return {
    name: failedExtEvent?.error?.documentation.join(' ') || 'Unknown error',
  };
};

export const submitRemoveLiquidity =
  (
    api: ApiPromise | null,
    transactionStore: TransactionStore,
    selectedAddress: QueryOptional<string>,
    config: QueryOptional<Config>,
  ) =>
  async ({
    firstTxAsset,
    secondTxAsset,
    lpAmount,
    active3rdPartyLiquidities,
    liquidityTokenId,
    onDone,
  }: RemoveLiquidityProps) => {
    if (!selectedAddress || !api || !config) {
      return false;
    }

    const extrinsicStatusCallback = (txEvents: MangataGenericEvent[]) => {
      if (some(txEvents, { method: RemoveLiquidityTxSuccess.LiquidityBurned })) {
        tx.done();
      } else {
        tx.doneWithError(getError(txEvents));
      }
    };

    const tx = new ExtrinsicTx(
      api,
      transactionStore,
      config,
      selectedAddress,
      undefined,
      extrinsicStatusCallback,
    )
      .create(TxType.RemoveLiquidity)
      .setOptions({ onDone })
      .setMetadata({
        amount: fromBN(new BN(lpAmount).muln(100), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS),
        symbol: `${firstTxAsset.symbol}-${secondTxAsset.symbol}`,
      });

    const removeLiqTx = api.tx.xyk.burnLiquidity(
      firstTxAsset.id,
      secondTxAsset.id,
      new BN(lpAmount),
    );

    const extrinsic = (() => {
      if (active3rdPartyLiquidities.length > 0) {
        return api.tx.utility.batchAll([
          ...get3rdPartyLiquidityDeactivationTxs(api, liquidityTokenId, active3rdPartyLiquidities),
          removeLiqTx,
        ]);
      }

      return removeLiqTx;
    })();

    await tx.setTx(extrinsic).build().send();

    return true;
  };
