import { FormattedMessage, useIntl } from 'react-intl';
import {
  AmountTooltip,
  Container,
  ETHFeeInfo,
  FormatAmount,
  formatAmount,
  RewardsBadge,
  Skeleton,
  Text,
  Tooltip,
  WidgetDetailRow,
} from 'ui';
import { useProvideLiquidityStore } from '../store/useProvideLiquidityStore';
import { AdaptiveTxFee, usePromotedPoolBlockRewards } from 'core';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { fromBN } from '@mangata-finance/sdk';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import { EstimatedShare, ProvideLiquidityFormError } from '../ProvideLiquidity';

interface ProvideLiquidityDetailsProps {
  expectedShare: EstimatedShare;
  feeData: AdaptiveTxFee['current'] | null;
  firstAmount: string | null;
  pool: PoolWithRatio | null;
  isFeeLoading: boolean;
}

export const ProvideLiquidityDetails = ({
  expectedShare,
  feeData,
  firstAmount,
  pool,
  isFeeLoading,
}: ProvideLiquidityDetailsProps) => {
  const intl = useIntl();
  const { formError } = useProvideLiquidityStore();
  const { promotedPoolBlockRewardsQuery } = usePromotedPoolBlockRewards(firstAmount ?? null, pool);
  const blockRewards =
    promotedPoolBlockRewardsQuery.data && fromBN(promotedPoolBlockRewardsQuery.data, 18);
  const isFeeInsufficient = formError[ProvideLiquidityFormError.InsufficientFee];
  const isNotEmptyPool = pool && pool.firstTokenAmount.gtn(0) && pool.secondTokenAmount.gtn(0);

  const Fee = feeData && (
    <Text type="label" color={isFeeInsufficient ? 'alert' : 'neutral'}>
      <AmountTooltip
        id="add-liq-fee-tooltip"
        value={feeData?.amount ?? ''}
        options={{ precision: 3, maxChars: 15, nonZeroPrecision: true }}
        className="mx-1"
      />
      {feeData?.symbol || ''}
    </Text>
  );

  const renderFee = () => {
    switch (true) {
      case feeData && isFeeInsufficient:
        return (
          <Tooltip
            tooltipContent={<FormattedMessage id="pool.provide.fee.insufficient" />}
            className="flex items-center"
            inPortal
            leftOffset={false}
          >
            {Fee}
            <InfoIcon className={'w-[24px] h-[24px] ml-1 fill-alert'} />
          </Tooltip>
        );
      case !feeData && isFeeLoading:
        return (
          <Skeleton height="14px" width="70px" className={isFeeInsufficient ? 'my-[5px]' : ''} />
        );

      case !!feeData:
        return Fee;

      default:
        return (
          <Text type="label" color="secondary">
            -
          </Text>
        );
    }
  };

  const AddedShare = expectedShare.current && (
    <Text color="accent" className="ml-1">
      +
      <FormatAmount
        className="ml-1"
        value={expectedShare.next}
        options={{ precision: 2, maxChars: 20, nonZeroPrecision: true }}
      />
      %
    </Text>
  );

  return (
    <Container column fullWidth className="mt-8" data-testid="provide-liq-details">
      <Container column fullWidth className="px-4">
        <WidgetDetailRow
          label={intl.formatMessage({ id: 'pool.provide.details.pool.share' })}
          value={
            <Text>
              <FormatAmount
                value={expectedShare.current || expectedShare.next}
                options={{ precision: 2, maxChars: 20, nonZeroPrecision: true }}
              />
              %{AddedShare}
            </Text>
          }
          data-testid="poolShare"
        />
        <WidgetDetailRow
          label={
            <Text
              id="pool.provide.details.pool.fee"
              type="title-4"
              color={isFeeInsufficient ? 'alert' : 'secondary'}
              className="mr-2"
            />
          }
          value={renderFee()}
          data-testid="fee"
        />
      </Container>
      {feeData?.id === '1' && <ETHFeeInfo className="mt-4" />}

      {pool?.isPromoted && isNotEmptyPool && (
        <Container
          className="px-6 pt-4 pb-5 bg-default rounded mt-5"
          data-testid="est-rewards"
          fullWidth
          column
        >
          <Container fullWidth justifyContent="space-between" alignItems="center">
            <Text type="title-3" id="pool.provide.details.est.rewards.title" />
            <RewardsBadge
              variant="amount"
              isLoading={promotedPoolBlockRewardsQuery.isLoading || !blockRewards}
              value={formatAmount(blockRewards || '0', { precision: 0 })}
              data-testid="est-rewards-amount"
            />
          </Container>
          <Text className="mt-2" id="pool.provide.details.est.rewards.desc" />
        </Container>
      )}
    </Container>
  );
};
