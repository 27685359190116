import cls from 'classnames';
import { ClassName, TestId } from 'core';
import { FormEvent, KeyboardEventHandler, MutableRefObject, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { Container } from '../Container/Container';

export interface SearchProps extends TestId, ClassName {
  placeholderId?: string;
  isSearchOpen: boolean;
  toggleSearch: () => void;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const Search = ({
  isSearchOpen,
  toggleSearch,
  placeholderId,
  containerRef,
  'data-testid': testId,
}: SearchProps) => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchIconRef = useRef<HTMLDivElement>(null);

  const searchQuery = searchParams.get('search');

  const handleChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    setSearchParams({
      search: currentTarget.value,
    });
  };

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        (!containerRef.current?.contains(e.target as Node) && isSearchOpen) ||
        searchIconRef.current?.contains(e.target as Node)
      ) {
        toggleSearch();
        setSearchParams({});
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [containerRef, isSearchOpen, setSearchParams, toggleSearch]);

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Escape' && isSearchOpen) {
      event.preventDefault();
      toggleSearch();
      setSearchParams({});
    }
  };

  return (
    <Container
      className={cls(
        'cursor-pointer h-[47px] border-b',
        isSearchOpen ? 'w-full border-default' : 'w-[48px] border-none',
      )}
      alignItems="center"
      justifyContent="center"
      tabIndex={0}
      data-testid={`${testId}-container`}
    >
      {isSearchOpen && (
        <input
          type="search"
          value={searchQuery || ''}
          autoFocus
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: placeholderId ?? 'common.search' })}
          data-testid={`${testId}-input`}
          className={cls(
            'text-neutral font-body-l py-[12px] px-0 bg-transparent w-full align-bottom',
            'border-none focus:ring-0 focus:outline-none',
            'peer placeholder:text-secondary relative top-[1px]',
          )}
        />
      )}
      <Container
        ref={searchIconRef}
        className="group w-[47px] h-[47px]"
        alignItems="center"
        justifyContent="center"
        data-testid={`${testId}-toggle`}
      >
        {isSearchOpen ? (
          <CloseIcon className="h-auto w-[24px] fill-secondary group-hover:fill-neutral" />
        ) : (
          <SearchIcon className="h-auto w-[24px] stroke-secondary group-hover:stroke-neutral" />
        )}
      </Container>
    </Container>
  );
};
