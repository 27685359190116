import { ClassName, TestId, useSafeTxModalStore } from 'core';
import { Button, Checkbox, Container, Modal, Text, formatAmount } from '../';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';

export interface LowBalanceModalProps extends ClassName, TestId {}

export const LowBalanceModal = ({ 'data-testid': testId }: LowBalanceModalProps) => {
  const { fee, isOpen, isConsentChecked, onClose, onProceed, toggleConsentChecked } =
    useSafeTxModalStore();

  if (!fee) return null;

  const feeSymbol = fee.asset.symbol;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="w-[400px] px-8 py-10 pt-8 flex-col whitespace-pre-line"
      data-testid={testId}
      closeIconClassName="hidden"
    >
      <Container alignItems="center" className="mb-6">
        <InfoIcon className="fill-alert w-[32px] h-auto mr-3" />
        <Text id="modal.low.balance.title" values={{ feeSymbol }} type="title-2" />
      </Container>
      <Text
        id={`modal.low.balance.${fee.isAdaptive ? 'adaptive' : 'mgx'}.desc`}
        color="secondary"
        values={{
          restrictText: <Text color="alert" id="modal.low.balance.desc.restrict" />,
          feeSymbol,
        }}
      />
      <Container className="rounded p-4 bg-default mt-6" column fullWidth>
        <Container alignItems="center" justifyContent="space-between" fullWidth className="mb-2">
          <Text id="modal.low.balance.current.label" color="secondary" />
          <Text>
            {formatAmount(fee.currentBalance, { precision: 2, nonZeroPrecision: true })} {feeSymbol}
          </Text>
        </Container>
        <Container alignItems="center" justifyContent="space-between" fullWidth>
          <Text id="modal.low.balance.after.label" color="secondary" />
          <Text color="alert">
            ~ {formatAmount(fee.remainingBalanceAfterTx, { precision: 2, nonZeroPrecision: true })}{' '}
            {feeSymbol}
          </Text>
        </Container>
      </Container>
      <Container
        className="rounded p-4 bg-default mt-2 mb-9 cursor-pointer select-none"
        onClick={toggleConsentChecked}
      >
        <Checkbox id="proceed" value={isConsentChecked} className="mr-3 mt-1" />
        <Text id="modal.low.balance.check.desc" color="secondary" values={{ feeSymbol }} />
      </Container>
      <Button
        fullWidth
        variant="primary"
        isDisabled={!isConsentChecked}
        className="mb-4"
        onClick={onProceed}
        message={{ id: 'modal.low.balance.cta' }}
      />
      <Button
        fullWidth
        variant="secondary"
        onClick={onClose}
        message={{ id: 'modal.low.balance.cancel' }}
      />
    </Modal>
  );
};
