import { Button, Container, Text } from 'ui';
import { ReactComponent as NoResultImage } from 'icons/search-table.svg';

interface PoolsSearchListEmptyProps {
  onCreatePoolClick: () => void;
}

export const PoolsSearchListEmpty = ({ onCreatePoolClick }: PoolsSearchListEmptyProps) => {
  return (
    <Container
      column
      alignItems="center"
      justifyContent="center"
      className="w-full py-10 bg-soft rounded-b-xl mt-10 rounded-lg h-[465px]"
    >
      <NoResultImage className="w-[50px] h-auto stroke-accent" />
      <Text type="title-2" id="pools.page.search.empty.title" className="mt-5" color="secondary" />
      <Text
        color="secondary"
        id="pools.page.search.empty.desc"
        className="mt-2 whitespace-pre-line text-center"
      />
      <Button
        onClick={onCreatePoolClick}
        className="mt-5"
        variant="primary-outlined"
        message={{ id: 'pools.page.search.empty.cta' }}
      />
    </Container>
  );
};
