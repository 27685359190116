import { Container } from '../Container/Container';
import { Text } from '../Text/Text';
import { ReactComponent as GaslessIcon } from '../../../assets/icons/gasless.svg';
import cls from 'classnames';

interface GaslessTagProps {
  isActive?: boolean;
}
export function GaslessTag({ isActive = false }: GaslessTagProps) {
  const CONTAINER_CLASS = cls('rounded-md py-1 px-2', !isActive ? 'bg-alert' : 'bg-accent-soft');
  const ICON_CLASS = cls('mr-2 w-[10px]', isActive ? 'fill-accent ' : 'fill-alert');

  return (
    <Container inline alignItems="center" className={CONTAINER_CLASS}>
      <GaslessIcon className={ICON_CLASS} />
      <Text
        id="gaslessTag.title"
        color={isActive ? 'accent' : 'alert'}
        type="body-s"
        className="leading-none"
      />
    </Container>
  );
}
