import { useNavigate } from 'react-router';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';
import { Button, Container, ShortAddress, Text } from 'ui';
import { ClassName } from 'core';
import { CollatorStateChip } from 'modules';
import { ReactComponent as BackIcon } from 'assets/icons/arrow-left.svg';

interface StakingDetailHeaderProps extends ClassName {
  collator: ParachainStakingCollatorCandidate | null | undefined;
}

export const StakingDetailHeader = ({ collator, className }: StakingDetailHeaderProps) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/staking');
  };

  if (!collator) {
    return null;
  }

  return (
    <Container fullWidth column className={className}>
      <Button
        variant="secondary"
        LeadIcon={BackIcon}
        onClick={handleBack}
        className="py-0"
        data-testid="back-button"
        message={{ id: 'staking.detail.back' }}
      />
      <Container column fullWidth className="mt-10">
        <Container alignItems="center" fullWidth className="gap-3">
          <Text type="display-2">
            <ShortAddress leftOffset={false} address={collator?.id.toString()} separator=" .. " />
          </Text>
          <CollatorStateChip isActive={collator?.state.isActive} />
        </Container>
      </Container>
    </Container>
  );
};
