import { useState } from 'react';
import { Decimal } from 'decimal.js';
import cls from 'classnames';
import { Button, Container, Text, SliderWidget, Banner, Tooltip } from 'ui';
import {
  TxType,
  useAll3rdPartyRewardsInfoQuery,
  useMetamaskNetwork,
  useRemoveLiquidity,
  useReserves,
  useSafeTransaction,
} from 'core';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { useRemoveLiquidityStore } from './store/useRemoveLiquidityStore';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { RemoveLiquidityDetails } from './details/RemoveLiquidityDetails';
import { RemoveLiquidityWidgetProps } from './RemoveLiquidity';
import { getSubmitMessage, useRemoveLiquidityValidation } from './useRemoveLiquidityValidation';
import { BN } from '@polkadot/util';
import { RemoveLiquidityModal } from './RemoveLiquidityModal/RemoveLiquidityModal';
import { useTx } from '../../Tx';

export const RemoveLiquidityWidget = ({
  pool,
  className,
  onCancel,
  onSuccess,
}: RemoveLiquidityWidgetProps) => {
  const store = useRemoveLiquidityStore();
  const { stakedUnactivatedReserves, stakedAndActivatedReserves } = useReserves(
    pool.liquidityTokenId,
  );
  const { isAllowed } = useTx();
  const { setRemoveAmount, removeAmount } = store;
  const { allThirdPartyRewardsInfoQuery } = useAll3rdPartyRewardsInfoQuery();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { isChainSwitchRequired, requestDefaultChain } = useMetamaskNetwork();

  const lpAmountToRemove = new Decimal(pool.totalLPTokens.toString())
    .mul(new Decimal(removeAmount).div(100))
    .toFixed(0);
  const pool3rdPartyRewardsInfo = allThirdPartyRewardsInfoQuery.data?.get(pool.liquidityTokenId);
  const active3rdPartyLiquidities = Array.from(pool3rdPartyRewardsInfo?.entries() || [])
    .map(([id, value]) => value.activatedAmount.gtn(0) && [id, value.activatedAmount.toBn()])
    .filter(($): $ is [string, BN] => !!$);

  const { removeLiquidityFeeQuery, removeLiquidityMutation } = useRemoveLiquidity({
    firstAssetId: pool.firstAsset.id,
    secondAssetId: pool.secondAsset.id,
    lpAmount: lpAmountToRemove,
    liquidityTokenId: pool.liquidityTokenId,
    active3rdPartyLiquidities,
  });

  const totalStakedLp = stakedAndActivatedReserves?.add(stakedUnactivatedReserves);
  const stakedPerc = totalStakedLp
    ? new Decimal(totalStakedLp?.toString())
        .div(pool.totalLPTokens.toString())
        .mul(100)
        .toDP(0, 0)
        .toNumber() || 0
    : 0;
  const { isInvalid, isAboveMaxAmount, maxAmount } = useRemoveLiquidityValidation(
    removeLiquidityFeeQuery.data?.current,
    stakedPerc,
  );

  const poolShare = fromBN(pool.share, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  const estFirstAmount = new Decimal(poolShare)
    .mul(fromBN(pool.firstTokenAmount, pool.firstAsset.decimals))
    .mul(removeAmount)
    .div(100)
    .toDecimalPlaces(pool.firstAsset.decimals);
  const estSecondAmount = new Decimal(poolShare)
    .mul(fromBN(pool.secondTokenAmount, pool.secondAsset.decimals))
    .mul(removeAmount)
    .div(100)
    .toDecimalPlaces(pool.secondAsset.decimals);
  const firstTxAsset = { ...pool.firstAsset, amount: estFirstAmount.toFixed() };
  const secondTxAsset = { ...pool.secondAsset, amount: estSecondAmount.toFixed() };

  const handleSubmit = () => {
    removeLiquidityMutation.mutate({
      firstTxAsset,
      secondTxAsset,
      liquidityTokenId: pool.liquidityTokenId,
      lpAmount: lpAmountToRemove,
      amountPerc: removeAmount.toString(),
      active3rdPartyLiquidities,
      onDone: () => {
        onSuccess?.();
      },
    });
    setIsConfirmModalOpen(false);
  };

  const { submitTxWithChecks } = useSafeTransaction(
    {
      receivingAssets: firstTxAsset && secondTxAsset ? [firstTxAsset, secondTxAsset] : [],
      adaptiveFee: removeLiquidityFeeQuery.data,
      type: TxType.RemoveLiquidity,
    },
    handleSubmit,
  );

  const handleRemoveAmountChange = (value: number) => setRemoveAmount(value);

  const isInputNullish = estFirstAmount.lte(0) && estSecondAmount.lte(0);
  const isTxAllowed =
    isAllowed(TxType.RemoveLiquidity) &&
    isAllowed(TxType.AddLiquidity) &&
    isAllowed(TxType.DeactivateLP) &&
    isAllowed(TxType.ActivateLP);

  return (
    <>
      <Container alignItems="center" justifyContent="center" className={cls(className)} fullWidth>
        <Container
          fullWidth
          className="transition-all bg-default p-6 rounded-md"
          alignItems="start"
          justifyContent="start"
          column
        >
          <Container alignItems="center" fullWidth justifyContent="space-between" className="mb-6">
            <Text type="title-1" id="position.remove.widget.title" />
            <Button
              variant="secondary"
              TrailIcon={CloseIcon}
              onClick={onCancel}
              className="py-0"
              message={{ id: 'pool.provide.details.cancel' }}
            />
          </Container>
          <SliderWidget
            value={removeAmount}
            titleId="position.remove.widget.input.title"
            onChange={handleRemoveAmountChange}
            data-testid="removedLiq-percent"
            disabledPerc={stakedPerc}
            isNotAllowed={isAboveMaxAmount}
            maxAmount={maxAmount}
            notAllowedNode={
              <Container className="mt-5">
                <Tooltip
                  inPortal
                  tooltipContent={
                    <Container fullWidth column className="w-[260px] p-1">
                      <Text id="position.remove.widget.notAllowed.slider.track.desc" />
                    </Container>
                  }
                >
                  <Text
                    className="whitespace-nowrap"
                    type="body-s"
                    color="secondary"
                    id="position.remove.widget.notAllowed.slider.track.title"
                    values={{ stakedPerc }}
                  />
                </Tooltip>
              </Container>
            }
          />
          {isAboveMaxAmount || maxAmount === 0 ? (
            <Banner
              variant="alert"
              className="mt-6"
              message={{ id: 'position.remove.widget.aboveMaxAmount' }}
            />
          ) : (
            <RemoveLiquidityDetails
              pool={pool}
              estFirstAmount={estFirstAmount.toFixed()}
              estSecondAmount={estSecondAmount.toFixed()}
              feeData={(!isInputNullish && removeLiquidityFeeQuery.data?.current) || null}
              isFeeLoading={removeLiquidityFeeQuery.isLoading}
            />
          )}
          <Container fullWidth className="mt-8">
            <Button
              variant="primary"
              size="huge"
              fullWidth
              data-testid="submitSwap"
              isDisabled={
                isInputNullish ||
                isInvalid ||
                removeLiquidityFeeQuery.isLoading ||
                isAboveMaxAmount ||
                !isTxAllowed
              }
              onClick={
                isChainSwitchRequired ? requestDefaultChain : () => setIsConfirmModalOpen(true)
              }
              message={{ id: getSubmitMessage(store, isChainSwitchRequired) }}
            />
          </Container>
        </Container>
      </Container>
      <RemoveLiquidityModal
        pool={pool}
        isOpen={isConfirmModalOpen}
        onCancel={() => setIsConfirmModalOpen(false)}
        onConfirm={submitTxWithChecks}
        feeAsset={removeLiquidityFeeQuery.data?.current || null}
        isFeeInsuff={false}
        isBatchDeactivation={active3rdPartyLiquidities.length > 0}
      />
    </>
  );
};
