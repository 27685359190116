import { isEmpty } from 'lodash-es';
import { Decimal } from 'decimal.js';
import { Container, Grid, Skeleton, Text } from 'ui';
import { useAllPoolsQuery, usePoolTvlQueries, usePromotedPools } from 'core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { usePoolsUIStore } from '../store/usePoolsUIStore';
import { PoolsSearchListEmpty } from './empty/PoolSearchListEmpty';
import { useEffect, useMemo } from 'react';
import { PoolListRow } from './row/PoolListRow';

export interface PoolsListProps {
  onlyPromoted?: boolean;
  className?: string;
  searchQuery?: string;
}

const splitQuery = (query: string) => {
  return query.replace(/ \/ |\/| - |-| /g, ',').split(',');
};

const isPoolFound = (query: string | null) => (pool: PoolWithRatio) => {
  if (!query) return true;

  const searchPlaces = [
    pool.firstAsset.symbol,
    pool.firstAsset.name,
    pool.secondAsset.symbol,
    pool.secondAsset.name,
  ];

  const queryParts = splitQuery(query).filter((q) => !isEmpty(q));

  return queryParts.every((q) =>
    searchPlaces.some((place) => place.toLowerCase().includes(q.toLowerCase())),
  );
};

export function PoolsList({ onlyPromoted }: PoolsListProps) {
  const { poolAprsByLPId, promotedPools, isLoading } = usePromotedPools();
  const { allPoolsQuery } = useAllPoolsQuery();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');
  const { setIsSearchOpen, setIsCreatePoolOpen } = usePoolsUIStore();

  const tvl = usePoolTvlQueries();

  useEffect(() => {
    if (promotedPools?.length === 0 && !isLoading) {
      navigate('/pools');
    }
  }, [isLoading, navigate, promotedPools?.length]);

  const sortedPools = useMemo(() => {
    if (!promotedPools || !allPoolsQuery.data?.baseList || isLoading || !tvl) {
      return null;
    }

    const promoted = promotedPools.sort(sortPromotedPoolsByApr(poolAprsByLPId));
    const all = allPoolsQuery.data?.baseList.sort(sortPoolsByTvl(tvl));

    return {
      promoted,
      all,
    };
  }, [promotedPools, allPoolsQuery.data?.baseList, isLoading, tvl, poolAprsByLPId]);

  const list = onlyPromoted && !searchQuery ? sortedPools?.promoted : sortedPools?.all;
  const filteredList = list?.filter(isPoolFound(searchQuery));

  const ListSkeleton = new Array(5).fill(true).map((_, i) => (
    <Grid fullWidth cols={6} gap="m" key={i} className="bg-default rounded-b-xl py-3 px-6">
      <Container className="col-span-3" alignItems="center">
        <Skeleton className="w-[32px] h-[32px] rounded-xl mr-2" />
        <Skeleton className="w-[32px] h-[32px] rounded-xl mr-2 -translate-x-1/2" />
        <Skeleton className="w-[200px] h-[20px] rounded-xl" />
      </Container>
      <Container>
        <Skeleton className="w-[100px] h-[20px] rounded-xl" />
      </Container>
      <Container>
        <Skeleton className="w-[100px] h-[20px] rounded-xl" />
      </Container>
      <Container>
        <Skeleton className="w-[80px] h-[20px] rounded-xl" />
      </Container>
    </Grid>
  ));

  const isFilteredListEmpty = allPoolsQuery.isSuccess && !filteredList?.length && searchQuery;

  const handleCreatePoolClick = () => {
    navigate('/pools');
    setIsSearchOpen(false);
    setIsCreatePoolOpen(true);
  };

  return isFilteredListEmpty ? (
    <PoolsSearchListEmpty onCreatePoolClick={handleCreatePoolClick} />
  ) : (
    <Container
      justifyContent="center"
      fullWidth
      column
      className="mt-10 bg-default rounded-xl border border-default"
    >
      <Grid
        fullWidth
        cols={6}
        gap="m"
        className="bg-default rounded-t-xl py-4 px-6 border-b border-default"
      >
        <Container className="col-span-3">
          <Text id="pools.list.header.pool" color="secondary" />
        </Container>
        <Container>
          <Text id="pools.list.header.volume24h" color="secondary" />
        </Container>
        <Container>
          <Text id="pools.list.header.tvl" color="secondary" />
        </Container>
        <Container>
          <Text id="pools.list.header.apy" color="secondary" />
        </Container>
      </Grid>
      {(!isLoading &&
        filteredList?.map((pool) => (
          <PoolListRow
            key={pool.id}
            pool={pool}
            data-testid={`pool-item-${pool.firstAsset.id}-${pool.secondAsset.id}`}
          />
        ))) ||
        ListSkeleton}
    </Container>
  );
}

const sortPromotedPoolsByApr =
  (poolAprById: Record<string, string | null>) => (a: PoolWithRatio, b: PoolWithRatio) => {
    const aApr = poolAprById[a.liquidityTokenId];
    const bApr = poolAprById[b.liquidityTokenId];

    if (!aApr && !bApr) return 0;
    if (!aApr) return 1;
    if (!bApr) return -1;

    return Number(bApr) - Number(aApr);
  };

const sortPoolsByTvl = (tvl: Map<string, string>) => (a: PoolWithRatio, b: PoolWithRatio) => {
  const aTvl = tvl.get(a.liquidityTokenId);
  const bTvl = tvl.get(b.liquidityTokenId);

  if (!aTvl && !bTvl) return 0;
  if (!aTvl) return 1;
  if (!bTvl) return -1;

  const aTvlDecimal = new Decimal(aTvl);
  const bTvlDecimal = new Decimal(bTvl);

  return bTvlDecimal.cmp(aTvlDecimal);
};
