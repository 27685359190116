import { Outlet } from 'react-router-dom';
import { AppContainer, Container, LowBalanceModal, useSystemTheme } from 'ui';
import { NativeTokenWidget, TxNotificationProvider, WalletChip } from 'modules';
import { Navbar } from '../navbar/Navbar';
import { Welcome } from '../welcome/Welcome';
import { Sidebar } from '../sidebar/Sidebar';
import { useUIStore } from '../../store/useUIStore';
import { animated, useTransition } from '@react-spring/web';
import { useEffect } from 'react';
import { MobileNotAvailable } from '../mobile/MobileNotAvailable';

export function AppLayout() {
  const { isSidebarOpen, setIsSidebarOpen } = useUIStore();
  const { theme } = useSystemTheme();

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  });

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

  const walletChipTransition = useTransition(!isSidebarOpen, {
    from: { opacity: 0, maxWidth: 0, filter: 'blur(10px)' },
    enter: { opacity: 1, maxWidth: 300, filter: 'blur(0px)' },
    leave: { opacity: 0, maxWidth: 0, filter: 'blur(10px)' },
  });

  return (
    <>
      <div className="prose max-h-screen h-screen py-2 hidden lg:flex" data-testid="app-layout">
        <Welcome />
        <TxNotificationProvider />
        <Container
          fullWidth
          row
          justifyContent="space-between"
          alignItems="start"
          className="bg-default rounded-xl relative mx-2 overflow-hidden"
        >
          <Navbar />
          <AppContainer>
            <Outlet />
          </AppContainer>
          <Container alignItems="center" className="absolute right-4 top-4 z-widget">
            <NativeTokenWidget />
            {walletChipTransition(
              (style, item) =>
                item && (
                  <animated.div style={style} className="flex flex-row">
                    <div className="px-2" />
                    <WalletChip onClick={openSidebar} />
                  </animated.div>
                ),
            )}
          </Container>
          <LowBalanceModal />
        </Container>
        <Sidebar />
      </div>
      <MobileNotAvailable className="lg:hidden" />
    </>
  );
}
