import { useMutation } from '@tanstack/react-query';
import { useTransactionStore } from '../../../transaction';
import { submitSwap } from './SwapSubmitService';
import { useConfig } from 'wagmi';
import { useApi } from '../../../../services';

export const useSwapMutation = () => {
  const transactionStore = useTransactionStore();
  const api = useApi();
  const config = useConfig();

  const swapMutation = useMutation(submitSwap(api, transactionStore, config));

  return { swapMutation };
};
