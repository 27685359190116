import Decimal from 'decimal.js';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Container, TabGroup, Text } from 'ui';
import { StakeChangeModal } from './modal/StakeChangeModal';
import { useIntl } from 'react-intl';
import { CollatorListSearchInput } from './search/CollatorListSearchInput';
import { useStakingUIStore } from 'modules';
import { useEffect, useRef } from 'react';
import { isEmpty, isNull } from 'lodash-es';
import { StakingFullBanner } from './list/StakingFullBanner/StakingFullBanner';
import { EnvConfig, useCollators } from 'core';

export function StakingPage() {
  const intl = useIntl();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    collatorsMapQuery: { data: collatorsMap },
  } = useCollators();

  const collators = collatorsMap
    ? Array.from(collatorsMap.values()).sort((a, b) => b.bond.cmp(a.bond))
    : [];

  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get('search');

  const { isSearchOpen, setIsSearchOpen } = useStakingUIStore();

  const areCandidatesFull =
    !isEmpty(collators) &&
    collators?.every((candidate) =>
      new Decimal(candidate.delegators.length).gte(EnvConfig.MAX_DELEGATION_COUNT),
    );

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    setSearchParams({ search: '' });
  };

  const tabPages = [
    { label: intl.formatMessage({ id: 'staking.page.tab.collators' }), path: '/staking' },
    { label: intl.formatMessage({ id: 'staking.page.tab.myStaking' }), path: '/staking/own' },
  ];

  useEffect(() => {
    if (!isSearchOpen && !isNull(searchQuery) && searchQuery !== '') {
      setIsSearchOpen(true);
    }
  }, [isSearchOpen, searchQuery, setIsSearchOpen]);

  const handleTabSelect = (i: number) => {
    navigate(tabPages[i].path);
  };

  const activeIndex = tabPages.findIndex((tab) => pathname === tab.path);
  return (
    <>
      <Container className="pt-[120px] max-h-[100vh]" column ref={containerRef}>
        <Text type="display" className="mt-4 mb-6" id="staking.page.title" />
        {areCandidatesFull && activeIndex === 0 && <StakingFullBanner />}
        <Container justifyContent="stretch" alignItems="stretch" fullWidth className="mb-8">
          {!isSearchOpen && (
            <TabGroup
              activeIndex={Math.max(activeIndex, 0)}
              data={tabPages.map(({ label }) => ({ label }))}
              onSelect={handleTabSelect}
              size="s"
            />
          )}
          <CollatorListSearchInput
            isSearchOpen={isSearchOpen}
            containerRef={containerRef}
            toggleSearch={toggleSearch}
            data-testid="pool-list-search"
          />
        </Container>
        <Outlet />
      </Container>
      <StakeChangeModal />
    </>
  );
}
