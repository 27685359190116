import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Container, TabGroup, Text } from 'ui';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { PoolListSearchInput } from './list/search/PoolListSearchInput';
import { usePoolsUIStore } from './store/usePoolsUIStore';
import { usePromotedPools } from 'core';
import { ReactComponent as PlusIcon } from 'icons/plus-circle.svg';
import { ProvideLiquidityWidget, ProvideLiquidityWidgetType } from 'modules';
import { PoolWithRatio } from 'core';
import { Modal } from 'ui';
import cls from 'classnames';

export function PoolsPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { isSearchOpen, isCreatePoolOpen, setIsCreatePoolOpen, setIsSearchOpen } =
    usePoolsUIStore();

  const { promotedPools } = usePromotedPools();
  const hasPromotedPools = promotedPools && promotedPools?.length > 0;

  const searchQuery = searchParams.get('search');

  useEffect(() => {
    if (searchQuery && !isSearchOpen) {
      setIsSearchOpen(true);
    }
  }, [setIsSearchOpen, searchQuery, isSearchOpen]);

  useEffect(() => {
    return () => {
      setIsSearchOpen(false);
    };
  }, [setIsSearchOpen]);

  const tabPages = [
    hasPromotedPools && {
      label: intl.formatMessage({ id: 'pools.page.tab.promoted' }),
      path: '/pools/promoted',
    },
    { label: intl.formatMessage({ id: 'pools.page.tab.all' }), path: '/pools' },
  ].filter(($): $ is { label: string; path: string } => !!$);

  const activeIndex = tabPages.findIndex((tab) => pathname.includes(tab.path));

  const handleTabSelect = (i: number) => {
    navigate(tabPages[i].path);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);

    if (isSearchOpen) {
      setSearchParams({});
    } else if (pathname.includes('/new')) {
      navigate('/pools');
    }
  };

  const handleCreatePoolClick = () => {
    setIsSearchOpen(false);
    setIsCreatePoolOpen(true);
  };

  const handleCloseCreatePool = () => {
    setIsCreatePoolOpen(false);
  };

  const handleCreatePoolSuccess = (pool: PoolWithRatio) => {
    setIsCreatePoolOpen(false);
    navigate(`/pools/${pool.id}`);
  };

  return (
    <>
      <Container className="pt-[120px] max-h-[100vh] whitespace-nowrap" column ref={containerRef}>
        <Text type="display" className="text-center mt-4 pb-0" id="pools.page.title" />
        <Text
          type="body-l"
          className="text-center mt-3 mb-10"
          color="secondary"
          id="pools.page.desc"
        />
        <Container justifyContent="stretch" alignItems="stretch" fullWidth>
          {!isSearchOpen && !searchQuery && (
            <TabGroup
              activeIndex={Math.max(activeIndex, 0)}
              data={tabPages.map(({ label }) => ({ label }))}
              onSelect={handleTabSelect}
              size="s"
              className="px-0"
            />
          )}
          {!isSearchOpen && (
            <Container
              onClick={handleCreatePoolClick}
              className="group h-[47px] w-[220px] border-b-1 border-default cursor-pointer"
              alignItems="center"
              justifyContent="center"
              data-testid="pool-create"
            >
              <PlusIcon className="h-auto w-[24px] stroke-secondary group-hover:stroke-accent mr-2" />
              <Text
                id="pools.page.create.pool"
                color="secondary"
                className="group-hover:text-accent"
              />
            </Container>
          )}
          <PoolListSearchInput
            isSearchOpen={isSearchOpen || !!searchQuery}
            containerRef={containerRef}
            toggleSearch={toggleSearch}
            data-testid="pool-list-search"
          />
        </Container>
        <Outlet />
      </Container>
      <Modal
        isCloseIconVisible={false}
        isOpen={isCreatePoolOpen}
        onClose={handleCloseCreatePool}
        className={cls('w-[480px] overflow-hidden py-0 px-2')}
        data-testid="provide-liquidity-modal"
      >
        <ProvideLiquidityWidget
          type={ProvideLiquidityWidgetType.Create}
          title="pool.create.title"
          onCancel={handleCloseCreatePool}
          onSuccess={handleCreatePoolSuccess}
          data-testid="provide-liquidity-widget"
        />
      </Modal>
    </>
  );
}
