import { useAssets, useCollators, useCollatorsApy, useSearch } from 'core';
import { isNil } from 'lodash-es';
import { useMemo } from 'react';
import { Container } from 'ui';
import { CollatorListSection } from './section/CollatorListSection';
import { ParachainStakingCollatorCandidate } from '@polkadot/types/lookup';

export function CollatorList() {
  const {
    collatorsMapQuery: { data: collatorsMap, isLoading },
    selectedCandidatesQuery: { data: selectedCandidates, isLoading: areCandidatesLoading },
  } = useCollators();

  const { assetsMap, isLoading: areAssetsLoading } = useAssets();

  const collators = collatorsMap
    ? Array.from(collatorsMap.values()).sort((a, b) => b.bond.cmp(a.bond))
    : [];

  const activeList = collators.filter((candidate) => {
    return (
      selectedCandidates?.has(candidate.id.toString()) &&
      candidate.state.isActive &&
      assetsMap.has(candidate.liquidityToken.toString())
    );
  });

  const { entries } = useSearch<ParachainStakingCollatorCandidate>({
    list: activeList,
    toSearch: ['id'],
  });

  const addresses = activeList.map((candidate) => candidate.id.toString());

  const { collatorApyMap } = useCollatorsApy(addresses);

  const apyMap = useMemo(
    () =>
      new Map(
        activeList
          .map((candidate) => {
            return [
              candidate.id.toString(),
              collatorApyMap
                ?.get(candidate.id.toString())
                ?.get(candidate.liquidityToken.toString()),
            ];
          })
          .filter((entry): entry is [string, string] => !isNil(entry[0]) && !isNil(entry[1])),
      ),
    [activeList, collatorApyMap],
  );
  return (
    <Container column fullWidth>
      <CollatorListSection
        isLoading={isLoading || areCandidatesLoading || areAssetsLoading}
        assets={assetsMap}
        list={entries}
        collatorApy={apyMap}
        titleId="staking.collators.active.title"
        descId="staking.collators.active.desc"
        data-testid="active-collators-list"
      />
    </Container>
  );
}
