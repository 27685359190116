import { UseQueryOptions, useQueries, useQuery } from '@tanstack/react-query';
import {
  FetchPriceDiscoveryQueryKey,
  PriceDiscoveryResult,
  fetchPriceDiscovery,
} from '../services/PriceDiscoveryService';
import { TokenId } from 'gasp-sdk';
import { transformPriceDiscovery } from '../transformers/PriceDiscoveryTransformer';
import { zipObject } from 'lodash-es';

type PriceDiscoveryQueryOptions = UseQueryOptions<
  PriceDiscoveryResult | null,
  unknown,
  PriceDiscoveryResult['current_price'] | null,
  FetchPriceDiscoveryQueryKey
>;

const queryOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  staleTime: 1000 * 60 * 5,
  retry: false,
};

const getQuery = (assetId: TokenId | undefined): PriceDiscoveryQueryOptions => ({
  queryKey: ['price-discovery', assetId],
  queryFn: fetchPriceDiscovery,
  select: transformPriceDiscovery,
  ...queryOptions,
  enabled: !!assetId,
});

export const usePriceDiscoveryQuery = (assetId: TokenId) => {
  const priceDiscoveryQuery = useQuery(getQuery(assetId));

  return { priceDiscoveryQuery };
};

export const usePriceDiscoveryQueries = (assetIds: TokenId[] | null) => {
  const priceQueries = useQueries({ queries: assetIds?.map(getQuery) || [] });

  if (!assetIds) {
    return {
      priceDiscoveryQueriesById: {},
    };
  }

  return {
    priceDiscoveryQueriesById: zipObject(assetIds, priceQueries),
  };
};
