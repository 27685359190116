import cls from 'classnames';
import { ToastBar } from 'react-hot-toast';

import { NotificationKey } from 'core';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/shield-success.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { Text } from '../Text/Text';
import { TestId } from '../../types';
import { Container } from '../Container/Container';

export type ToastProps = TestId & React.ComponentProps<typeof ToastBar>;
type Renderable = JSX.Element | string | null;

const ICON_STYLES = 'w-[20px] h-full';

const renderContent = (message: Renderable) => {
  const type = typeof message === 'string' ? message : message?.props.children;

  switch (type) {
    case NotificationKey.WALLET_CONNECTED:
      return (
        <>
          <SuccessIcon className={cls('fill-accent ', ICON_STYLES)} />
          <Text color="accent" id="wallet.toast.connected" className="ml-2 mr-[2px]" />
        </>
      );
    case NotificationKey.GENERIC_ERROR:
      return (
        <>
          <WarningIcon className={cls('fill-alert', ICON_STYLES)} />
          <Text color="alert" id="error.toast" className="ml-2 mr-[2px]" />
        </>
      );

    default:
      return <Text>{type}</Text>;
  }
};

export function Toast({ 'data-testid': testId = 'toast', toast }: ToastProps) {
  return (
    <ToastBar toast={{ ...toast, className: '!bg-default !rounded !px-4' }}>
      {({ message }) => <Container data-testid={testId}>{renderContent(message)}</Container>}
    </ToastBar>
  );
}
