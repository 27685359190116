import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { SentryTag, TestId, useAccounts } from 'core';
import { ConnectedLayout } from './layouts/Connected/ConnectedLayout';
import { SelectWalletLayout } from './layouts/SelectWallet/SelectWalletLayout';
import { SelectAccountModal } from './layouts/SelectAccount/SelectAccountModal';
import { WalletStatus, useWalletUIStore } from './store/useWalletUIStore';
import { WalletWrapper } from './components';
import { GaspNotConnectedLayout } from './layouts/NotConnected/GaspNotConnectedLayout';
import { useTxTracking } from '../Tx/useTxTracking';

interface WalletProps extends TestId {
  onClose: () => void;
}
export const Wallet = ({ 'data-testid': testId = 'wallet', onClose }: WalletProps) => {
  const { selectedAccount } = useAccounts();
  const { setStatus, status } = useWalletUIStore();

  useTxTracking();

  useEffect(() => {
    if (selectedAccount) {
      Sentry.setTags({
        [SentryTag.UserAddress]: selectedAccount.address,
        [SentryTag.UserAccountType]: selectedAccount.type,
      });
      setStatus(WalletStatus.Connected);
    }
  }, [selectedAccount, setStatus]);

  const handleConnectWallet = () => {
    setStatus(WalletStatus.SelectWallet);
  };

  const renderWalletWrapper = () => {
    return (
      <WalletWrapper onClose={onClose} data-testid={`${testId}-wrapper`} className="h-full z-20">
        {(onClose) => {
          switch (status) {
            case WalletStatus.SelectWallet:
              return (
                <SelectWalletLayout
                  data-testid={`${testId}-${WalletStatus.SelectWallet}`}
                  onClose={onClose}
                  onBack={() => setStatus(WalletStatus.NotConnected)}
                />
              );
            case WalletStatus.Connected:
              return <ConnectedLayout data-testid={`${testId}-${WalletStatus.Connected}`} />;
            default:
              return (
                <GaspNotConnectedLayout
                  data-testid={`${testId}-${WalletStatus.NotConnected}`}
                  onClose={onClose}
                  onConnectWallet={handleConnectWallet}
                />
              );
          }
        }}
      </WalletWrapper>
    );
  };

  return (
    <>
      {renderWalletWrapper()}
      <SelectAccountModal />
    </>
  );
};
