import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { ParachainStakingDelegator } from '@polkadot/types/lookup';
import { ClassName, PoolWithShare, QueryOptional, useTotalPoolRewardsApr } from 'core';
import { HTMLProps } from 'react';
import { Link } from 'react-router-dom';
import { Container, TokenIcon, Text, FormatAmount, RewardsBadge } from 'ui';
import { ActiveRewardsWidget } from '../ActiveRewardsWidget/ActiveRewardsWidget';

interface PositionCardProps extends ClassName, Pick<HTMLProps<HTMLDivElement>, 'style'> {
  amount: string | undefined;
  isActive: boolean;
  pool: PoolWithShare;
  stakingLpTokens: QueryOptional<Set<string>>;
  delegator: QueryOptional<ParachainStakingDelegator>;
}

export function PositionCard({
  pool,
  stakingLpTokens,
  delegator,
  className,
  style,
}: PositionCardProps) {
  const { isPromoted, activatedLPTokens, nonActivatedLPTokens, symbols, sharePercentage } = pool;
  const { totalApr } = useTotalPoolRewardsApr(pool);

  const allLpTokens = activatedLPTokens.add(nonActivatedLPTokens);

  return (
    <Link to={`/positions/${pool.id}`} className={className} style={style}>
      <Container
        alignItems="center"
        justifyContent="space-between"
        className="bg-default hover:bg-hover-soft p-6 rounded-xl w-full min-h-[88px] cursor-pointer shadow-xl border border-default"
        data-testid={`pool-${pool.id}`}
        column
      >
        <Container fullWidth justifyContent="space-between">
          <Container alignItems="center">
            <TokenIcon
              token={[pool.firstAsset, pool.secondAsset]}
              className="mr-4"
              isCompact={false}
              size="m"
            />
            <Container column>
              <Text color="secondary" type="title-4" id="positionCard.position" />
              <Text type="title-2" className="mb-1 mt-0.5">
                {symbols.join(' / ')}
              </Text>
            </Container>
          </Container>
          {totalApr && <RewardsBadge value={totalApr} variant="apr" />}
        </Container>
        {!isPromoted && (
          <Container column fullWidth className="mt-6">
            <Text color="secondary" id="positionCard.share" />
            <Container>
              <Text className="mr-1">
                <FormatAmount
                  value={sharePercentage}
                  options={{ precision: 2, maxChars: 13 }}
                  data-testid="pool-share-value"
                />
              </Text>
              <Text color="secondary">%</Text>
            </Container>
          </Container>
        )}
        {isPromoted && (
          <Container column fullWidth className="mt-6">
            <Text type="title-4" color="secondary" id="positionCard.lpTokenInfo" />
            <Container>
              <Text>
                <FormatAmount
                  value={fromBN(activatedLPTokens, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
                  options={{ precision: 2, maxChars: 13 }}
                  data-testid="activated-LP-tokens-value"
                />
                {' / '}
                <FormatAmount
                  value={fromBN(allLpTokens, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
                  options={{ precision: 2, maxChars: 13 }}
                  data-testid="available-LP-tokens-value"
                />
              </Text>
              <Text className="ml-1" color="secondary">
                {symbols.join('-')}
              </Text>
            </Container>
          </Container>
        )}

        <ActiveRewardsWidget
          pool={pool}
          delegator={delegator}
          stakeLpTokens={stakingLpTokens}
          className="mt-4"
        />
      </Container>
    </Link>
  );
}
