import { TxAsset, TxType, useAccounts, useAdaptiveFee, useReservesForTx } from '../../../..';
import { useApi, DEFAULT_QUERY_OPTIONS, QueryOptional } from '../../../../../services';
import { getExecuteDelegationRequestFee } from '../services/executeDelegationRequestService';
import { ParachainStakingDelegationRequest } from '@polkadot/types/lookup';
import { useQuery } from '@tanstack/react-query';

export const useExecuteDelegationRequestFeeQuery = (
  collatorAddress: QueryOptional<string>,
  stakedTxAsset: QueryOptional<TxAsset>,
  request: ParachainStakingDelegationRequest,
) => {
  const api = useApi();
  const { selectedAccount } = useAccounts();
  const { getAdaptiveFee, isAdaptiveFeeReady } = useAdaptiveFee();
  const { sources: activateLiqSources } = useReservesForTx(TxType.ActivateLP, stakedTxAsset?.id);
  const { sources: confirmStakeSources } = useReservesForTx(
    request?.action.isIncrease ? TxType.ConfirmStakeIncrease : TxType.ConfirmStakeDecrease,
    stakedTxAsset?.id,
  );

  const executeDelegationRequestFeeQuery = useQuery(
    [
      'execute-delegation-request-fee',
      selectedAccount?.address,
      collatorAddress,
      stakedTxAsset,
      request,
    ],
    getExecuteDelegationRequestFee(api, confirmStakeSources, activateLiqSources),
    {
      ...DEFAULT_QUERY_OPTIONS,
      select: getAdaptiveFee,
      enabled:
        isAdaptiveFeeReady &&
        !!selectedAccount?.address &&
        !!api &&
        !!confirmStakeSources &&
        !!activateLiqSources,
    },
  );

  return { executeDelegationRequestFeeQuery };
};
