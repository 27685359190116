import { useInvestedPoolFromPath } from 'core';
import { Container } from 'ui';
import {
  PositionDetails,
  PositionWidgetState,
} from '../../components/PositionDetails/PositionDetails';
import { ProvideLiquidityWidget, ProvideLiquidityWidgetType } from '../../../Pool/provideLiquidity';
import { RemoveLiquidityWidget } from '../../../Pool';
import { PoolLiqMiningRewardsCard } from '../../components';

interface PositionOverviewTabPageProps {
  initialState?: PositionWidgetState;
  onWalletConnect?: () => void;
}

export const PositionOverviewTabPage = ({
  initialState,
  onWalletConnect,
}: PositionOverviewTabPageProps) => {
  const { pool: investedPool } = useInvestedPoolFromPath();

  if (!investedPool) {
    return null;
  }

  return (
    <Container column fullWidth>
      <PositionDetails
        initialState={initialState}
        pool={investedPool}
        renderAddLiq={(onCancel) => (
          <ProvideLiquidityWidget
            type={ProvideLiquidityWidgetType.Add}
            title="pool.provide.details.title"
            onSuccess={onCancel}
            onCancel={onCancel}
            onWalletConnect={onWalletConnect}
          />
        )}
        renderRemoveLiq={(onCancel) => (
          <RemoveLiquidityWidget pool={investedPool} onCancel={onCancel} onSuccess={onCancel} />
        )}
      />
      <PoolLiqMiningRewardsCard className="mt-8" pool={investedPool} />
    </Container>
  );
};
