import { Container, Text, WalletLogo } from 'ui';

import { TestId } from 'core';
import { FormattedMessage } from 'react-intl';

interface WalletCardProps extends TestId {
  name: string;
  onClick(wallet: string): void;
}

export const WalletCard = ({ name, onClick, 'data-testid': testId }: WalletCardProps) => {
  const handleWalletSelect = () => onClick(name);
  const defaultWalletName = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <Container
      inline
      justifyContent="space-between"
      alignItems="center"
      column
      data-testid={testId + '-' + name}
      onClick={handleWalletSelect}
      fullWidth
      className="group/wallet-card bg-default hover:bg-input rounded-full border border-default cursor-pointer transition-all"
    >
      <Container fullWidth alignItems="center" className="p-4">
        <WalletLogo
          className="w-7 group-hover/wallet-card:scale-[1.15] transition-all"
          wallet={name}
        />
        <Text type="title-4" className="ml-3 max-w-[200px] text-ellipsis overflow-hidden">
          <FormattedMessage
            id={`wallet.extension.${name.toLowerCase()}`}
            defaultMessage={defaultWalletName}
          />
        </Text>
      </Container>
    </Container>
  );
};
