import * as React from 'react';
import cls from 'classnames';

import { Button, ButtonVariant } from '../Button/Button';

import { ClassName, TestId } from '../../types';

export interface IconButtonProps
  extends ClassName,
    TestId,
    Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  iconClassName?: string;
  size?: IconButtonSize;
  variant?: ButtonVariant;
  iconFill?: boolean;
  iconStroke?: boolean;
}

export type IconButtonSize = 'l' | 'm' | 's';

const SIZES: Record<IconButtonSize, Record<string, string>> = {
  l: {
    btn: 'w-[48px] h-[48px]',
    icon: 'w-[20px] ',
  },
  m: {
    btn: 'w-[40px] h-[40px]',
    icon: 'w-[20px]',
  },
  s: {
    btn: 'w-[32px] h-[32px]',
    icon: 'w-[16px]',
  },
};

export function IconButton({
  Icon,
  className,
  onClick,
  iconClassName,
  variant = 'secondary',
  'data-testid': testId,
  size = 'l',
  iconFill,
  iconStroke = true,
}: IconButtonProps) {
  const ICON_STYLES: Record<ButtonVariant, Record<'fill' | 'stroke', string>> = {
    primary: {
      fill: cls('fill-inverted'),
      stroke: cls('stroke-inverted'),
    },
    secondary: {
      fill: cls('fill-secondary'),
      stroke: cls('stroke-secondary'),
    },
    premium: {
      fill: cls('fill-neutral'),
      stroke: cls('stroke-neutral'),
    },
    accent: {
      fill: cls('fill-inverted'),
      stroke: cls('stroke-inverted'),
    },
    'primary-outlined': {
      fill: cls('fill-accent'),
      stroke: cls('stroke-accent'),
    },
    alert: {
      fill: cls('fill-alert'),
      stroke: cls('stroke-alert'),
    },
    base: {
      fill: '',
      stroke: '',
    },
  };

  return (
    <Button
      variant="secondary"
      className={cls('min-w-[0] bg-transparent px-1 border-none', SIZES[size].btn, className)}
      onClick={onClick}
      data-testid={testId}
    >
      <Icon
        className={cls(
          'h-[auto]',
          SIZES[size].icon,
          iconFill && ICON_STYLES[variant].fill,
          iconStroke && ICON_STYLES[variant].stroke,
          iconClassName,
        )}
      />
    </Button>
  );
}
