import { animated, useTransition } from '@react-spring/web';
import { Container } from 'ui';
import { useUIStore } from '../../store/useUIStore';
import { Wallet } from 'modules';

export function Sidebar() {
  const { isSidebarOpen, setIsSidebarOpen } = useUIStore();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const sidebarTransition = useTransition(isSidebarOpen, {
    from: { opacity: 0, width: 0, filter: 'blur(20px)' },
    enter: { opacity: 1, width: 400, filter: 'blur(0px)' },
    leave: { opacity: 0, width: 0, filter: 'blur(20px)' },
    config: {
      tension: 100,
      friction: 15,
      precision: 0.01,
    },
  });
  return sidebarTransition(
    (style, item) =>
      item && (
        <animated.div style={style} className="shrink-0 overflow-hidden">
          <Container className="bg-default rounded-xl h-full mr-2 px-4 py-4 min-w-[380px]">
            <Wallet onClose={toggleSidebar} />
          </Container>
        </animated.div>
      ),
  );
}
