import { Container, Text } from 'ui';
import { ReactComponent as GaspLogo } from 'icons/gasp-logo.svg';
import { ReactComponent as GaspText } from 'icons/gasp-text.svg';
import { ReactComponent as XLogo } from 'icons/x-com.svg';
import { ReactComponent as DiscordLogo } from 'icons/discord.svg';
import { ReactComponent as MobileUnavailable } from 'icons/mobile-unavailable.svg';
import { ClassName } from 'core';
import cls from 'classnames';
import mobileImg from 'images/mobile-guy.png';

type MobileNotAvailableProps = ClassName;

export const MobileNotAvailable = ({ className }: MobileNotAvailableProps) => {
  return (
    <div className={cls('prose h-[calc(100dvh)] bg-default overflow-y-auto', className)}>
      <Container fullWidth justifyContent="space-between" alignItems="center" className="px-8 py-4">
        <Container alignItems="center">
          <GaspLogo className="h-auto w-[52px]" />
          <GaspText className="h-[27px] w-[91px] fill-attention ml-2" />
        </Container>
        <Container>
          <a
            href="https://discord.gg/gasp-xyz"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 bg-soft rounded-full mr-5"
          >
            <DiscordLogo className="h-auto w-6 fill-neutral" />
          </a>
          <a
            href="https://x.com/gasp_xyz"
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 bg-soft rounded-full"
          >
            <XLogo className="h-auto w-6 fill-neutral" />
          </a>
        </Container>
      </Container>
      <Container
        className="text-center"
        column
        alignItems="center"
        justifyContent="center"
        fullWidth
      >
        <img
          src={mobileImg}
          alt="illustration"
          className="object-cover w-[198px] sm:w-[228px] h-auto mt-8"
        />
        <Text type="display-2" className="mb-4 mt-10">
          Switch to a desktop <br /> to access Gasp
        </Text>
        <Text type="body-l" color="secondary" className="w-[262px] sm:w-[450px]">
          Mobile view is not supported, yet. <br />
          For the best experience, please use a desktop browser supporting non-custodial wallet
          extensions.
        </Text>
        <MobileUnavailable className="mt-8 w-[124px] h-[46px] fill-soft stroke-dimmed mb-2" />
      </Container>
    </div>
  );
};
