import cls from 'classnames';
import { PoolWithRatio } from 'core/src/domains/pool/Pool';
import { Button, Container, FormatAmount, Text } from 'ui';
import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { ReactComponent as PoolIcon } from 'icons/pool.svg';
import { ClassName, TestId, useInvestedPools } from 'core';

export interface PoolDetailHeaderProps extends ClassName, TestId {
  pool: PoolWithRatio;
}

export const PoolDetailPosition = ({
  pool,
  className,
  'data-testid': testId,
}: PoolDetailHeaderProps) => {
  const { data } = useInvestedPools();

  const share = data?.byId[pool.id]?.sharePercentage || '0';
  const isInvested = share && share !== '0';

  return (
    <Container
      className={cls(
        'w-full bg-default p-3 pr-5 px-4 rounded-xl',
        className,
        isInvested
          ? 'bg-gradient-to-r from-icon-highlight/[.2] from-5% via-transparent via-70% to-transparent to-100%'
          : 'bg-default',
      )}
      alignItems="center"
      justifyContent="space-between"
      data-testid={testId}
    >
      <Container alignItems="center">
        <PoolIcon
          className={cls('w-[32px] h-auto mr-4', isInvested ? 'fill-accent ' : 'fill-secondary')}
        />
        <Container column>
          <Text color="secondary" id="pool.detail.my.position" />
          <Text type="title-1">
            <FormatAmount
              value={share}
              options={{ nonZeroPrecision: true, precision: 2, maxChars: 13 }}
              className="mr-1"
              data-testid="my-pool-position-amount"
            />
            %
          </Text>
        </Container>
      </Container>
      <Button
        size="s"
        element="link"
        to={`/pools/${pool.id}/supply`}
        LeadIcon={PlusIcon}
        iconClassName="w-[20px]"
        data-testid="add-liquidity"
        message={{ id: 'common.add.liq.cta' }}
      />
    </Container>
  );
};
