import { fromBN } from '@mangata-finance/sdk';
import { useAccounts, useInternalBalance, useMangataAsset } from 'core';
import { Container, FormatAmount, Skeleton, Text, TokenIcon, useMultiHover } from 'ui';
import cls from 'classnames';
import { animated, useTransition } from '@react-spring/web';
import { NativeTokenWidgetContent } from './components/NativeTokenWidgetContent';
import { useRef, useState } from 'react';

export const NativeTokenWidget = () => {
  const { mangataAsset } = useMangataAsset();
  const { selectedAccount } = useAccounts();
  const [isOpen, setIsOpen] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const isHovering = useMultiHover([buttonRef.current, contentRef.current]);

  const { mgxBalance, isLoading: _isLoading } = useInternalBalance();
  const hasTokens = mgxBalance?.free.gtn(0);

  const isLoading = _isLoading || !mangataAsset || mgxBalance === undefined;

  const contentTransition = useTransition(isHovering, {
    from: { opacity: 0, y: 40, scale: 0.9 },
    enter: { opacity: 1, y: 0, scale: 1 },
    leave: { opacity: 0, y: 40, scale: 0.9 },
    duration: 200,
    delay: isHovering ? 0 : 200,
  });

  const renderInfo = () => {
    if (!mangataAsset) {
      return null;
    }

    return (
      <Container alignItems="center">
        <TokenIcon className="mr-2" token={mangataAsset} />
        {isLoading && !!selectedAccount ? (
          <Skeleton width="80px" height="12px" />
        ) : (
          <>
            {!hasTokens && (
              <Text id="nativeTokenWidget.getTokens" color={isHovering ? 'secondary' : 'neutral'} />
            )}
            {hasTokens && mgxBalance && (
              <Text className="whitespace-nowrap" color={isHovering ? 'secondary' : 'neutral'}>
                <FormatAmount
                  value={fromBN(mgxBalance.free, mangataAsset.decimals)}
                  options={{ maxChars: 10, precision: 0 }}
                />{' '}
                {mangataAsset.symbol}
              </Text>
            )}
          </>
        )}
      </Container>
    );
  };

  return (
    <Container column alignItems="end" className="relative">
      <Container
        ref={buttonRef}
        alignItems="center"
        onClick={() => setIsOpen(!isOpen)}
        className={cls(
          'my-1 p-3 rounded-full border border-default transition-all bg-default cursor-default h-[56px]',
          isLoading && 'hidden',
        )}
      >
        {renderInfo()}
      </Container>

      {contentTransition((style, visible) => {
        return visible ? (
          <animated.div style={style}>
            <NativeTokenWidgetContent ref={contentRef} />
          </animated.div>
        ) : null;
      })}
    </Container>
  );
};
