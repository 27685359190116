import { AccountInfo } from './components/AccountInfo/AccountInfo';
import { Button, Container } from 'ui';
import { TestId, WalletAccount, useModalStore } from 'core';
import { ReactComponent as WithdrawIcon } from 'icons/arrow-right-up.svg';
import { ReactComponent as DepositIcon } from 'icons/deposit.svg';
import { TxActivityWidget } from '../TxActivityWidget/TxActivityWidget';
import { RollupWithdrawal } from '../../../Bridge/withdrawal/RollupWithdrawal';
import { RollupDeposit } from '../../../Bridge/deposit/RollupDeposit';

export interface WalletHeaderProps extends TestId {
  selectedWallet: string | null;
  selectedAccount: WalletAccount | null;
  onClose: () => void;
}

export function WalletHeader({
  selectedWallet,
  selectedAccount,
  onClose,
  'data-testid': testId,
}: WalletHeaderProps) {
  const { openWithdrawal, openDeposit, isDepositOpen, isWithdrawalOpen } = useModalStore();

  const showAccountInfo = selectedWallet && selectedAccount;

  return (
    <>
      {isDepositOpen && <RollupDeposit />}
      {isWithdrawalOpen && <RollupWithdrawal />}
      {showAccountInfo && (
        <Container column fullWidth data-testid={testId}>
          <AccountInfo
            account={selectedAccount}
            wallet={selectedWallet}
            onToggle={onClose}
            data-testid={testId}
          />

          <Container fullWidth className="my-3">
            <Button
              size="m"
              onClick={openDeposit}
              fullWidth
              variant="primary"
              className="mr-3"
              LeadIcon={DepositIcon}
              message={{ id: 'wallet.deposit' }}
            />
            <Button
              size="m"
              onClick={openWithdrawal}
              variant="secondary"
              LeadIcon={WithdrawIcon}
              message={{ id: 'wallet.withdraw' }}
            />
          </Container>

          <TxActivityWidget />
        </Container>
      )}
    </>
  );
}
