import { Container, Skeleton } from 'ui';

export function StakingCardSkeleton() {
  return (
    <Container fullWidth column className="rounded-xl border border-default py-4">
      <Container className="gap-2 border-b border-default p-4 pt-0" fullWidth alignItems="center">
        <div>
          <Skeleton height="32px" width="32px" />
        </div>
        <Container column fullWidth className="gap-y-1.5 ">
          <Skeleton height="8px" width="76px" />
          <Skeleton height="8px" width="150px" />
        </Container>
      </Container>
      <Container
        fullWidth
        column
        className="gap-y-2 px-4 bg-gradient-to-b from-base/[0.50] from-0% via-1% to-transparent"
      >
        <Skeleton height="8px" width="101px" className="mt-6" />
        <Skeleton height="18px" width="328px" />
        <Skeleton height="65px" width="100%" rounded={false} className="mt-4 rounded-xl" />
      </Container>
    </Container>
  );
}
