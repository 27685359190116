import { useNavigate, useParams } from 'react-router';
import { usePoolFromPath, usePromotedPools } from 'core';
import { Container } from 'ui';
import { PoolDetailHeader } from '../header/PoolDetailHeader';
import { ProvideLiquidityWidget, ProvideLiquidityWidgetType } from 'modules';

export const AddPoolLiquidityPage = () => {
  const { pool } = usePoolFromPath();
  const { poolAprsByLPId } = usePromotedPools();
  const params = useParams();
  const navigate = useNavigate();

  const poolId = params?.poolId;

  const navigateToDetail = () => {
    navigate(`/pools/${poolId}`);
  };

  return (
    <Container justifyContent="center" className="mt-[120px] w-[480px] pb-[80px]" column>
      {pool && <PoolDetailHeader pool={pool} poolApr={poolAprsByLPId[pool.liquidityTokenId]} />}
      <ProvideLiquidityWidget
        type={ProvideLiquidityWidgetType.Add}
        title="pool.provide.details.title"
        onCancel={navigateToDetail}
        className="mt-8"
        data-testid="provide-liquidity-widget"
      />
    </Container>
  );
};
