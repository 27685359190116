import { Container, Text, ShortAddress } from 'ui';

import { TestId, WalletAccount } from 'core';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { IconCircle } from '../../../../components/IconCircle/IconCircle';

interface AccountItemProps extends TestId {
  data: WalletAccount;
  onClick(account: WalletAccount): void;
  withAvatar?: boolean;
}

export const AccountItem = ({
  data,
  onClick,
  'data-testid': testId = 'accountItem',
  withAvatar,
}: AccountItemProps) => {
  const handleAccountSelected = () => onClick(data);

  return (
    <Container
      justifyContent="space-between"
      alignItems="center"
      data-testid={testId}
      onClick={handleAccountSelected}
      className="py-3 pl-2 pr-4 w-full hover:bg-hover rounded cursor-pointer transition-all"
    >
      <Container alignItems="center" className="h-[32px]">
        {withAvatar && <IconCircle icon="user" className="mx-2" />}
        <Container className="ml-2" column>
          <Text type="title-4" className="">
            {data.name || <ShortAddress address={data.address} />}
          </Text>
          {data.name && (
            <Text type="body-s" className="" color="secondary">
              <ShortAddress address={data.address} />
            </Text>
          )}
        </Container>
      </Container>
      <ChevronRight className="w-[20px] h-full stroke-secondary" />
    </Container>
  );
};
