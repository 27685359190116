import { Container, Text } from 'ui';
import { ReactComponent as LiquidityIcon } from 'assets/icons/liquidity.svg';

export const PoolAlreadyExistBanner = () => {
  return (
    <Container inline alignItems="center" className="rounded px-5 py-4 w-full bg-accent-soft mt-8">
      <Container>
        <LiquidityIcon className="w-[30px] h-auto mr-5" />
      </Container>
      <Text id="pool.create.already.exists.info" />
    </Container>
  );
};
