import Decimal from 'decimal.js';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from 'gasp-sdk';
import { Container, FormatAmount, Grid, Skeleton, Text, TokenIcon } from 'ui';
import { CollatorDetailCard, StartStakingWidget, StakingLimitReachedModal, MyStake } from 'modules';
import {
  useAssets,
  useCollatorApy,
  useCollatorRewards,
  useCollatorStateQuery,
  useDelegatorStateQuery,
} from 'core';
import { calculateMinBond } from '../list/section/CollatorListSection';
import { StakingDetailHeader } from '../header/StakingDetailHeader';
import { EnvConfig } from 'core';
import { BN } from '@polkadot/util';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as BoltIcon } from 'icons/lightning.svg';
import { StakingNotAvailable } from './StakingNotAvailable/StakingNotAvailable';

export function StakingDetailPage() {
  const { assets } = useAssets();
  const params = useParams();
  const collatorAddress = params?.collator;
  const {
    collatorStateQuery: { data: collatorData },
  } = useCollatorStateQuery(collatorAddress);
  const {
    delegatorStateQuery: { data: delegatorData },
  } = useDelegatorStateQuery();
  const {
    collatorApyQuery: { data: apyData, isLoading: isApyLoading },
  } = useCollatorApy(collatorAddress);
  const apy = apyData ? apyData.apy : null;

  const { collatorDailyRewardsMap, isLoading: areRewardsLoading } =
    useCollatorRewards(collatorAddress);

  const collatorRewards = useMemo(() => {
    if (!collatorAddress) {
      return null;
    }

    return collatorDailyRewardsMap
      .get(collatorAddress)
      ?.find((entry) => entry.tokenId === collatorData?.liquidityToken.toString());
  }, [collatorAddress, collatorDailyRewardsMap, collatorData?.liquidityToken]);

  const stakedTokenId = collatorData?.liquidityToken;
  const stakedAsset = assets.find((asset) => asset.id === stakedTokenId?.toString()) || null;
  const candidateDelegationCount = collatorData?.delegators?.length || 0;
  const delegatorDelegationCount = delegatorData?.delegations?.length || 0;
  const minStakeAmount =
    collatorData && stakedAsset ? calculateMinBond(collatorData, stakedAsset) : null;
  const currentDelegation = delegatorData?.delegations?.find(
    (delegation) => delegation.owner.toString() === collatorAddress,
  );
  const currentStakeAmount =
    currentDelegation && stakedAsset
      ? fromBN(currentDelegation?.amount.toBn(), stakedAsset.decimals)
      : null;

  const isCandidateFull = new Decimal(candidateDelegationCount).gte(EnvConfig.MAX_DELEGATION_COUNT);

  if (!collatorAddress || !collatorData) {
    return null;
  }

  const renderApy = () => {
    if (isApyLoading) {
      return <Skeleton width="50px" height="14px" rounded className="mt-1" />;
    }

    if (apy) {
      return (
        <Container alignItems="center">
          <Text type="title-3" color="highlight">
            +{apy} %
          </Text>
        </Container>
      );
    }

    return '-';
  };

  const renderRewards = () => {
    if (areRewardsLoading) {
      return <Skeleton width="50px" height="14px" rounded className="mt-1" />;
    }

    if (collatorRewards) {
      return (
        <Container alignItems="center">
          <BoltIcon className="w-[16px] h-auto fill-accent " />
          <Text color="highlight" type="title-3" className="ml-1">
            <FormatAmount
              value={fromBN(
                new BN(collatorRewards.dailyRewards),
                BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS,
              )}
              options={{ precision: 3, nonZeroPrecision: false, maxChars: 12 }}
            />{' '}
            <FormattedMessage id="common.mainToken" />
          </Text>
        </Container>
      );
    }

    return '-';
  };

  return (
    <>
      <Container className="pt-[120px] max-h-[100vh]" column>
        <StakingDetailHeader collator={collatorData} className="mb-8" />
        {isCandidateFull && <StakingNotAvailable />}
        {stakedAsset && !isCandidateFull && (
          <Container fullWidth column className="mb-8">
            {stakedAsset && currentStakeAmount && (
              <MyStake asset={stakedAsset} amount={currentStakeAmount} />
            )}
            {!currentStakeAmount && (
              <StartStakingWidget
                stakedAsset={stakedAsset}
                collatorAddress={collatorAddress}
                minStakeAmount={minStakeAmount || '0'}
                candidateDelegationCount={candidateDelegationCount}
                delegatorDelegationCount={delegatorDelegationCount}
              />
            )}
          </Container>
        )}
        <Text id="staking.detail.stats.title" type="title-3" />
        <Grid cols={2} rows={3} gap="l" className="mt-6 w-full">
          <CollatorDetailCard titleId="staking.detail.stats.apr">{renderApy()}</CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.rewards">
            {renderRewards()}
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.token">
            <Container alignItems="center">
              {stakedAsset ? (
                <>
                  <TokenIcon token={stakedAsset} type="diagonal" className="mr-1" size="xs" />
                  {stakedAsset.symbol}
                </>
              ) : (
                '-'
              )}
            </Container>
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.minStake">
            {stakedAsset && (
              <FormatAmount value={minStakeAmount} options={{ precision: 2 }} className="mr-1" />
            )}
            {stakedAsset?.symbol || '-'}
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.totalStake">
            {stakedAsset && (
              <FormatAmount
                value={fromBN(collatorData?.bond?.toBn() || 0, stakedAsset.decimals)}
                options={{ precision: 2, nonZeroPrecision: true }}
                className="mr-1"
              />
            )}
            {stakedAsset?.symbol || '-'}
          </CollatorDetailCard>
          <CollatorDetailCard titleId="staking.detail.stats.delegators">
            {candidateDelegationCount}
          </CollatorDetailCard>
        </Grid>
      </Container>
      <StakingLimitReachedModal />
    </>
  );
}
