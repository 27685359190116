import cls from 'classnames';
import { ClassName, TestId } from 'core';
import { FocusEventHandler, FormEvent, KeyboardEventHandler, MutableRefObject } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { ReactComponent as SearchIcon } from 'icons/search.svg';
import { ReactComponent as ClearIcon } from 'icons/cross.svg';
import { Container, Text } from 'ui';

interface PoolListSearchInputProps extends ClassName, TestId {
  isSearchOpen: boolean;
  toggleSearch: () => void;
  containerRef: MutableRefObject<HTMLDivElement | null>;
}

export const PoolListSearchInput = ({
  isSearchOpen,
  toggleSearch,
  containerRef,
  'data-testid': testId,
  className,
}: PoolListSearchInputProps) => {
  const intl = useIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = searchParams.get('search');
  const SearchToolIcon = isSearchOpen ? ClearIcon : SearchIcon;

  const handleChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    setSearchParams({
      search: currentTarget.value,
    });
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (containerRef.current && !containerRef.current.contains(e.relatedTarget) && isSearchOpen) {
      toggleSearch();
      setSearchParams({});
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Escape' && isSearchOpen) {
      event.preventDefault();
      toggleSearch();
      setSearchParams({});
    }
  };

  return (
    <Container
      className={cls(
        'border-b-1 border-default cursor-pointer h-[47px]',
        isSearchOpen ? 'w-full' : 'w-[90px]',
        className,
      )}
      alignItems="center"
      justifyContent="center"
      tabIndex={0}
      data-testid={`${testId}-container`}
    >
      {isSearchOpen && (
        <input
          type="search"
          value={searchQuery || ''}
          autoFocus
          onBlurCapture={handleBlur}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          placeholder={intl.formatMessage({ id: 'pools.page.search.placeholder' })}
          data-testid={`${testId}-input`}
          className={cls(
            'text-neutral font-body-l py-[12px] px-0 bg-transparent w-full align-bottom',
            'border-none border-b-1 border-strong border focus:ring-0 focus:outline-none',
            'peer placeholder:text-secondary relative top-[1px]',
          )}
        />
      )}
      <Container
        onClick={toggleSearch}
        className="group h-[47px]"
        alignItems="center"
        justifyContent="center"
      >
        <SearchToolIcon
          className={cls(
            'h-auto stroke-secondary mr-2 group-hover:stroke-accent',
            isSearchOpen ? 'w-[34px]' : 'w-[24px]',
          )}
        />
        {!isSearchOpen && (
          <Text id="common.search" color="secondary" className="group-hover:text-accent" />
        )}
      </Container>
    </Container>
  );
};
