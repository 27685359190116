import cls from 'classnames';
import { Asset } from 'core';
import { useMemo } from 'react';
import { ClassName, TestId } from 'core';
import { useIntl } from 'react-intl';
import {
  Container,
  Text,
  FiatValue,
  TokenIcon,
  TokenIconSize,
  TokenIconType,
  ItemRow,
  FormatAmount,
  Skeleton,
  getTokenI18nDesc,
} from 'ui';

export interface TokenRowProps
  extends ClassName,
    TestId,
    Pick<React.DetailsHTMLAttributes<HTMLDivElement>, 'onClick'> {
  token: Asset;
  isCompact?: boolean;
  showDescription?: boolean;
  iconSize?: TokenIconSize;
  iconType?: TokenIconType;
  freeBalance?: string;
}

export function TokenRow({
  className,
  'data-testid': testId,
  token,
  onClick,
  isCompact = true,
  iconSize = 'm',
  iconType = 'row',
  freeBalance,
}: TokenRowProps) {
  const intl = useIntl();

  const renderRightElement = () => {
    if (freeBalance === undefined) {
      return (
        <Container column alignItems="end">
          <Skeleton width="60px" height="10px" />
          <Skeleton width="40px" height="10px" className="mt-1" />
        </Container>
      );
    }

    return (
      <Container column alignItems="end">
        <Text type="data-m">
          <FormatAmount
            value={freeBalance || '0'}
            options={{ precision: 3 }}
            data-testid="token-amount"
          />
        </Text>
        <Text color="secondary" type="body-tiny">
          <FiatValue id={token.id} amount={freeBalance} />
        </Text>
      </Container>
    );
  };

  const rowDescription = useMemo(() => {
    if (token.origin) {
      return intl.formatMessage({ id: getTokenI18nDesc(token.type, token.origin) });
    }
    return token.name;
  }, [token.origin, token.name, token.type, intl]);

  return (
    <Container
      fullWidth
      alignItems="center"
      justifyContent="space-between"
      row
      className={cls('rounded px-1 py-2 last-of-type:mb-0', className)}
      data-testid={testId}
      onClick={onClick}
    >
      <TokenIcon
        type={iconType}
        token={token}
        size={iconSize}
        isCompact={isCompact}
        className="mr-3"
      />
      <ItemRow
        title={token.symbol}
        className="w-full"
        description={rowDescription}
        rightElement={renderRightElement()}
      />
    </Container>
  );
}
