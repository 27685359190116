import cls from 'classnames';
import { Container, Text } from 'ui';

interface CollatorStateChipProps {
  isActive?: boolean;
}

export function CollatorStateChip({ isActive = false }: CollatorStateChipProps) {
  return (
    <Container
      className={cls('py-1 px-2 rounded-lg', isActive ? 'bg-positive-soft' : 'bg-attention')}
      justifyContent="center"
      alignItems="center"
    >
      <Text
        type="title-4"
        color={isActive ? 'positive' : 'attention'}
        id={isActive ? 'collator.state.active' : 'collator.state.waiting'}
      />
    </Container>
  );
}
