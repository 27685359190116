import { Container, STOP_EVENT_BUBBLING, Text } from 'ui';
import bg from './images/token-launch-bg.png';
import { ClassName } from 'core';
import cls from 'classnames';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSwapStore } from '../../store/useSwapStore';
import { animated, useSpring } from '@react-spring/web';
import { ReactComponent as ArrowIcon } from 'icons/arrow-right.svg';

const MAILERLITE_FORM_ID = 'FFh8kJ';

export function TokenLaunchBanner({ className }: ClassName) {
  const [isMailerLiteAvailable, setIsMailerLiteAvailable] = useState(window.ml !== undefined);
  const { setIsTokenLaunchBannerVisible, isTokenLaunchBannervisible } = useSwapStore();
  const showModal = () => {
    window.ml?.('show', MAILERLITE_FORM_ID, true);
  };

  useEffect(() => {
    if (window.ml && !isMailerLiteAvailable) {
      setIsMailerLiteAvailable(true);
      return;
    }

    const interval = setInterval(() => {
      if (window.ml) {
        clearInterval(interval);
        setIsMailerLiteAvailable(true);
        setIsTokenLaunchBannerVisible(true);
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [isMailerLiteAvailable, setIsTokenLaunchBannerVisible]);

  const appLayout = document.getElementById('app-layout');

  const tokenLaunchBannerSpring = useSpring({
    opacity: isTokenLaunchBannervisible ? 1 : 0,
    transform: isTokenLaunchBannervisible ? 'translateY(0)' : 'translateY(20px)',
    delay: !isTokenLaunchBannervisible ? 0 : 400,
    config: { duration: 400 },
  });

  const handleHide = () => {
    setIsTokenLaunchBannerVisible(false);
  };

  return isMailerLiteAvailable && appLayout
    ? createPortal(
        <animated.div
          style={tokenLaunchBannerSpring}
          className={cls(
            'fixed bottom-[40px] h-[72px] whitespace-nowrap justify-center items-center z-widget',
            !isTokenLaunchBannervisible && 'pointer-events-none',
          )}
        >
          <Container
            onClick={showModal}
            className={cls(
              'rounded-xl bg-accent overflow-hidden cursor-pointer',
              'pr-5 absolute w-[560px] left-[-50px] right-[-50px] items-center',
              className,
            )}
          >
            <img src={bg} alt="background" />
            <Container fullWidth column className="pr-8 pl-2">
              <Text
                color="inverted"
                weight="semibold"
                className="text-[18px]"
                id="tokenLaunchBanner.title"
              />
              <Text color="inverted-dimmed" id="tokenLaunchBanner.desc" />
            </Container>
            <Container alignItems="center">
              <Container onClick={STOP_EVENT_BUBBLING}>
                <Container onClick={handleHide} className="p-2 mr-2">
                  <Text color="inverted-dimmed" id="common.hide" />
                </Container>
              </Container>
              <Container>
                <Text color="inverted" id="tokenLaunchBanner.cta" />
                <ArrowIcon className={cls('w-[20px] ml-2 h-auto stroke-inverted')} />
              </Container>
            </Container>
          </Container>
        </animated.div>,
        appLayout,
      )
    : null;
}
