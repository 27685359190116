import { useNavigate } from 'react-router-dom';
import { Container, Text, Button } from 'ui';
import { ReactComponent as StarIcon } from 'icons/star.svg';

export const EmptyPositions = () => {
  const navigate = useNavigate();

  const handleStartEarning = () => {
    navigate('/pools/promoted');
  };

  return (
    <Container fullWidth justifyContent="space-between" alignItems="center">
      <Container alignItems="center">
        <StarIcon className="w-[36px] h-auto fill-accent " />
        <Container column className="ml-6 max-w-[400px]">
          <Text type="title-2" id="position.claim.all.noPosition.title" />
          <Text color="secondary" id="position.claim.all.noPosition.desc" className="mt-1" />
        </Container>
      </Container>
      <Button
        variant="accent"
        onClick={handleStartEarning}
        message={{ id: 'position.claim.all.noPosition.cta' }}
      />
    </Container>
  );
};
