import cls from 'classnames';
import { MouseEventHandler, useState } from 'react';
import Decimal from 'decimal.js';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, BN_ZERO, fromBN } from '@mangata-finance/sdk';
import { Container } from 'ui';
import {
  ClaimRewardsConfirmModal,
  ClaimRewardsConfirmModalType,
} from '../ClaimRewardsConfirmModal/ClaimRewardsConfirmModal';
import {
  Asset,
  ClassName,
  TxType,
  useAllRewards,
  useAssets,
  useInternalBalance,
  useInvestedPools,
  useMangataAsset,
  useNativeRewards,
  useRewards,
  useSafeTransaction,
} from 'core';
import { isEmpty, isNull, isUndefined } from 'lodash-es';
import { EmptyPositions } from './components/EmptyPositions';
import { RewardsContent } from './components/RewardsContent';

export function GaspClaimAllRewardsCard({ className }: ClassName) {
  const [isConfirming, setIsConfirming] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    claimAllRewardsMutation: { mutate: claimAllRewards },
    claimAllRewardsFeeQuery: { data: fee },
  } = useRewards(undefined);

  const { rewardQueriesByLpId } = useNativeRewards();
  const rewardsMap = useAllRewards();

  const { data: _pools } = useInvestedPools();
  const pools = _pools?.baseList;

  const { getFreeBalance } = useInternalBalance();
  const { assetsMap } = useAssets();
  const { mangataAsset } = useMangataAsset();

  const feeTxAsset =
    mangataAsset && fee && fee.current ? { ...mangataAsset, amount: fee.current.amount } : null;
  const feeBalance = getFreeBalance(feeTxAsset);

  const nativeRewardsAmount = Object.values(rewardQueriesByLpId).reduce((acc, { data }) => {
    return acc.add(data ?? BN_ZERO);
  }, BN_ZERO);

  const nativeRewardsAmountStr = fromBN(nativeRewardsAmount, BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS);
  const { submitTxWithChecks } = useSafeTransaction(
    {
      feeTxAsset,
      receivingAssets:
        mangataAsset && nativeRewardsAmountStr
          ? [{ ...mangataAsset, amount: nativeRewardsAmountStr }]
          : [],
      type: TxType.Claim,
    },
    claimAllRewards,
  );

  const submitClaimAllRewards = () => {
    toggleConfirmModal();
    submitTxWithChecks();
  };

  const toggleConfirmModal = () => {
    setIsConfirming(!isConfirming);
  };

  const handleClaim: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    toggleConfirmModal();
  };

  const toggleRewardsInfo = () => setIsExpanded(!isExpanded);

  const rewards: Array<[Asset, string]> = Array.from(rewardsMap.entries())
    .map(([id, value]) => {
      const asset = assetsMap.get(id);
      return [asset, fromBN(value, asset?.decimals)];
    })
    .filter(($): $ is [Asset, string] => !isUndefined($[0]));
  const hasRewards = !isEmpty(rewards);

  const CONTAINER_CLASS = cls(
    'bg-default px-8 py-6 rounded-xl relative group shadow-xl border border-default',
    hasRewards && 'cursor-pointer',
    className,
  );

  return (
    <>
      <Container
        className={CONTAINER_CLASS}
        data-testid="user-rewards-panel"
        fullWidth
        onClick={toggleRewardsInfo}
        justifyContent="space-between"
        alignItems="center"
      >
        <>
          {isEmpty(pools) ? (
            <EmptyPositions />
          ) : (
            <RewardsContent rewards={rewards} isExpanded={isExpanded} onClaim={handleClaim} />
          )}
          {!hasRewards && (
            <div className="absolute inset-0 bg-transparent group-hover:bg-hover transition duration-300 rounded-xl" />
          )}
        </>
      </Container>
      {!isNull(rewards) && (
        <ClaimRewardsConfirmModal
          isOpen={isConfirming}
          rewards={rewards}
          feeAsset={feeTxAsset}
          onConfirm={submitClaimAllRewards}
          onCancel={toggleConfirmModal}
          type={ClaimRewardsConfirmModalType.All}
          isFeeInsuff={new Decimal(feeBalance || '0').lt(feeTxAsset?.amount || '0')}
        />
      )}
    </>
  );
}
