export default {
  'default.toggle.on': 'ON',
  'default.toggle.off': 'OFF',
  'navigation.swap': 'Trade',
  'navigation.pools': 'Pools',
  'navigation.positions': 'My Positions',
  'navigation.tokens': 'Tokens',
  'navigation.staking': 'Staking',
  'navigation.footer.title': 'Security Research Labs',
  'navigation.footer.description': 'Audited on 24 DEC, 2021',
  'wallet.connect': 'Connect Wallet',
  'wallet.open': 'Open',
  'wallet.toast.connected': 'Wallet Connected',
  'wallet.connectWallet.header': 'Select a Wallet',
  'wallet.extension.connect': 'Connect',
  'wallet.extension.talisman': 'Talisman',
  'wallet.extension.subwallet-js': 'SubWallet',
  'wallet.extension.polkadot-js': 'Polkadot{.js}',
  'wallet.extension.enkrypt': 'Enkrypt',
  'wallet.extension.metamask': 'Metamask',
  'wallet.notConnected.header': 'No Wallet Connected',
  'wallet.notConnected.title': 'Connect Your Wallet',
  'wallet.notConnected.cta': 'Connect wallet',
  'wallet.notConnected.info.key': 'Wallet is your key to Web3',
  'wallet.notConnected.info.safe':
    'Connecting the wallet is safe. \n There will be no transactions \n without your approval.',
  'wallet.connecting.info.text': 'New to wallets?',
  'wallet.connecting.info.cta': 'Learn more',
  'wallet.modal.account.connected.title': 'Connected account',
  'wallet.modal.account.disconnect': 'Disconnect',
  'wallet.modal.account.select.title': 'Select account',
  'wallet.modal.account.connected.header': 'Connected with {wallet}',
  'wallet.modal.account.connecting.header': 'Connecting {wallet}...',
  'wallet.modal.account.change.title': 'Connect with another account',
  'wallet.modal.account.loading.title': 'Loading accounts',
  'wallet.modal.account.loading.desc': 'If loading is taking too long, refresh the browser.',
  'wallet.modal.account.empty.title': 'No accounts found',
  'wallet.modal.account.empty.desc': 'Create an account in your wallet to get started.',
  'wallet.modal.connect.genericError.title': 'Something went wrong',
  'wallet.modal.connect.notFound.title': 'Wallet not found ',
  'address.tooltip.copy': 'Copy',
  'address.tooltip.copied': 'Copied',
  'wallet.modal.connect.notFound.desc':
    'It seems like {wallet} is not installed on your computer. Please install the {wallet} wallet extension or refresh your browser if it is already installed.',
  'wallet.positions.empty.title': '0 active positions',
  'wallet.positions.empty.desc':
    'Provide liquidity and earn trading commissions. On top of that, you will earn additional liquidity mining rewards by adding liquidity to promoted pools.',
  'wallet.tokens.empty.cta': 'Deposit Tokens',
  'wallet.tokens.empty.title': 'Your wallet is empty',
  'wallet.tokens.empty.desc': 'Deposit tokens to your account and start trading!',
  'wallet.positions.empty.cta': 'Go to liquidity pools',
  'token.select.modal.title': 'Select a token',
  'token.select.modal.search': 'Search Token',
  'swap.token.warning.description':
    'Anyone can create a token, including fake  \n versions of already existing tokens and tokens \n representing projects that do not exist. You might \n be unable to sell such a token back once you \n purchase it.',
  'token.settings.modal.title': 'Manage token list',
  'token.settings.show.allAvailableTokens': 'Show all available tokens',
  'token.settings.show.mangataDefaultTokens': 'Mangata default tokens',
  'token.settings.show.userOwnedTokens': 'User Owned tokens',
  'token.settings.show.liquidityPoolTokens': 'Liquidity pool tokens',
  'token.select.button': 'Select token',
  'token.select.empty': 'No results',
  'swap.input.firstToken.label': 'You Pay',
  'gaslessTag.title': 'Gasless Swap',
  'error.toast': 'Something went wrong',
  'modal.transaction.swap.header.title': 'Swapping',
  'modal.transaction.claim.header.title': 'Claiming Rewards',
  'modal.transaction.deactivateLP.header.title': 'Deactivating',
  'modal.transaction.activateLP.header.title': 'Activating',
  'modal.transaction.swap.header.title.success': 'Successfully Swapped',
  'modal.transaction.claim.header.title.success': 'Successfully Claimed',
  'modal.transaction.deactivateLP.header.title.success': 'Successfully Deactivated',
  'modal.transaction.activateLP.header.title.success': 'Successfully Activated',
  'modal.transaction.swap.description': 'Please confirm the transaction in your wallet',
  'modal.transaction.error.title': 'Transaction Failed',
  'modal.transaction.error.desc': '{error}',
  'modal.transaction.error.cancelled.title': 'Transaction Rejected',
  'modal.transaction.error.cancelled.desc': 'The transaction has not been confirmed.',
  'modal.transaction.error.cta': 'Ok, close',
  'modal.transaction.confirming.title': 'Confirm Transaction',
  'modal.transaction.pending.title': 'Transaction Pending',
  'modal.transaction.success.title': 'Transaction Completed',
  'modal.transaction.confirming.desc': 'Confirm the transaction \n in your wallet.',
  'modal.transaction.pending.desc': 'Wait until the transaction \n is processed.',
  'modal.transaction.success.cta': 'Confirm',
  'modal.transaction.success.link': 'View on Explorer',
  'modal.transaction.header.for': 'for',
  'modal.transaction.header.and': 'and',
  'modal.transaction.header.plus': '+',
  'amount.tooltip.copy': 'Copy',
  'amount.tooltip.copied': 'Copied',
  'miningInfo.paused': 'Paused',
  'pool.remove.submit.enterAmount': 'Enter Amount',
  'pool.remove.submit.insufficientFee': 'Insufficient Fee',
  'pool.remove.submit.cta': 'Remove Liquidity',
  'swap.widget.title': 'Trade',
  'staking.new.banner.title': 'Maximize your token potential. \n Stake & earn rewards!',
  'staking.new.banner.cta': 'Start staking',
  'staking.new.banner.myStake': 'My stake',
  'staking.new.banner.manage': 'Manage position',
};
