import { useParams } from 'react-router';
import { useInvestedPools } from './useInvestedPools';

export const useInvestedPoolFromPath = () => {
  const params = useParams();
  const { data } = useInvestedPools();

  const poolId = params?.poolId;
  const pool = poolId ? data?.list.find(({ id }) => poolId === id) : null;
  const isNotFound = poolId && data && !pool;

  return {
    pool,
    isNotFound,
    poolId,
  };
};
