import { Text, Container } from 'ui';
import { ReactComponent as InfoIcon } from 'icons/info-filled.svg';

export const StakingNotAvailable = () => {
  return (
    <Container alignItems="center" className="bg-default rounded-[12px] py-4 px-5 w-full my-4">
      <InfoIcon className="fill-highlight w-6 h-auto mr-2" />
      <Text color="accent" id="staking.detail.candidate.full.title" />
    </Container>
  );
};
