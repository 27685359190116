import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export interface UIStore {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

export const useUIStore = create(
  devtools<UIStore>((set) => ({
    isSidebarOpen: false,
    setIsSidebarOpen: (isOpen) => set({ isSidebarOpen: isOpen }),
  })),
);
