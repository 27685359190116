import { isSellAssetTransactionSuccessful, MangataGenericEvent, toBN } from 'gasp-sdk';

import { BN } from '@polkadot/util';
import { TransactionStore, TxAsset, TxType, ExtrinsicTx } from '../../../transaction';
import { SwapRoute, SwapTransactionError } from '../../Swap';
import { QueryOptional, WalletAccount } from '../../../../services';
import { Config } from 'wagmi';
import { ApiPromise } from '@polkadot/api';
import { some } from 'lodash-es';

export type SwapMutationProps = {
  route: SwapRoute;
  minAmountOut: BN;
  firstToken: TxAsset;
  secondToken: TxAsset;
  selectedAccount: WalletAccount;
  onDone?: () => void;
};

const getError = (txEvents: MangataGenericEvent[]) => {
  const failedEvent = txEvents.find((txEvent) => txEvent.error);
  const fallbackError = 'Unknown error';

  switch (true) {
    case !!failedEvent:
      return {
        name: failedEvent?.error?.name || fallbackError,
        msg: failedEvent?.error?.documentation.join(' '),
      };
    case some(txEvents, { method: SwapTransactionError.FailedDueToSlippage }):
    case some(txEvents, { method: SwapTransactionError.FailedOnAtomicSwap }):
      return {
        name: SwapTransactionError.FailedDueToSlippage,
        msg: null,
      };
  }

  return { name: fallbackError };
};

export const submitSwap =
  (api: ApiPromise | null, transactionStore: TransactionStore, config: QueryOptional<Config>) =>
  async (props: SwapMutationProps) => {
    const { route, minAmountOut, selectedAccount, firstToken, secondToken } = props;

    if (!selectedAccount || !api || !config) {
      return null;
    }

    const extrinsicStatusCallback = (txEvents: MangataGenericEvent[]) => {
      if (isSellAssetTransactionSuccessful(txEvents)) {
        tx.done();
        props.onDone?.();
      } else {
        tx.doneWithError(getError(txEvents));
      }
    };

    const tx = new ExtrinsicTx(
      api,
      transactionStore,
      config,
      selectedAccount.address,
      undefined,
      extrinsicStatusCallback,
    )
      .create(TxType.Swap)
      .setMetadata({
        tokens: [firstToken, secondToken],
        joinSymbolId: 'modal.transaction.header.for',
      })
      .setOptions({ doneOnTrigger: true })
      .setTx(
        api.tx.xyk.multiswapSellAsset(
          route.map((token) => token.id),
          toBN(firstToken.amount, firstToken.decimals),
          minAmountOut.toString(),
        ),
      )
      .build();

    await tx.send();
  };
