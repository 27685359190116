import { Container } from 'ui';
import { useSwapStore } from '../store/useSwapStore';
import { SwapWidgetSettings } from './settings/SwapWidgetSettings';
import { SwapWidgetContent } from './content/SwapWidgetContent';
import { RefObject, useEffect, useRef } from 'react';
import cls from 'classnames';
import { animated, Lookup, TransitionFn, useTransition } from '@react-spring/web';

export const MAX_SLIPPAGE = 1;

interface SwapWidgetProps {
  onWalletConnect?: () => void;
}

export const SwapWidget = ({ onWalletConnect }: SwapWidgetProps) => {
  const { areSettingsOpen, setSettingsOpen, dispose } = useSwapStore();

  const contentRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleSettingsClose = () => setSettingsOpen(false);

  useEffect(() => dispose, [dispose]);

  const settingsTransition = useTransition(areSettingsOpen, {
    from: { y: '-100%', opacity: 0, scale: 0.8 },
    enter: { y: '0%', opacity: 1, scale: 1 },
    leave: { y: '-100%', opacity: 0, scale: 0.8 },
    immediate: !containerRef.current,
  });

  const contentTransition = useTransition(!areSettingsOpen, {
    from: { y: '100%', opacity: 0, scale: 0.8 },
    enter: { y: '0%', opacity: 1, scale: 1 },
    leave: { y: '100%', opacity: 0, scale: 0.8 },
    immediate: !containerRef.current,
  });

  const renderAnimated = <T extends Lookup>(
    transition: TransitionFn<boolean, T>,
    Component: JSX.Element,
    ref: RefObject<HTMLDivElement>,
  ) => {
    return transition((style, visible) =>
      visible ? (
        <animated.div style={style} className={cls('w-full row-start-1 col-start-1')}>
          <Container
            className={cls('bg-default p-6 rounded-xl transition-all')}
            alignItems="stretch"
            justifyContent="start"
            column
            ref={ref}
            fullWidth
          >
            {Component}
          </Container>
        </animated.div>
      ) : null,
    );
  };

  return (
    <div
      className="grid grid-cols-1 grid-rows-1 w-full min-h-[320px] place-items-center"
      ref={containerRef}
    >
      {renderAnimated(
        settingsTransition,
        <SwapWidgetSettings onClose={handleSettingsClose} />,
        settingsRef,
      )}
      {renderAnimated(
        contentTransition,
        <SwapWidgetContent onWalletConnect={onWalletConnect} />,
        contentRef,
      )}
    </div>
  );
};
