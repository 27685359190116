import { Container, Text } from 'ui';
import { ReactComponent as InfoIcon } from 'icons/timer.svg';

interface StakingCardStakeChangeChipProps {
  isReady: boolean;
}
export function StakingCardStakeChangeChip({ isReady }: StakingCardStakeChangeChipProps) {
  return (
    <Container className="bg-attention-soft rounded-full py-1 px-2" alignItems="center">
      <Text
        id={isReady ? 'stakingCard.confirmChange' : 'stakingCard.changeScheduled'}
        className="mr-1"
        type="body-tiny"
        weight="light"
      />
      <InfoIcon className="stroke-neutral fill-neutral w-[20px] h-auto" />
    </Container>
  );
}
