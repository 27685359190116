import { Container, Text, FormatAmount, Button, TokenIcon } from 'ui';

import { ReactComponent as PlusIcon } from 'icons/plus.svg';
import { Asset, ClassName } from 'core';
import { BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS, fromBN } from '@mangata-finance/sdk';
import { StakingCardProps } from './StakingCard';
import { ParachainStakingDelegationRequest } from '@polkadot/types/lookup';
import { isUndefined } from 'lodash-es';
import cls from 'classnames';
import { useStakingUIStore } from '../../../Staking';

interface StakingCardMyStakeProps extends Pick<StakingCardProps, 'delegation'>, ClassName {
  asset: Asset;
  request?: ParachainStakingDelegationRequest;
}

export function StakingCardMyStake({
  asset,
  delegation,
  request,
  className,
}: StakingCardMyStakeProps) {
  const { setAddModalOpen, setRemoveModalOpen } = useStakingUIStore();

  const collatorAddress = delegation.owner.toString();

  const handleBondMore = () => setAddModalOpen(true, collatorAddress);
  const handleBondLess = () => setRemoveModalOpen(true, collatorAddress);

  return (
    <Container
      fullWidth
      className={cls(
        'px-4 bg-gradient-to-b from-base/[0.50] from-0% to-transparent to-60%',
        className,
      )}
      alignItems="center"
      justifyContent="space-between"
    >
      <Container column>
        <Text type="body-s" uppercase color="secondary" id="stakingCard.myStake" />
        <Container className="mt-1">
          <TokenIcon token={asset} className="mr-1" />
          <Text className="mr-1">
            <FormatAmount
              value={fromBN(delegation.amount.toBn(), BN_DIV_NUMERATOR_MULTIPLIER_DECIMALS)}
              options={{ precision: 3, nonZeroPrecision: true }}
            />
          </Text>
          <Text color="secondary">{asset.symbol}</Text>
        </Container>
      </Container>

      {isUndefined(request) && (
        <Container>
          <Button
            size="xs"
            variant="secondary"
            onClick={handleBondLess}
            className="px-2 py-3"
            message={{ id: 'common.remove' }}
          />
          <Button
            size="xs"
            variant="primary-outlined"
            LeadIcon={PlusIcon}
            className="ml-2 px-2 py-3"
            onClick={handleBondMore}
            message={{ id: 'common.add' }}
          />
        </Container>
      )}
    </Container>
  );
}
