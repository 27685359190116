import cls from 'classnames';
import { Container, Text } from 'ui';
import { ReactComponent as LightningIcon } from 'icons/lightning.svg';

interface CollatorAprChipProps {
  isActive?: boolean;
  value: string;
}

export function CollatorAprChip({ isActive = false, value }: CollatorAprChipProps) {
  return (
    <Container inline justifyContent="center" alignItems="center">
      <LightningIcon className={cls('w-4 h-auto', isActive ? 'fill-accent ' : 'fill-warning')} />

      <Text type="title-4" color={isActive ? 'highlight' : 'attention'}>
        {value} %
      </Text>
    </Container>
  );
}
