import { ReactNode } from 'react';
import { Container, Text } from '../';
import { TestId } from '../../types';
import { ClassName } from 'core';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

interface WidgetDetailRowProps extends TestId, ClassName {
  label: string | ReactNode;
  value: string | ReactNode;
}
export function WidgetDetailRow({
  label,
  value,
  'data-testid': testId,
  className,
}: WidgetDetailRowProps) {
  const valueDataTestId = testId ? `${testId}-value` : undefined;
  return (
    <Container
      justifyContent="space-between"
      fullWidth
      className={twMerge(classNames('mt-4', className))}
      data-testid={testId}
    >
      {typeof label === 'string' ? (
        <Text type="title-4" color="secondary">
          {label}
        </Text>
      ) : (
        label
      )}
      {typeof value === 'string' ? (
        <Text type="data-m" data-testid={valueDataTestId}>
          {value}
        </Text>
      ) : (
        value
      )}
    </Container>
  );
}
