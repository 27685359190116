import { Container } from '../Container/Container';
import { ReactComponent as DayIcon } from '../../../assets/icons/day-theme.svg';
import { ReactComponent as NightIcon } from '../../../assets/icons/night-theme.svg';
import cls from 'classnames';
import { ClassName } from '../../types';
import { Theme } from 'core';

interface ThemeSwitchProps extends ClassName {
  theme: Theme;
  onChange: (theme: Theme, isSystemThemeActive?: boolean) => void;
}

export const ThemeSwitch = ({ theme, onChange, className }: ThemeSwitchProps) => {
  const handleChange = (theme: Theme) => () => onChange(theme, false);

  return (
    <Container className={cls('py-4', className)}>
      <Container className="cursor-pointer p-2" onClick={handleChange(Theme.Day)}>
        <DayIcon
          className={cls(
            'w-[24px] h-auto',
            theme === Theme.Day ? 'fill-secondary' : 'stroke-secondary',
          )}
        />
      </Container>
      <Container className="cursor-pointer p-2" onClick={handleChange(Theme.Night)}>
        <NightIcon
          className={cls(
            'w-[24px] h-auto',
            theme === Theme.Night ? 'fill-secondary' : 'stroke-secondary',
          )}
        />
      </Container>
    </Container>
  );
};
