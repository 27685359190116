import { Button, Container, IconButton, Text } from 'ui';
import { TestId } from 'core';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as WalletXIcon } from 'assets/icons/wallet-x.svg';
import { ReactComponent as WalletPlusIcon } from 'assets/icons/wallet-plus.svg';

interface ConnectWalletLayoutProps extends TestId {
  onConnectWallet(): void;
  onClose(): void;
}

export const GaspNotConnectedLayout = ({
  onConnectWallet,
  onClose,
  'data-testid': testId,
}: ConnectWalletLayoutProps) => {
  return (
    <div className="flex flex-col w-full text-center h-full" data-testid={testId}>
      <Container justifyContent="space-between" alignItems="center">
        <Container>
          <WalletXIcon className="stroke-secondary w-5 h-auto" />
          <Text type="title-4" className="ml-3" color="secondary" id="wallet.notConnected.header" />
        </Container>
        <IconButton Icon={CloseIcon} size="s" onClick={onClose} />
      </Container>
      <Container className="h-full w-full overflow-y-auto no-scrollbar" justifyContent="center">
        <div className="mt-[90px] w-[250px] self-center flex flex-col justify-items-stretch items-center">
          <Text type="title-2" className="mt-5" id="wallet.notConnected.title" />
          <Text
            type="body-s"
            color="secondary"
            id="wallet.notConnected.info.text"
            className="mt-3"
          />
          <Button
            size="m"
            data-testid={`${testId}-cta`}
            className="mt-9 w-[178px] hover:w-[200px] transition-all"
            onClick={onConnectWallet}
            message={{ id: 'wallet.notConnected.cta' }}
            LeadIcon={WalletPlusIcon}
          />
        </div>
      </Container>
    </div>
  );
};
