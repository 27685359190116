import cls from 'classnames';
import { Container, LinkButton, Modal, Text } from 'ui';
import { useMetamaskNetwork, useModalStore, useTransactionStore } from 'core';
import { DepositUIState, useDepositStore } from '../store/useDepositStore';
import { RefObject, useEffect, useRef } from 'react';
import { useRollupDepositState } from './useRollupDepositState';
import { getChainIconUrl } from '../components/ChainSelector/ChainSelector';
import { TokenSelect } from '../components/TokenSelect/TokenSelect';
import { animated } from '@react-spring/web';
import { DepositDefaultContent } from '../components/DepositDefaultContent/DepositDefaultContent';
import { Asset } from 'core';
import { useRollupDepositTransitions } from './useRollupDepositTransitions';
import { DepositTxInfo } from '../components/DepositTxInfo/DepositTxInfo';
import { isNil } from 'lodash-es';
import { TransferSuccess } from '../components/TransferSuccess/TransferSuccess';
import { CustomTokenDeposit } from '../components/CustomTokenDeposit/CustomTokenDeposit';
import { FormattedMessage } from 'react-intl';

export function RollupDeposit() {
  const { isDepositOpen, closeDeposit } = useModalStore();
  const { remove } = useTransactionStore();

  const defaultContentRef = useRef<HTMLDivElement>(null);
  const selectTokenRef = useRef<HTMLDivElement>(null);
  const customTokenRef = useRef<HTMLDivElement>(null);
  const txInfoRef = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);

  const refMap: Record<DepositUIState, RefObject<HTMLDivElement>> = {
    [DepositUIState.Default]: defaultContentRef,
    [DepositUIState.SelectToken]: selectTokenRef,
    [DepositUIState.CustomToken]: customTokenRef,
    [DepositUIState.Depositing]: txInfoRef,
    [DepositUIState.Success]: successRef,
    [DepositUIState.ApproveSpendingCap]: txInfoRef,
  };

  const {
    depositTokens,
    depositBalances,
    approveTx,
    depositTx,
    accounts,
    isDepositing,
    isDepositReady,
    isApproveInProgress,
    submitDeposit,
    freeBalance,
  } = useRollupDepositState();

  const { chain, setChain, dispose, setAsset, setUIState, uiState } = useDepositStore();

  const {
    containerSpring,
    defaultStateTransition,
    txInfoTransition,
    successTransition,
    selectTokenTransition,
    customTokenTransition,
  } = useRollupDepositTransitions(refMap[uiState], approveTx, depositTx);
  const { defaultChain } = useMetamaskNetwork();

  useEffect(() => {
    return () => {
      dispose();
    };
  }, [dispose]);

  useEffect(() => {
    if (defaultChain && isNil(chain?.id)) {
      setChain(
        {
          id: defaultChain.chainId,
          title: defaultChain.name,
          icon: getChainIconUrl(defaultChain.chainId),
        },
        false,
      );
    }
  }, [defaultChain, chain?.id, setChain]);

  const handleTokenSelectClick = () => {
    setUIState(DepositUIState.SelectToken);
  };

  const handleTokenSelect = (asset: Asset) => {
    setAsset(asset);
    setUIState(DepositUIState.Default);
  };

  const handleClose = () => {
    closeDeposit();
    remove(approveTx?.id);
    remove(depositTx?.id);
  };

  return (
    <Modal
      isCloseIconVisible={false}
      isClosableOnOverlayClick={false}
      isOpen={isDepositOpen}
      onClose={handleClose}
      className={cls('w-[480px] py-6 overflow-hidden')}
      data-testid="deposit-modal"
    >
      <animated.div style={containerSpring} className="relative w-full">
        {txInfoTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container ref={txInfoRef} fullWidth>
                <DepositTxInfo approveTx={approveTx} depositTx={depositTx} />
              </Container>
            </animated.div>
          ) : null,
        )}
        {successTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container ref={successRef} fullWidth>
                <TransferSuccess
                  tx={depositTx}
                  onClose={handleClose}
                  handleActivityTabNavigation={handleClose}
                />
              </Container>
            </animated.div>
          ) : null,
        )}
        {customTokenTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container ref={customTokenRef} fullWidth>
                {
                  <CustomTokenDeposit
                    onBack={() => setUIState(DepositUIState.SelectToken)}
                    onClose={closeDeposit}
                    titleId="bridge.tokenSelect.title"
                  />
                }
              </Container>
            </animated.div>
          ) : null,
        )}
        {selectTokenTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container ref={selectTokenRef} fullWidth>
                {depositTokens && (
                  <TokenSelect
                    onBack={() => setUIState(DepositUIState.Default)}
                    onClose={closeDeposit}
                    onTokenSelect={handleTokenSelect}
                    tokens={depositTokens}
                    balances={depositBalances}
                    titleId="bridge.deposit.title"
                    footer={
                      <Container
                        justifyContent="space-between"
                        alignItems="center"
                        fullWidth
                        className="p-4 bg-input rounded-xl"
                      >
                        <Container column>
                          <Text type="title-4" id="bridge.tokenSelect.customToken.title" />
                          <Text color="secondary" id="bridge.tokenSelect.customToken.desc" />
                        </Container>
                        <Container>
                          <LinkButton
                            isExternal={false}
                            onClick={() => setUIState(DepositUIState.CustomToken)}
                          >
                            <FormattedMessage id="bridge.tokenSelect.customToken.cta" />
                          </LinkButton>
                        </Container>
                      </Container>
                    }
                  />
                )}
              </Container>
            </animated.div>
          ) : null,
        )}
        {defaultStateTransition((style, visible) =>
          visible ? (
            <animated.div style={style} className="w-full absolute">
              <Container column ref={defaultContentRef} fullWidth>
                <DepositDefaultContent
                  onTokenSelectClick={handleTokenSelectClick}
                  accounts={accounts}
                  depositTokens={depositTokens}
                  depositBalances={depositBalances}
                  freeBalance={freeBalance}
                  isApproveInProgress={isApproveInProgress}
                  isDepositReady={isDepositReady}
                  isDepositing={isDepositing}
                  submitDeposit={submitDeposit}
                />
              </Container>
            </animated.div>
          ) : null,
        )}
      </animated.div>
    </Modal>
  );
}
